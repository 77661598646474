import { Carousel as CarouselComponent } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import type { FC, ReactElement } from 'react';

import { useFrontpage } from '../../contexts';
import { HeroBannerDesktopPlaceholder, HeroBannerMobilePlaceholder } from '../../images';
import { CarouselBanner } from './CarouselBanner';
import { CAROUSEL, CAROUSEL_BANNER_DESKTOP_PLACEHOLDER, CAROUSEL_BANNER_MOBILE_PLACEHOLDER } from './constants';
import { CarouselSlide, DesktopImagePlaceholderWrapper, MobileImagePlaceholderWrapper, Root } from './styles';

interface CarouselProps {
  skeleton: ReactElement;
}

export const Carousel: FC<CarouselProps> = ({ skeleton }) => {
  const { getTestId } = useTestId();
  const { heroBanners } = useFrontpage();

  return (
    <Root data-testid={getTestId(CAROUSEL)}>
      <CarouselComponent
        autoplay={{ enabled: true, interval: 6000 }}
        navigation={{ showArrows: false }}
        pagination={{ style: 'rectangles' }}
        id="hero-banner-carousel"
      >
        {heroBanners.length > 0 ? (
          heroBanners.map(({ fontColor, headline, imageUrl, link, mobileFontColor, smallImageUrl, tagline }, index) => (
            <CarouselSlide
              key={headline}
              minWidth="100%"
            >
              <CarouselBanner
                position={index}
                fontColor={fontColor}
                headline={headline}
                imageUrl={imageUrl}
                link={link}
                mobileFontColor={mobileFontColor}
                smallImageUrl={smallImageUrl}
                tagline={tagline}
              />
            </CarouselSlide>
          ))
        ) : (
          <CarouselSlide minWidth="100%">
            <DesktopImagePlaceholderWrapper>
              <HeroBannerDesktopPlaceholder testId={getTestId(CAROUSEL_BANNER_DESKTOP_PLACEHOLDER)} />
            </DesktopImagePlaceholderWrapper>
            <MobileImagePlaceholderWrapper>
              <HeroBannerMobilePlaceholder testId={getTestId(CAROUSEL_BANNER_MOBILE_PLACEHOLDER)} />
            </MobileImagePlaceholderWrapper>
          </CarouselSlide>
        )}
      </CarouselComponent>
    </Root>
  );
};
