import useTestId from '@ori/testid-generator';

import { Header, Banner, Products, ViewMoreButton, BannerPlaceholder } from './components';
import { BANNER_WITH_PRODUCTS_AND_BUTTON, BANNER_WITH_PRODUCTS_AND_BUTTON_CIRCLE } from './constants';
import { Root, Circle, BannerWrapper } from './styles';
import type { BannerWithProductsAndButtonProps } from './types';

/**
 * Component responsible for displaying various sections on front page.
 * Right now is it suitable for What's new, Best Offers and Category banner.
 */
export const BannerWithProductsAndButton = ({
  header,
  products,
  banner,
  bannerPlaceholder,
  button,
  displayCircle,
}: BannerWithProductsAndButtonProps) => {
  const { getTestId } = useTestId();
  const { title, description } = header;
  const { banner: bannerData, alignment, bannerOrientation } = banner;
  const { title: buttonTitle, variant, alignment: buttonAlignment, href, color } = button;
  const { productBoxes, maxProductsToDisplay, maxProductsToDisplayMobile } = products;

  return (
    <Root
      bannerOrientation={bannerOrientation}
      bannerAlignment={alignment}
      data-testid={getTestId(BANNER_WITH_PRODUCTS_AND_BUTTON)}
    >
      {displayCircle ? <Circle data-testid={getTestId(BANNER_WITH_PRODUCTS_AND_BUTTON_CIRCLE)} /> : null}
      <Header
        title={title}
        description={description}
      />
      <ViewMoreButton
        href={href}
        alignment={buttonAlignment}
        title={buttonTitle}
        color={color}
        variant={variant}
      />
      <BannerWrapper bannerOrientation={bannerOrientation}>
        {bannerData?.imageUrl ? (
          <Banner
            bannerOrientation={bannerOrientation}
            banner={bannerData}
            alignment={alignment}
          />
        ) : null}
        <BannerPlaceholder
          bannerOrientation={bannerOrientation}
          alignment={alignment}
          image={bannerPlaceholder.image}
        />
      </BannerWrapper>
      {productBoxes.length > 0 ? (
        <Products
          productBoxes={productBoxes}
          maxProductsToDisplay={maxProductsToDisplay}
          maxProductsToDisplayMobile={maxProductsToDisplayMobile}
        />
      ) : null}
    </Root>
  );
};
