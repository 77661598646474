/* istanbul ignore file -- api */
import { fetchGraphql } from '@ori/fetching';
import { resolveHideRating } from '@ori/product-box';
import {
  ApiClient as StaticApiClient,
  getTenantApplicationsProductboxConfiguration,
} from '@ori/static-api-client-react';

import type { GetFrontPageQuery, GetFrontPageQueryVariables } from '../models';
import { frontPageQuery } from '../queries-net';
import type { GetFrontpageDataParams } from './types';

/**
 * Fetch function that fetches data for the frontpage.
 */
export const getFrontpageData = async ({ graphQlNetUrl, staticApiUrl, headers, tenant }: GetFrontpageDataParams) => {
  const staticApiClient = new StaticApiClient({ baseUrl: staticApiUrl, tenant });
  const [data, hideRating, { data: productBoxConfiguration }] = await Promise.all([
    fetchGraphql<GetFrontPageQuery, GetFrontPageQueryVariables>({
      query: frontPageQuery,
      url: graphQlNetUrl,
      headers,
    }),
    resolveHideRating({ staticApiUrl, tenant }),
    getTenantApplicationsProductboxConfiguration(staticApiClient, tenant),
  ]);

  return {
    data,
    productBoxConfiguration: {
      ...productBoxConfiguration,
      isRatingsAndReviewsEnabled: productBoxConfiguration.isRatingsAndReviewsEnabled || !hideRating,
    },
  };
};
