import { styled, type Theme, Typography, type TypographyProps } from '@ori-ui/mui';
import { shouldNotForwardProp } from '@ori/presentation-utils';
import type { CSSProperties } from 'react';

import { TABLET_BANNER_BREAKPOINT } from '../../containers/CampaignBannerSection/constants';
import type { DeviceColorsProps } from '../../types';
import { CAMPAIGN_BANNER_IMAGE_DESKTOP_WIDTH } from './constants';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',

  [theme.breakpoints.up(TABLET_BANNER_BREAKPOINT)]: {
    flex: `${CAMPAIGN_BANNER_IMAGE_DESKTOP_WIDTH}px 0 0`,
  },
}));

export const Text = styled(Typography, {
  shouldForwardProp: shouldNotForwardProp(['mobileColor', 'desktopColor']),
})<DeviceColorsProps & Pick<TypographyProps, 'component'>>(({ desktopColor, mobileColor, theme }) => ({
  alignSelf: 'flex-end',
  color: mobileColor,
  fontFamily: 'SansOri, SansOriFallback, sans-serif',
  marginBottom: theme.spacing(3),
  position: 'absolute',
  textAlign: 'center',
  width: '100%',
  fontSize: '2.5rem',
  [theme.breakpoints.up('sm')]: {
    color: desktopColor,
    marginBottom: theme.spacing(8),
  },
}));

const getImageStyles = (theme: Theme): CSSProperties => ({
  borderRadius: theme.spacing(0),
  height: '100%',
  objectFit: 'cover',
  objectPosition: '50% 50%',
  position: 'absolute',
  top: 0,
  width: '100%',
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.spacing(1),
  },
  [theme.breakpoints.up('lg')]: {
    borderRadius: theme.spacing(1, 0, 0, 1),
  },
});

export const Image = styled('img')(({ theme }) => ({
  ...getImageStyles(theme),
}));

export const ImagePlaceholder = styled('div')(({ theme }) => ({
  ...getImageStyles(theme),
  zIndex: -1,
  svg: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderRadius: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: theme.spacing(1, 0, 0, 1),
    },
  },
  left: 0,
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 225,
  [theme.breakpoints.up('sm')]: {
    height: 410,
  },
}));
