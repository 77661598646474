import { useTheme } from '@ori-ui/mui';
import type { ImageSizesHelperParams } from '@ori/image-sizes-helper';
import { getImageVariants, generateSrcSet } from '@ori/image-sizes-helper';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import type { Brand } from '../../../../models';
import { onLinkClick } from '../../utils';
import { BRAND_DESKTOP_WIDTH, BRAND_MOBILE_WIDTH } from './constants';
import { Root } from './styles';

export const Image: FC<Brand> = ({ imageUrl, name, url }) => {
  const theme = useTheme();
  const onImageLinkClick = useCallback(() => onLinkClick(name, url), [name, url]);
  const imagesVariants = useMemo<ImageSizesHelperParams>(
    () => ({
      url: imageUrl,
      backgroundColor: theme.palette.grey[100],
      imageFormat: 'WebP',
      imageSizeMultiplier: 2,
      params: [
        {
          width: BRAND_MOBILE_WIDTH,
          quality: 90,
        },
        {
          width: BRAND_DESKTOP_WIDTH,
          quality: 90,
        },
      ],
    }),
    [imageUrl, theme.palette.grey],
  );
  const image = useMemo(
    () => (
      <Root
        alt={name}
        loading="lazy"
        sizes={`(max-width: ${theme.breakpoints.values.sm - 1}px) ${BRAND_MOBILE_WIDTH}px, ${BRAND_DESKTOP_WIDTH}px`}
        srcSet={generateSrcSet(getImageVariants(imagesVariants))}
      />
    ),
    [imagesVariants, name, theme.breakpoints.values.sm],
  );

  return url ? (
    <a
      aria-label={name}
      href={url}
      onClick={onImageLinkClick}
    >
      {image}
    </a>
  ) : (
    image
  );
};
