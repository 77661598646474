import { mapProductBoxesFromGql } from '@ori/product-box';

import type { CategoryListing, GetFrontPageQuery } from '../models';

export const shouldRenderCategoryListing = ({ productBoxes }: Pick<CategoryListing, 'productBoxes'>): boolean =>
  productBoxes.length > 0;

export const getCategoryListing = (data: GetFrontPageQuery, isAnonymousUser: boolean): CategoryListing => {
  const [banner] = data.application?.frontpage?.digitalPromotions?.categoryBanners ?? [];
  const productBoxes = mapProductBoxesFromGql({
    data: [...(data.application?.frontpage?.digitalPromotions?.categoryProducts ?? [])],
    isAnonymousUser,
  });

  return {
    banner: banner ?? null,
    productBoxes,
    shouldRender: shouldRenderCategoryListing({ productBoxes }),
  };
};
