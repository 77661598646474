import { Placement } from '@ori/analytics-data';
import { useIsMobile } from '@ori/presentation-hooks';
import { ProductBox } from '@ori/product-box';
import useTestId from '@ori/testid-generator';

import { PRODUCTS_BOX, PRODUCTS_ROOT } from '../../../../constants';
import { useFrontpage } from '../../../../contexts';
import { Root } from './styles';
import type { ProductsProps } from './types';

export const Products = ({ productBoxes, maxProductsToDisplay, maxProductsToDisplayMobile }: ProductsProps) => {
  const { productBoxConfiguration } = useFrontpage();
  const { getTestId } = useTestId();
  const isMobile = useIsMobile();

  let numberOfProducts = maxProductsToDisplay ?? productBoxes.length;

  if (isMobile) {
    numberOfProducts = maxProductsToDisplayMobile ?? productBoxes.length;
  }

  return (
    <Root
      data-testid={getTestId(PRODUCTS_ROOT)}
      productCount={numberOfProducts}
    >
      {productBoxes.slice(0, numberOfProducts).map((productProps, index) => (
        <li
          key={productProps.code}
          data-testid={PRODUCTS_BOX}
        >
          <ProductBox
            {...productProps}
            analyticsData={{ placement: Placement.NewProducts, position: index }}
            hideRating={!productBoxConfiguration.isRatingsAndReviewsEnabled}
            withTranslationsProvider={false}
            mrpStatementEnabled={productBoxConfiguration.isMrpStatementEnabled}
          />
        </li>
      ))}
    </Root>
  );
};
