import { useToken } from '@ori/auth';
import type { CategoryLink } from '@ori/presentation-components';
import type { StaticApplicationsProductBoxConfigurationDto } from '@ori/static-api-client-react';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';

import type {
  CampaignBannersFragment,
  GetFrontPageQuery,
  NotificationBannerFragment,
  MotivationContent,
  TopBrands,
  WhatsNew,
  VipBenefits,
  BenefitBannerFragment,
  VipCustomerBannerFragment,
  HeroBannerFragment,
  BeautyVideo,
  BestOffers,
  CategoryListing,
  BestSellerProducts,
  IconicProducts,
} from '../models';
import {
  getBeautyVideo,
  getBestOffers,
  getBestSellerProducts,
  getCategoryListing,
  getCategoryNavigationItems,
  getHeroBanners,
  getIconicProducts,
  getMotivationContent,
  getTopBrands,
  getVipBenefits,
  getWhatsNew,
} from '../utils';

export interface FrontpageContext {
  beautyVideo: BeautyVideo;
  benefitBanner: BenefitBannerFragment['benefitBanner'];
  bestOffers: BestOffers;
  bestsellerProducts: BestSellerProducts;
  campaignBanner: CampaignBannersFragment | null;
  categoryListing: CategoryListing;
  categoryNavigationItems: CategoryLink[];
  heroBanners: HeroBannerFragment[];
  iconicProducts: IconicProducts;
  motivationContent: MotivationContent;
  notificationBanner: NotificationBannerFragment['notificationBanner'] | null;
  productBoxConfiguration: Partial<StaticApplicationsProductBoxConfigurationDto>;
  topBrands: TopBrands;
  vipBenefits: VipBenefits;
  vipCustomerBanner: VipCustomerBannerFragment['vipCustomerBanner'];
  whatsNew: WhatsNew;
}

export const defaultFrontpageContextValue: FrontpageContext = {
  beautyVideo: { relativeUrl: '', title: '', videoId: '', videoPlayInLoop: false, shouldRender: false },
  benefitBanner: null,
  bestsellerProducts: { banner: null, headline: null, productBoxes: [], shouldRender: false },
  bestOffers: { banner: null, productBoxes: [], shouldRender: false },
  notificationBanner: null,
  campaignBanner: null,
  categoryListing: { banner: null, productBoxes: [], shouldRender: false },
  categoryNavigationItems: [],
  heroBanners: [],
  iconicProducts: { banner: null, description: null, headline: null, productBoxes: [], shouldRender: false },
  motivationContent: { headline: '', text: '', shouldRender: false },
  productBoxConfiguration: {},
  topBrands: { title: '', brands: [], shouldRender: false },
  vipBenefits: { images: [], linkRelativeUrl: '', linkTitle: '', shouldRender: false },
  vipCustomerBanner: null,
  whatsNew: { banner: null, productBoxes: [], shouldRender: false },
};

export const frontpageContext = createContext<FrontpageContext>(defaultFrontpageContextValue);

export const { Provider } = frontpageContext;

export interface FrontpageProviderProps extends PropsWithChildren {
  data: GetFrontPageQuery;
  productBoxConfiguration: Partial<StaticApplicationsProductBoxConfigurationDto>;
}

export const FrontpageProvider: FC<FrontpageProviderProps> = ({ data, productBoxConfiguration, children }) => {
  const { isAnonymousUser } = useToken();
  const value = useMemo<FrontpageContext>(
    () => ({
      beautyVideo: getBeautyVideo(data.application?.frontpage?.beautyVideo),
      benefitBanner: data.application?.frontpage?.benefitBanner,
      bestOffers: getBestOffers(data, isAnonymousUser),
      notificationBanner: data.application?.frontpage?.notificationBanner ?? null,
      campaignBanner:
        data.application?.frontpage?.digitalPromotions?.campaignBanners?.filter((banner) => banner)[0] ?? null,
      categoryListing: getCategoryListing(data, isAnonymousUser),
      categoryNavigationItems: getCategoryNavigationItems({
        categoryNavigation: data.application?.frontpage?.categoryNavigation,
      }),
      heroBanners: getHeroBanners(data.application?.frontpage?.digitalPromotions?.heroBanners),
      productBoxConfiguration,
      motivationContent: getMotivationContent(data.application?.frontpage?.motivationContent),
      topBrands: getTopBrands(data.application?.frontpage?.topBrands),
      vipBenefits: getVipBenefits(data.application?.frontpage?.vipBenefits),
      vipCustomerBanner: data.vipCustomerBanner,
      whatsNew: getWhatsNew(data, isAnonymousUser),
      bestsellerProducts: getBestSellerProducts(data.application?.frontpage?.bestsellerProducts, isAnonymousUser),
      iconicProducts: getIconicProducts(data.application?.frontpage?.iconicProducts, isAnonymousUser),
    }),
    [data, isAnonymousUser, productBoxConfiguration],
  );

  return <Provider value={value}>{children}</Provider>;
};

export const useFrontpage = () => useContext(frontpageContext);
