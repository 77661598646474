import { mapProductBoxesFromGql } from '@ori/product-box';

import type { GetFrontPageQuery, WhatsNew } from '../models';

export const shouldRenderWhatsNew = ({ productBoxes }: Pick<WhatsNew, 'productBoxes'>): boolean =>
  productBoxes.length > 0;

export const getWhatsNew = (data: GetFrontPageQuery, isAnonymousUser: boolean): WhatsNew => {
  const [banner] = data.application?.frontpage?.digitalPromotions?.whatsNewBanners ?? [];
  const productBoxes = mapProductBoxesFromGql({
    data: [...(data.application?.frontpage?.digitalPromotions?.whatsNewProducts ?? [])],
    isAnonymousUser,
  });

  return {
    banner: banner ?? null,
    productBoxes,
    shouldRender: shouldRenderWhatsNew({ productBoxes }),
  };
};
