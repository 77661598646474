import { mapProductBoxesFromGql } from '@ori/product-box';

import type { CategoryListing, IconicProducts, IconicProductsFragment } from '../models';

export const shouldRenderIconicProducts = ({ productBoxes }: Pick<CategoryListing, 'productBoxes'>): boolean =>
  productBoxes.length > 0;

export const getIconicProducts = (
  data: IconicProductsFragment['iconicProducts'],
  isAnonymousUser: boolean,
): IconicProducts => {
  const banner = data?.banner;
  const productBoxes = mapProductBoxesFromGql({ data: [...(data?.products ?? [])], isAnonymousUser });

  return {
    banner: banner ?? null,
    description: data?.description ?? null,
    headline: data?.headline ?? null,
    productBoxes,
    shouldRender: shouldRenderIconicProducts({ productBoxes }),
  };
};
