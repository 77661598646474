/* istanbul ignore file */

/**
  * DO NOT EDIT!
  * This file was autogenerated by graphql-codegen.
  * Changes may cause incorrect behavior and will be lost when the code is regenerated.
  *
  * Run `npx run codegen` to regenerate.
  */


export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: string; output: string; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string; output: string; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: unknown; output: unknown; }
  JSON: { input: Record<string, any>; output: Record<string, any>; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: unknown; output: unknown; }
  Void: { input: void; output: void; }
};

/** addThis configuration */
export type AddThis = {
  readonly __typename?: 'AddThis';
  /** profile id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** services list */
  readonly services?: Maybe<ReadonlyArray<Maybe<AddThisService>>>;
  /** shared url */
  readonly sharedUrl?: Maybe<Scalars['String']['output']>;
};

/** add this service */
export type AddThisService = {
  readonly __typename?: 'AddThisService';
  /** service css class */
  readonly cssClassName?: Maybe<Scalars['String']['output']>;
  /** service js class */
  readonly jsClassName?: Maybe<Scalars['String']['output']>;
  /** service name */
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type Address = {
  readonly __typename?: 'Address';
  /** Address id */
  readonly addressId: Scalars['ID']['output'];
  /** Address type */
  readonly addressType?: Maybe<AddressType>;
  /** Area */
  readonly area?: Maybe<Scalars['String']['output']>;
  /** City */
  readonly city?: Maybe<Scalars['String']['output']>;
  /** Coordinates */
  readonly coordinates?: Maybe<Coordinates>;
  /** Country */
  readonly country?: Maybe<Scalars['String']['output']>;
  /** Delivery */
  readonly delivery?: Maybe<Scalars['String']['output']>;
  /** Delivery2 */
  readonly delivery2?: Maybe<Scalars['String']['output']>;
  /** Delivery3 */
  readonly delivery3?: Maybe<Scalars['String']['output']>;
  /** District */
  readonly district?: Maybe<Scalars['String']['output']>;
  /** Is address primary */
  readonly isPrimary?: Maybe<Scalars['Boolean']['output']>;
  /** Locality */
  readonly locality?: Maybe<Scalars['String']['output']>;
  /** Province */
  readonly province?: Maybe<Scalars['String']['output']>;
  /** Region */
  readonly region?: Maybe<Scalars['String']['output']>;
  /** Street address */
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  /** Zip code */
  readonly zipcode?: Maybe<Scalars['String']['output']>;
};

export type AddressMetadata = {
  readonly __typename?: 'AddressMetadata';
  /** Address type */
  readonly addressType?: Maybe<AddressMetadataType>;
  /** Area */
  readonly area?: Maybe<AddressMetadataItem>;
  /** City */
  readonly city?: Maybe<AddressMetadataItem>;
  /** Country */
  readonly country?: Maybe<CountryMetadataItem>;
  /** Delivery */
  readonly delivery?: Maybe<AddressMetadataDelivery>;
  /** Delivery2 */
  readonly delivery2?: Maybe<AddressMetadataDelivery>;
  /** Delivery3 */
  readonly delivery3?: Maybe<AddressMetadataDelivery>;
  /** District */
  readonly district?: Maybe<AddressMetadataItem>;
  /** Is primary */
  readonly isPrimary?: Maybe<IsPrimaryMetadataItem>;
  /** KYC operations */
  readonly kycOperations?: Maybe<MetadataOperations>;
  /** Locality */
  readonly locality?: Maybe<AddressMetadataItem>;
  /** Operations */
  readonly operations?: Maybe<AddressMetadataOperations>;
  /** Province */
  readonly province?: Maybe<AddressMetadataItem>;
  /** Region */
  readonly region?: Maybe<AddressMetadataItem>;
  /** Search option */
  readonly searchOptions?: Maybe<AddressMetadataSearchOptions>;
  /** Street address */
  readonly streetAddress?: Maybe<AddressMetadataItem>;
  /** Subscription operations */
  readonly subscriptionOperations?: Maybe<MetadataOperations>;
  /** Zip code */
  readonly zipcode?: Maybe<AddressMetadataItem>;
};

/** Enum allowed search results */
export enum AddressMetadataAllowedSearchResults {
  Area = 'Area',
  City = 'City',
  Country = 'Country',
  District = 'District',
  Locality = 'Locality',
  Market = 'Market',
  Province = 'Province',
  Region = 'Region',
  Street = 'Street',
  ZipCode = 'ZipCode'
}

export type AddressMetadataDelivery = {
  readonly __typename?: 'AddressMetadataDelivery';
  /** Available options */
  readonly availableOptions?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Order of property in which should be preferably displayed (e.g. field on edit form) */
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  /** Is delivery available */
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Is delivery editable */
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Delivery validation */
  readonly validation?: Maybe<AddressMetadataDeliveryValidation>;
};

export type AddressMetadataDeliveryValidation = {
  readonly __typename?: 'AddressMetadataDeliveryValidation';
  /** Is delivery mandatory */
  readonly isMandatory?: Maybe<Scalars['Boolean']['output']>;
  /** Delivery maximal length */
  readonly maxLength?: Maybe<Scalars['Int']['output']>;
  /** Delivery minimal length */
  readonly minLength?: Maybe<Scalars['Int']['output']>;
  /** Regular expression */
  readonly regularExpression?: Maybe<Scalars['String']['output']>;
};

export type AddressMetadataItem = {
  readonly __typename?: 'AddressMetadataItem';
  /** Order of property in which should be preferably displayed (e.g. field on edit form) */
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  /** Is item available */
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Is item editable */
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Item validation */
  readonly validation?: Maybe<AddressMetadataValidation>;
};

export type AddressMetadataOperation = {
  readonly __typename?: 'AddressMetadataOperation';
  /** Is operation enabled */
  readonly isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** List of reasons */
  readonly reasons?: Maybe<ReadonlyArray<Maybe<AddressMetadataOperationReason>>>;
};

export type AddressMetadataOperationReason = {
  readonly __typename?: 'AddressMetadataOperationReason';
  /** Code */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** Message */
  readonly message?: Maybe<Scalars['String']['output']>;
};

export type AddressMetadataOperations = {
  readonly __typename?: 'AddressMetadataOperations';
  /** Create */
  readonly create?: Maybe<AddressMetadataOperation>;
  /** Delete */
  readonly delete?: Maybe<AddressMetadataOperation>;
  /** Delete primary */
  readonly deletePrimary?: Maybe<AddressMetadataOperation>;
  /** Read */
  readonly read?: Maybe<AddressMetadataOperation>;
  /** Update */
  readonly update?: Maybe<AddressMetadataOperation>;
};

export type AddressMetadataSearchOptions = {
  readonly __typename?: 'AddressMetadataSearchOptions';
  /** Allowed search results */
  readonly allowedSearchResults?: Maybe<ReadonlyArray<AddressMetadataAllowedSearchResults>>;
  /** Order of property in which should be preferably displayed (e.g. field on edit form) */
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  /** Is search options enabled */
  readonly isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of results offered during search */
  readonly maxResults?: Maybe<Scalars['Int']['output']>;
};

export type AddressMetadataType = {
  readonly __typename?: 'AddressMetadataType';
  /** Available options */
  readonly availableOptions?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Is address type available */
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Is address type editable */
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Address type validation */
  readonly validation?: Maybe<AddressMetadataTypeValidation>;
};

export type AddressMetadataTypeValidation = {
  readonly __typename?: 'AddressMetadataTypeValidation';
  /** Is field mandatory */
  readonly isMandatory?: Maybe<Scalars['Boolean']['output']>;
};

export type AddressMetadataValidation = {
  readonly __typename?: 'AddressMetadataValidation';
  /** Is address match required */
  readonly isAddressMatchRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Is field mandatory */
  readonly isMandatory?: Maybe<Scalars['Boolean']['output']>;
  /** Field maximal length */
  readonly maxLength?: Maybe<Scalars['Int']['output']>;
  /** Field minimal length */
  readonly minLength?: Maybe<Scalars['Int']['output']>;
  /** Regular expression */
  readonly regularExpression?: Maybe<Scalars['String']['output']>;
};

/** Enum address type */
export enum AddressType {
  Channel = 'Channel',
  Delivery = 'Delivery',
  Foreign = 'Foreign',
  Home = 'Home',
  Kyc = 'Kyc',
  Other = 'Other'
}

export enum AllowedClaimExchangeProducts {
  All = 'All',
  DifferentProductOnly = 'DifferentProductOnly',
  SameProductOnly = 'SameProductOnly'
}

export type AppLink = {
  readonly __typename?: 'AppLink';
  /** Deep link */
  readonly deepLink?: Maybe<Scalars['String']['output']>;
  /** Link */
  readonly link?: Maybe<Scalars['String']['output']>;
};

export type Application = {
  readonly __typename?: 'Application';
  /** Bazaarvoice configuration */
  readonly bazaarvoiceConfiguration?: Maybe<BazaarvoiceConfiguration>;
  /** Breadcrumb links for a page URL */
  readonly breadcrumbLinks?: Maybe<BreadcrumbLinks>;
  readonly catalogueLandingPage?: Maybe<CatalogueLandingPage>;
  /** Editorial landing page */
  readonly editorialLandingPage?: Maybe<EditorialLandingPage>;
  /** Editorial landing pages */
  readonly editorialLandingPages?: Maybe<ReadonlyArray<Maybe<EditorialLandingPage>>>;
  /** Editorial page */
  readonly editorialPage?: Maybe<EditorialPage>;
  /** Editorial pages */
  readonly editorialPages?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  /** Feedback benefit banners */
  readonly feedbackBenefitBanners?: Maybe<ReadonlyArray<Maybe<FeedbackBenefitBanner>>>;
  /** Footer */
  readonly footer?: Maybe<Footer>;
  /** Frontpage v3 content */
  readonly frontpage?: Maybe<Frontpage>;
  /** Ingredient library */
  readonly ingredientLibrary?: Maybe<IngredientLibrary>;
  /** Makeup Try On */
  readonly makeupTryOnConfiguration?: Maybe<MakeupTryOnConfiguration>;
  /**
   * The get navigation async.
   *
   *
   * **Returns:**
   * The result.
   */
  readonly navigation?: Maybe<NavigationCollection>;
  /**
   * The get navigation async.
   *
   *
   * **Returns:**
   * The result.
   */
  readonly navigations?: Maybe<ReadonlyArray<Maybe<NavigationCollection>>>;
  /** Olapic configuration */
  readonly olapic?: Maybe<OlapicConfiguration>;
  /** Navigation menu for a single page */
  readonly pageNavigation?: Maybe<PageNavigation>;
  /** Navigation menu for multiple pages */
  readonly pageNavigations?: Maybe<ReadonlyArray<Maybe<PageNavigation>>>;
  readonly permanentTopBanners?: Maybe<ReadonlyArray<Maybe<PermanentTopBanner>>>;
  readonly personalEditorialLandingPage?: Maybe<PersonalEditorialLandingPage>;
  readonly personalEditorialLandingPages?: Maybe<ReadonlyArray<Maybe<PersonalEditorialLandingPage>>>;
  readonly personalEditorialPage?: Maybe<EditorialPage>;
  readonly personalEditorialPages?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  /** Personal product list configuration */
  readonly personalProductListConfiguration?: Maybe<PersonalProductListConfiguration>;
  /** Product detail page */
  readonly productDetailPage?: Maybe<ProductDetailPage>;
  /** Product listing page */
  readonly productListingPage?: Maybe<ProductListingPage>;
  /** Product Search Page */
  readonly productSearchPage?: Maybe<ProductSearchPage>;
  /** Preview of product search results */
  readonly productSearchPreview?: Maybe<ProductSearchPreview>;
  readonly ratingsAndReviewsConfiguration?: Maybe<RatingsAndReviewsConfiguration>;
  /** Share and earn page */
  readonly shareAndEarn?: Maybe<ShareAndEarn>;
  /** Links meant for user sharing */
  readonly sharingLinks?: Maybe<SharingLinks>;
  /** Wellness Club */
  readonly wellnessClub?: Maybe<WellnessClub>;
};


export type ApplicationBreadcrumbLinksArgs = {
  pageUrl?: InputMaybe<Scalars['String']['input']>;
};


export type ApplicationEditorialLandingPageArgs = {
  channelType?: EditorialChannelType;
  landingPageId?: InputMaybe<Scalars['ID']['input']>;
};


export type ApplicationEditorialLandingPagesArgs = {
  channelType?: EditorialChannelType;
  landingPageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};


export type ApplicationEditorialPageArgs = {
  channelType?: EditorialChannelType;
  pageId?: InputMaybe<Scalars['ID']['input']>;
};


export type ApplicationEditorialPagesArgs = {
  channelType?: EditorialChannelType;
  pageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


export type ApplicationFeedbackBenefitBannersArgs = {
  bannerTypes?: InputMaybe<ReadonlyArray<FeedbackBenefitBannerType>>;
};


export type ApplicationNavigationArgs = {
  customerTypeId?: InputMaybe<Scalars['Int']['input']>;
  navigationType: NavigationType;
  nodeId?: InputMaybe<Scalars['String']['input']>;
  visitorSegment?: InputMaybe<VisitorSegment>;
};


export type ApplicationNavigationsArgs = {
  customerTypeId?: InputMaybe<Scalars['Int']['input']>;
  navigationTypes?: InputMaybe<ReadonlyArray<NavigationType>>;
  nodeId?: InputMaybe<Scalars['String']['input']>;
  visitorSegment?: InputMaybe<VisitorSegment>;
};


export type ApplicationOlapicArgs = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  olapicType: OlapicTypeEnum;
  productCode?: InputMaybe<Scalars['ID']['input']>;
};


export type ApplicationPageNavigationArgs = {
  pageId?: InputMaybe<Scalars['String']['input']>;
};


export type ApplicationPageNavigationsArgs = {
  pageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type ApplicationPermanentTopBannersArgs = {
  bannerType: PermanentTopBannerType;
};


export type ApplicationPersonalEditorialLandingPageArgs = {
  landingPageId?: InputMaybe<Scalars['ID']['input']>;
};


export type ApplicationPersonalEditorialLandingPagesArgs = {
  landingPageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


export type ApplicationPersonalEditorialPageArgs = {
  pageId?: InputMaybe<Scalars['ID']['input']>;
};


export type ApplicationPersonalEditorialPagesArgs = {
  pageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


export type ApplicationProductDetailPageArgs = {
  productCode?: InputMaybe<Scalars['ID']['input']>;
};


export type ApplicationProductListingPageArgs = {
  pageId?: InputMaybe<Scalars['ID']['input']>;
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Approval = {
  readonly __typename?: 'Approval';
  /** Blocks the consultant's guarantee and SPO manager from ordering on their behalf */
  readonly blockGuaranteeOrdering?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for receiving info emails/SMS */
  readonly contact?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for receiving emails/SMS from beauty consultant */
  readonly marketingConsultant?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for receiving marketing information from external sources */
  readonly marketingExternal?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for receiving marketing information from Oriflame */
  readonly marketingOriflame?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for receiving newsletters */
  readonly newsletters?: Maybe<Scalars['Boolean']['output']>;
  /** Customer cannot be selected as sponsor by different subjects (upline consultant, self. registration, locator, ...) */
  readonly optedOutOfSponsorSelect?: Maybe<Scalars['Boolean']['output']>;
  /** Nova customer approval for share Order history with allocated beauty Consultant */
  readonly orderHistory?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for displaying personal data */
  readonly personalData?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for publication in Oriflame materials */
  readonly publication?: Maybe<Scalars['Boolean']['output']>;
  /** Leader approval for upline Leader to browse leader downline members */
  readonly uplineLeaderBrowse?: Maybe<Scalars['Boolean']['output']>;
  /** Leader approval for upline Leader to display leader's data */
  readonly uplineLeaderData?: Maybe<Scalars['Boolean']['output']>;
};

export type Article = {
  readonly __typename?: 'Article';
  /** Type of a article */
  readonly articleType?: Maybe<ArticleType>;
  /** Title of the category of article */
  readonly categoryTitle?: Maybe<Scalars['String']['output']>;
  /** Article's image */
  readonly image?: Maybe<ArticleImage>;
  /** Introductory text from article */
  readonly introText?: Maybe<Scalars['String']['output']>;
  /** Flag indicating if article contains video */
  readonly isVideoArticle?: Maybe<Scalars['Boolean']['output']>;
  /** Link to the article */
  readonly link?: Maybe<Link>;
  /** The get products. */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Date of article's publication */
  readonly published?: Maybe<Scalars['DateTime']['output']>;
  /** Title of the article */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ArticleImage = {
  readonly __typename?: 'ArticleImage';
  /** Alternative text of an image */
  readonly alt?: Maybe<Scalars['String']['output']>;
  /** Image's url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

export enum ArticleType {
  /** Articles from Beauty Edit section */
  BeautyEdit = 'BeautyEdit',
  /** Articles related to products */
  RelatedToProduct = 'RelatedToProduct',
  /** Default value - not for use with API. Exists to detect errors in parsing values */
  Undefined = 'Undefined'
}

export type Attribute = {
  readonly __typename?: 'Attribute';
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type BackInStockAvailability = {
  readonly __typename?: 'BackInStockAvailability';
  /** Flag indicating that back in stock notification is available */
  readonly showBackInStockNotification?: Maybe<Scalars['Boolean']['output']>;
};

/** Channel to identify how we should notify user */
export enum BackInStockNotificationChannel {
  /** We will notify customer on provided email */
  Email = 'Email',
  /** We will notify customer on provided telephone */
  Sms = 'Sms'
}

export type BackInStockNotificationSubscriptionInput = {
  /** Email value for notificationChannel.Email */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** The product code to watch */
  readonly itemCode?: InputMaybe<Scalars['String']['input']>;
  /** Flag indicating the channel for notification */
  readonly notificationChannel: BackInStockNotificationChannel;
  /** Telephone value for notificationChannel.Telephone */
  readonly telephoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Background types */
export enum BackgroundType {
  /** Color as a background */
  Color = 'Color',
  /** Image as a background */
  Image = 'Image',
  /** No background */
  None = 'None',
  /** Video as a background */
  Video = 'Video'
}

export type Bank = {
  readonly __typename?: 'Bank';
  /** Id of the Bank */
  readonly bankId?: Maybe<Scalars['Int']['output']>;
  /** Bank code */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** Name of the Bank */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** SWIFT/BIC code */
  readonly swiftBic?: Maybe<Scalars['String']['output']>;
};

export type BankAccount = {
  readonly __typename?: 'BankAccount';
  /** The Bank */
  readonly bank?: Maybe<Bank>;
  /** Bank Account ID */
  readonly bankAccountId: Scalars['ID']['output'];
  /** Branch of the Bank */
  readonly bankBranch?: Maybe<BankBranch>;
  /** Subbranch of the Bank */
  readonly bankSubBranch?: Maybe<Scalars['String']['output']>;
  /** Bank account number */
  readonly number?: Maybe<Scalars['String']['output']>;
  /** Bank account owner */
  readonly owner?: Maybe<Scalars['String']['output']>;
};

export enum BankBonusPaymentTransferState {
  NothingForTransfer = 'NothingForTransfer',
  Pending = 'Pending',
  Transferred = 'Transferred',
  Undefined = 'Undefined'
}

export type BankBranch = {
  readonly __typename?: 'BankBranch';
  /** Id of the bank branch */
  readonly bankBranchId?: Maybe<Scalars['Int']['output']>;
  /** Bank branch code */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** Bank branch name */
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type BazaarvoiceConfiguration = {
  readonly __typename?: 'BazaarvoiceConfiguration';
  /** Checks if Conversations Platform is enabled */
  readonly conversationsPlatformEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Checks if Dream Catcher is enabled */
  readonly dreamCatcherEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Dream Catcher Product Code */
  readonly dreamCatcherProductCode?: Maybe<Scalars['String']['output']>;
  /** Legal notice read-more link */
  readonly legalNoticeLink?: Maybe<Link>;
  /** Legal notice text */
  readonly legalNoticeText?: Maybe<Scalars['String']['output']>;
  /** Checks if Questions And Answers are enabled */
  readonly questionsAndAnswersEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Checks if Questions And Answers are Enabled For Segments */
  readonly questionsAndAnswersEnabledForSegments?: Maybe<Scalars['Boolean']['output']>;
  /** Checks if Ratings And Reviews are enabled */
  readonly ratingsAndReviewsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Url for Submission Client Script */
  readonly submissionClientScriptUrl?: Maybe<Scalars['String']['output']>;
  /** Submission Page Id */
  readonly submissionPageId?: Maybe<Scalars['String']['output']>;
  /** Checks if Transaction Tracking is enabled */
  readonly transactionTrackingEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** General Url for bazaarvoice */
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type BeautyEdit = {
  readonly __typename?: 'BeautyEdit';
  readonly editorialArticles?: Maybe<ReadonlyArray<Maybe<Article>>>;
  /** Url of the main image of Beauty Edit section */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Main text of Beauty Edit section. */
  readonly text?: Maybe<Scalars['String']['output']>;
};


export type BeautyEditEditorialArticlesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Int']['input']>;
};

export type BeautyVideo = {
  readonly __typename?: 'BeautyVideo';
  /** Link to Beauty Edit section */
  readonly link?: Maybe<Link>;
  /** Video displayed in the section */
  readonly video?: Maybe<Video>;
};

export type BenefitBanner = {
  readonly __typename?: 'BenefitBanner';
  /** Title */
  readonly messageTitle?: Maybe<Scalars['String']['output']>;
  /** URL */
  readonly messageUrl?: Maybe<Scalars['String']['output']>;
};

export type BestOffersBanner = {
  readonly __typename?: 'BestOffersBanner';
  /** Background color */
  readonly backgroundColor?: Maybe<Scalars['String']['output']>;
  /** Font color -eg. red, grey */
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  /** Headline of the banner */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image url of the banner */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link type, attributes and Id */
  readonly link?: Maybe<Link>;
  /** Mobile font color -eg. red, grey */
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  /** Banner Validity date from */
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Banner validity date to */
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type BestsellerProducts = {
  readonly __typename?: 'BestsellerProducts';
  /** Banner of the iconic products. */
  readonly banner?: Maybe<ProductsPromotionBanner>;
  /** Headline of the iconic products. */
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

export type Brand = {
  readonly __typename?: 'Brand';
  /** Brand id */
  readonly brandId: Scalars['ID']['output'];
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Background Image URL */
  readonly image?: Maybe<Scalars['String']['output']>;
  /** Collection of logo images with sizes */
  readonly logo?: Maybe<ReadonlyArray<Maybe<SizedImage>>>;
  /** Localized name of the brand */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Original name of the brand used for product/concept indexing */
  readonly originalName?: Maybe<Scalars['String']['output']>;
  /** Collection of sub-brands */
  readonly subBrands?: Maybe<ReadonlyArray<Maybe<Brand>>>;
  /** Taxonomy id */
  readonly taxonomyId: Scalars['ID']['output'];
  /** SEO headline text from product area */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Title image */
  readonly titleImage?: Maybe<Scalars['String']['output']>;
  /** Brand Page url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type BreadcrumbLinks = {
  readonly __typename?: 'BreadcrumbLinks';
  /** Collection of links for the breadcrumb navigation */
  readonly links?: Maybe<ReadonlyArray<Maybe<Link>>>;
};

/** The bundle component resolver. */
export type BundleComponent = {
  readonly __typename?: 'BundleComponent';
  /** The product bundle components . */
  readonly product?: Maybe<Product>;
  /** Quantity of a product in a bundle */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
};

export type CampaignBanner = {
  readonly __typename?: 'CampaignBanner';
  /** Background color -eg. red, grey */
  readonly backgroundColor?: Maybe<Scalars['String']['output']>;
  /** Font color -eg. red, grey */
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  /** Headline of the banner */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image url of the banner */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link type, attributes and Id */
  readonly link?: Maybe<Link>;
  /** Mobile font color -eg. red, grey */
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  /** Short text of the banner */
  readonly shortText?: Maybe<Scalars['String']['output']>;
  /** Tagline of the banner */
  readonly tagline?: Maybe<Scalars['String']['output']>;
  /** Banner Validity date from */
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Banner validity date to */
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
};

export enum CanCancelOrderResult {
  Allowed = 'Allowed',
  AlreadyCancelled = 'AlreadyCancelled',
  AlreadyRequested = 'AlreadyRequested',
  CancellationNotAllowedByOrderFlow = 'CancellationNotAllowedByOrderFlow',
  CancellationNotAllowedByOrisales = 'CancellationNotAllowedByOrisales',
  CancellationOfOrdersWithGiftCardsIsDisabled = 'CancellationOfOrdersWithGiftCardsIsDisabled',
  FinalizedNotSynchronized = 'FinalizedNotSynchronized',
  Hidden = 'Hidden',
  InsufficientPermissions = 'InsufficientPermissions',
  IsFromOrisales = 'IsFromOrisales',
  IsNotAllowedPeriodOrder = 'IsNotAllowedPeriodOrder',
  IsNotCurrentPeriodOrder = 'IsNotCurrentPeriodOrder',
  IsOrderOriginPharmacy = 'IsOrderOriginPharmacy',
  IsPaidOnline = 'IsPaidOnline',
  IsPrinted = 'IsPrinted',
  PendingOnlinePayment = 'PendingOnlinePayment',
  PeriodClosingMinutesOffset = 'PeriodClosingMinutesOffset',
  PlaceOrderMinutesOffset = 'PlaceOrderMinutesOffset',
  RedeemedGiftCard = 'RedeemedGiftCard'
}

export type Catalogue = {
  readonly __typename?: 'Catalogue';
  /** catalogue code */
  readonly catalogueCode: Scalars['ID']['output'];
};

/** Represents the information of a catalogue. */
export type CatalogueInfo = {
  readonly __typename?: 'CatalogueInfo';
  /** The type of the catalogue. */
  readonly catalogueType: CatalogueType;
  /** The image URL of the catalogue. */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** The link associated with the catalogue. */
  readonly link?: Maybe<Link>;
  /** The title of the catalogue. */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** The valid from date of the catalogue. */
  readonly validFrom: Scalars['DateTime']['output'];
  /** The valid to date of the catalogue. */
  readonly validTo: Scalars['DateTime']['output'];
};

export type CatalogueLandingPage = {
  readonly __typename?: 'CatalogueLandingPage';
  /** The collection of digital catalogues. */
  readonly digitalCatalogues?: Maybe<ReadonlyArray<Maybe<CatalogueInfo>>>;
  /** The collection of other catalogue types. */
  readonly otherCatalogues?: Maybe<ReadonlyArray<Maybe<CatalogueInfo>>>;
};

export type CatalogueOffer = {
  readonly __typename?: 'CatalogueOffer';
  /** Benefit Product */
  readonly benefits?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProductBenefit>>>;
  /** Quantity of benefit */
  readonly benefitsQuantity?: Maybe<Scalars['Int']['output']>;
  /** Concept linked to the offer */
  readonly concepts?: Maybe<ReadonlyArray<Maybe<CatalogueOffersConcept>>>;
  /** Benefit concepts */
  readonly conceptsBenefits?: Maybe<ReadonlyArray<Maybe<CatalogueOffersConceptBenefit>>>;
  /** Percent of the discount */
  readonly discountPercent?: Maybe<Scalars['Float']['output']>;
  /** Quantity of missing products */
  readonly missingQuantity?: Maybe<Scalars['Int']['output']>;
  /** Offer id */
  readonly offerId?: Maybe<Scalars['Int']['output']>;
  /** Product linked to the offer */
  readonly products?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProduct>>>;
  /** Total price */
  readonly totalPrice?: Maybe<Scalars['Float']['output']>;
  /** Total price after discount */
  readonly totalPriceAfterDiscount?: Maybe<Scalars['Float']['output']>;
  /** Type of the offer */
  readonly type?: Maybe<CatalogueOfferType>;
};

export enum CatalogueOfferType {
  /** Special offer for buying product */
  Buy = 'Buy',
  /** Choose from products */
  Choose = 'Choose',
  /** Choose a product and choose another */
  ChooseAndChoose = 'ChooseAndChoose',
  /** Choose a product and get another */
  ChooseAndGet = 'ChooseAndGet',
  /** Get a product */
  Get = 'Get',
  /** No type of catalogue offer was specified */
  None = 'None'
}

export type CatalogueOffersConcept = {
  readonly __typename?: 'CatalogueOffersConcept';
  /** Buisness points */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Offer concept */
  readonly concept?: Maybe<Concept>;
  /** Price of the concept */
  readonly price?: Maybe<Scalars['Float']['output']>;
  /** Quantity of the product */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  /** Shades of the product */
  readonly shades?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProduct>>>;
};

export type CatalogueOffersConceptBenefit = {
  readonly __typename?: 'CatalogueOffersConceptBenefit';
  /** Buisness points */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Concept */
  readonly concept?: Maybe<Concept>;
  /** Price */
  readonly price?: Maybe<Scalars['Float']['output']>;
  /** Purchase price */
  readonly purchasePrice?: Maybe<Scalars['Float']['output']>;
  /** Quantity of the concept benefit */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  /** Shades of the concept benefit */
  readonly shades?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProductBenefit>>>;
};

export type CatalogueOffersProduct = {
  readonly __typename?: 'CatalogueOffersProduct';
  /** Buisness points */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Price of the product */
  readonly price?: Maybe<Scalars['Float']['output']>;
  /** Offer product */
  readonly product?: Maybe<Product>;
  /** Quantity of the product */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
};

export type CatalogueOffersProductBenefit = {
  readonly __typename?: 'CatalogueOffersProductBenefit';
  /** Buisness points */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Is purchased flag */
  readonly isPurchased?: Maybe<Scalars['Boolean']['output']>;
  /** Price */
  readonly price?: Maybe<Scalars['Float']['output']>;
  /** The get product. */
  readonly product?: Maybe<Product>;
  /** Purchase price of the product benefit */
  readonly purchasePrice?: Maybe<Scalars['Float']['output']>;
  /** Quantity of the product benefit */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  /** The get replaced product. */
  readonly replacedPoduct?: Maybe<Product>;
};

export enum CatalogueType {
  /** DigitalCurrent catalogue type */
  DigitalCurrent = 'DigitalCurrent',
  /** DigitalNext catalogue type */
  DigitalNext = 'DigitalNext',
  /** LocalCustom catalogue type */
  LocalCustom = 'LocalCustom',
  /** Lookbook catalogue type */
  Lookbook = 'Lookbook',
  None = 'None'
}

export type Category = {
  readonly __typename?: 'Category';
  /** Expiration date description used for category */
  readonly expirationDateDescription?: Maybe<Scalars['String']['output']>;
  /** Category Id */
  readonly id: Scalars['ID']['output'];
  /** Category image */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Localized category name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Gets the navigation tags for the category. */
  readonly navigationTags?: Maybe<ReadonlyArray<Maybe<NavigationTag>>>;
  /** Original name of the category used for product/concept indexing */
  readonly originalName?: Maybe<Scalars['String']['output']>;
  /** Collection of subcategories */
  readonly subCategories?: Maybe<ReadonlyArray<Maybe<Category>>>;
  /** Taxonomy ID of the category */
  readonly taxonomyId: Scalars['ID']['output'];
};

export type CategoryBanner = {
  readonly __typename?: 'CategoryBanner';
  /** Background color */
  readonly backgroundColor?: Maybe<Scalars['String']['output']>;
  /** Font color -eg. red, grey */
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  /** Headline of the banner */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image url of the banner */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link type, attributes and Id */
  readonly link?: Maybe<Link>;
  /** Mobile font color -eg. red, grey */
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  /** Short text of the banner */
  readonly shortText?: Maybe<Scalars['String']['output']>;
  /** Tagline of the banner */
  readonly tagline?: Maybe<Scalars['String']['output']>;
  /** Banner Validity date from */
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Banner validity date to */
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
};

export enum ChannelType {
  Mv7 = 'MV7',
  Website = 'Website'
}

export type ClaimAction = {
  readonly __typename?: 'ClaimAction';
  readonly actionId: ClaimActionType;
  readonly description?: Maybe<Scalars['String']['output']>;
};

export enum ClaimActionType {
  CancelOrder = 'CancelOrder',
  CancelProduct = 'CancelProduct',
  ExchangeProduct = 'ExchangeProduct',
  None = 'None',
  ResendOrder = 'ResendOrder',
  ResendProduct = 'ResendProduct',
  ReturnProduct = 'ReturnProduct'
}

export type ClaimActions = {
  readonly __typename?: 'ClaimActions';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ClaimAction>>>;
};

export type ClaimBundleItem = {
  readonly __typename?: 'ClaimBundleItem';
  /** Bundle component identification */
  readonly componentNumber: Scalars['Int']['output'];
  /** Code of the Product/Fee/Dropping/... */
  readonly itemCode?: Maybe<Scalars['String']['output']>;
  /** The item unit price */
  readonly price: Scalars['Decimal']['output'];
  /** The get product from the order. */
  readonly product?: Maybe<Product>;
  /** Quantity which could be claimed */
  readonly quantity: Scalars['Int']['output'];
};

export type ClaimBundleItems = {
  readonly __typename?: 'ClaimBundleItems';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ClaimBundleItem>>>;
};

export type ClaimCategories = {
  readonly __typename?: 'ClaimCategories';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ClaimCategory>>>;
};

export type ClaimCategory = {
  readonly __typename?: 'ClaimCategory';
  /** The claim category actions. */
  readonly actions?: Maybe<ClaimActions>;
  readonly categoryId: ClaimCategoryType;
  /** The claim category causes. */
  readonly causes?: Maybe<ClaimCategoryCauses>;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** The claim category scenarios. */
  readonly scenarios?: Maybe<ClaimScenarios>;
  /** The claim sub-categories. */
  readonly subCategories?: Maybe<ClaimCategories>;
};


export type ClaimCategoryScenariosArgs = {
  actionId: ClaimActionType;
  causeId: ClaimCauseType;
};

export type ClaimCategoryCause = {
  readonly __typename?: 'ClaimCategoryCause';
  readonly categoryId: ClaimCategoryType;
  readonly causeId: ClaimCauseType;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** The claim causes. */
  readonly reasons?: Maybe<ClaimReasons>;
};

export type ClaimCategoryCauses = {
  readonly __typename?: 'ClaimCategoryCauses';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ClaimCategoryCause>>>;
};

export enum ClaimCategoryType {
  DistributionIssue = 'DistributionIssue',
  Exchange = 'Exchange',
  InvoiceIssue = 'InvoiceIssue',
  Missing = 'Missing',
  ProductJustMissing = 'ProductJustMissing',
  Return = 'Return',
  ReturnExchangeMissing = 'ReturnExchangeMissing',
  Root = 'Root',
  WrongProductDelivered = 'WrongProductDelivered'
}

export enum ClaimCauseType {
  AdverseReaction = 'AdverseReaction',
  CancelOrder = 'CancelOrder',
  DamagedOrDefectedProduct = 'DamagedOrDefectedProduct',
  ExtraProductReceivedMissingOnTheInvoice = 'ExtraProductReceivedMissingOnTheInvoice',
  ExtraProductReceivedWrongQuantity = 'ExtraProductReceivedWrongQuantity',
  InvoiceCampaignProductMissing = 'InvoiceCampaignProductMissing',
  InvoiceWrongPriceOrFee = 'InvoiceWrongPriceOrFee',
  MissingProduct = 'MissingProduct',
  ParcelDamaged = 'ParcelDamaged',
  ParcelNotDelivered = 'ParcelNotDelivered',
  ParcelRefused = 'ParcelRefused',
  RefundReturnCost = 'RefundReturnCost',
  SupplementaryProduct = 'SupplementaryProduct',
  UncollectedParcel = 'UncollectedParcel',
  UnwantedProduct = 'UnwantedProduct',
  WrongPriceFix = 'WrongPriceFix',
  WrongProductDelivered = 'WrongProductDelivered'
}

export type ClaimDelivery = {
  readonly __typename?: 'ClaimDelivery';
  /** Selected delivery address */
  readonly addressId?: Maybe<Scalars['Long']['output']>;
  /** The delivery channel. */
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  /** Selected delivery channel */
  readonly deliveryChannelId?: Maybe<Scalars['Long']['output']>;
};

export type ClaimDeliveryChannel = {
  readonly __typename?: 'ClaimDeliveryChannel';
  /** Home delivery address */
  readonly deliveryAddressId?: Maybe<Scalars['Long']['output']>;
  /** The delivery channel. */
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  /** Delivery channel id */
  readonly deliveryChannelId: Scalars['Long']['output'];
  /** SPO, home delivery, ... */
  readonly deliveryMethod: DeliveryMethodType;
  /** Delivery channel name (e.g. DHL) */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Courier logo */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Cash-on-delivery is allowed */
  readonly isCodAllowed: Scalars['Boolean']['output'];
  /** Luxury (branded) delivery channel - special usage for SPOs */
  readonly isLuxury: Scalars['Boolean']['output'];
  /** Pick-up point address */
  readonly pickupAddressId?: Maybe<Scalars['Long']['output']>;
  /** Phone contact to the delivery channel */
  readonly telephoneNumber?: Maybe<Scalars['String']['output']>;
  /** Working hours description (e.g. Mo-Fr: 10:00 - 16:00) */
  readonly workingHours?: Maybe<Scalars['String']['output']>;
};

export type ClaimDeliveryChannels = {
  readonly __typename?: 'ClaimDeliveryChannels';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ClaimDeliveryChannel>>>;
};

export type ClaimDeliveryMethod = {
  readonly __typename?: 'ClaimDeliveryMethod';
  /** Delivery method name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Category of delivery method */
  readonly type: ClaimDeliveryMethodType;
};

export enum ClaimDeliveryMethodType {
  DepartmentStore = 'DepartmentStore',
  Home = 'Home',
  LastUsed = 'LastUsed',
  Lpo = 'Lpo',
  NearestPostOffice = 'NearestPostOffice',
  NextOrder = 'NextOrder',
  ParcelLocker = 'ParcelLocker',
  PickupPoint = 'PickupPoint',
  PostOffice = 'PostOffice',
  Spo = 'Spo'
}

export type ClaimDeliveryMethods = {
  readonly __typename?: 'ClaimDeliveryMethods';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ClaimDeliveryMethod>>>;
};

export type ClaimDescription = {
  readonly __typename?: 'ClaimDescription';
  /** Customer's note */
  readonly note?: Maybe<Scalars['String']['output']>;
};

export type ClaimItems = {
  readonly __typename?: 'ClaimItems';
  /** Exchanged products */
  readonly exchangedItems?: Maybe<ReadonlyArray<Maybe<ExchangedClaimItem>>>;
  /** Missing products */
  readonly missingItems?: Maybe<ReadonlyArray<Maybe<MissedClaimItem>>>;
  /** Returned products */
  readonly returnedItems?: Maybe<ReadonlyArray<Maybe<ReturnedClaimItem>>>;
  /** Products with wrong price */
  readonly wrongItems?: Maybe<ReadonlyArray<Maybe<ExchangedClaimItem>>>;
};

export type ClaimOrderItem = {
  readonly __typename?: 'ClaimOrderItem';
  /** Bundle components */
  readonly bundleItems?: Maybe<ClaimBundleItems>;
  /** Identity of the products should not be presented (surprise gift) */
  readonly isHidden: Scalars['Boolean']['output'];
  /** Code of the Product/Fee/Dropping/... */
  readonly itemCode?: Maybe<Scalars['String']['output']>;
  /** Order item identification */
  readonly orderItemId: Scalars['Long']['output'];
  /** The item unit price */
  readonly price: Scalars['Decimal']['output'];
  /** The get product from the order. */
  readonly product?: Maybe<Product>;
  /** Quantity which could be claimed */
  readonly quantity: Scalars['Int']['output'];
};

export type ClaimOrderItems = {
  readonly __typename?: 'ClaimOrderItems';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ClaimOrderItem>>>;
};

export enum ClaimProductItemCategory {
  AddOns = 'AddOns',
  Cosmetics = 'Cosmetics',
  DemoProduct = 'DemoProduct',
  Fee = 'Fee',
  Jewellery = 'Jewellery',
  NonFinancial = 'NonFinancial',
  None = 'None',
  Other = 'Other',
  OtherSample = 'OtherSample',
  Packaging = 'Packaging',
  PrintingMaterial = 'PrintingMaterial',
  RecruitmentGifts = 'RecruitmentGifts',
  Training = 'Training',
  Unknown = 'Unknown',
  Wellness = 'Wellness'
}

export type ClaimReason = {
  readonly __typename?: 'ClaimReason';
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly reasonId: ClaimReasonType;
};

export enum ClaimReasonType {
  BatchNumberOrExpiryDateMissingNotReadable = 'BatchNumberOrExpiryDateMissingNotReadable',
  ColorVariation = 'ColorVariation',
  ConsistencyColorOrScentIssue = 'ConsistencyColorOrScentIssue',
  ConsistencyOrTextureVariation = 'ConsistencyOrTextureVariation',
  ContaminatedByForeignObject = 'ContaminatedByForeignObject',
  ContentDamagedByLeakageFromAnotherProduct = 'ContentDamagedByLeakageFromAnotherProduct',
  DamagedByAnotherProduct = 'DamagedByAnotherProduct',
  DamagedContainerJarTubeBottlePouch = 'DamagedContainerJarTubeBottlePouch',
  DamagedOrDeformed = 'DamagedOrDeformed',
  DamagedOrDeformed_22 = 'DamagedOrDeformed_22',
  DamagedPackagingExternalProductBoxTamperProofPolybagFoil = 'DamagedPackagingExternalProductBoxTamperProofPolybagFoil',
  DescriptionFoundInCatalogue = 'DescriptionFoundInCatalogue',
  DescriptionFoundInOtherCommunication = 'DescriptionFoundInOtherCommunication',
  DescriptionFoundOnline = 'DescriptionFoundOnline',
  DidntMatchTheProductDescription = 'DidntMatchTheProductDescription',
  DidntMeetExpectations = 'DidntMeetExpectations',
  DifferentProductThanStatedOnPackOrLabel = 'DifferentProductThanStatedOnPackOrLabel',
  ForeignMatter = 'ForeignMatter',
  Leakage = 'Leakage',
  Malfunction = 'Malfunction',
  MechanismProblems = 'MechanismProblems',
  MissingLabelOrLeaflet = 'MissingLabelOrLeaflet',
  MissingPart = 'MissingPart',
  MissingPartsOrUnderfilled = 'MissingPartsOrUnderfilled',
  NoReasonJustChangedMyMind = 'NoReasonJustChangedMyMind',
  NotUsedProduct = 'NotUsedProduct',
  NotUsedProductNoReason = 'NotUsedProductNoReason',
  NotUsedProductNotMeetExpectations = 'NotUsedProductNotMeetExpectations',
  NotWorkingAtAll = 'NotWorkingAtAll',
  PackagingDamagedByLeakageFromAnotherProduct = 'PackagingDamagedByLeakageFromAnotherProduct',
  PackagingQualityIssue = 'PackagingQualityIssue',
  ParcelDelayed = 'ParcelDelayed',
  ParcelIsLost = 'ParcelIsLost',
  PoorLabelling = 'PoorLabelling',
  ProductContentQualityIssue = 'ProductContentQualityIssue',
  ProductIsLeaking = 'ProductIsLeaking',
  SmellChangedOrUnpleasant = 'SmellChangedOrUnpleasant',
  TasteChangedOrUnpleasant = 'TasteChangedOrUnpleasant',
  UsedProduct = 'UsedProduct',
  UsedProductNoReason = 'UsedProductNoReason',
  UsedProductNotMeetExpectations = 'UsedProductNotMeetExpectations',
  WorkingPoorly = 'WorkingPoorly',
  WrongAddress = 'WrongAddress',
  WrongProductOrdered = 'WrongProductOrdered',
  WrongQuantity = 'WrongQuantity'
}

export type ClaimReasons = {
  readonly __typename?: 'ClaimReasons';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ClaimReason>>>;
};

export type ClaimReplacementItem = {
  readonly __typename?: 'ClaimReplacementItem';
  /** Product code */
  readonly itemCode?: Maybe<Scalars['String']['output']>;
  /** The get product from the claim. */
  readonly product?: Maybe<Product>;
  /** Product quantity */
  readonly quantity: Scalars['Int']['output'];
  /** BP to be ordered */
  readonly totalBp: Scalars['Decimal']['output'];
  /** Price to be ordered */
  readonly totalPrice: Scalars['Decimal']['output'];
};

export type ClaimReplacementItems = {
  readonly __typename?: 'ClaimReplacementItems';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ClaimReplacementItem>>>;
};

export type ClaimScenario = {
  readonly __typename?: 'ClaimScenario';
  readonly allowedExchangeProducts: AllowedClaimExchangeProducts;
  readonly batchIdProductCategory: ClaimProductItemCategory;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly enabledForProductCategory: ClaimProductItemCategory;
  readonly isFileUploadAllowed: Scalars['Boolean']['output'];
  readonly scenarioId: Scalars['Int']['output'];
};

export type ClaimScenarios = {
  readonly __typename?: 'ClaimScenarios';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ClaimScenario>>>;
};

export type ClaimSession = {
  readonly __typename?: 'ClaimSession';
  /** The claim items. */
  readonly claimItems?: Maybe<ClaimItems>;
  /** Claim type */
  readonly claimType: ClaimType;
  /** The customer's delivery selection of supplementary order. */
  readonly delivery?: Maybe<ClaimDelivery>;
  /** The available delivery channels. */
  readonly deliveryChannels?: Maybe<ClaimDeliveryChannels>;
  /** The available delivery methods. */
  readonly deliveryMethods?: Maybe<ClaimDeliveryMethods>;
  /** The customer's note. */
  readonly description?: Maybe<ClaimDescription>;
  /** The order associated with the claim. */
  readonly order?: Maybe<Order>;
  /** Order to claim */
  readonly orderId: Scalars['Long']['output'];
  /** The order items to complaint. */
  readonly orderItems?: Maybe<ClaimOrderItems>;
  /** Claiming session id */
  readonly sessionId: Scalars['ID']['output'];
};


export type ClaimSessionDeliveryChannelsArgs = {
  addressId?: InputMaybe<Scalars['Long']['input']>;
  method?: ClaimDeliveryMethodType;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  top?: Scalars['Int']['input'];
};

export enum ClaimType {
  DistributionIssue = 'DistributionIssue',
  InvoiceIssue = 'InvoiceIssue',
  ReturnExchangeOrMissingProduct = 'ReturnExchangeOrMissingProduct'
}

/** Claiming query root */
export type Claiming = {
  readonly __typename?: 'Claiming';
  /** The claim categories. */
  readonly claimCategories?: Maybe<ClaimCategories>;
  /** The claim session. */
  readonly claimSession?: Maybe<ClaimSession>;
};


/** Claiming query root */
export type ClaimingClaimCategoriesArgs = {
  claimType: ClaimType;
};


/** Claiming query root */
export type ClaimingClaimSessionArgs = {
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

/** Color type */
export enum ColorType {
  Brown = 'Brown',
  Green = 'Green',
  White = 'White'
}

export type CommissionSheetDocument = {
  readonly __typename?: 'CommissionSheetDocument';
  /** Document id */
  readonly documentId?: Maybe<Scalars['String']['output']>;
};

/** The concept resolver. */
export type Concept = {
  readonly __typename?: 'Concept';
  /** About concept */
  readonly about?: Maybe<Scalars['String']['output']>;
  /** Localized ingredients text */
  readonly activeIngredients?: Maybe<Scalars['String']['output']>;
  /** The get concept availability. */
  readonly availability?: Maybe<ConceptAvailability>;
  /** Collection of localized benefits texts */
  readonly benefits?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** The get brand. */
  readonly brand?: Maybe<Brand>;
  /** The get concept categories. */
  readonly categories?: Maybe<ReadonlyArray<Maybe<Category>>>;
  /** Code that identifies the concept and the first product of the concept */
  readonly conceptCode: Scalars['ID']['output'];
  /** Localized description of main product (concept) */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Flavour of market concept data */
  readonly flavour?: Maybe<Scalars['String']['output']>;
  /** The get formatted price. */
  readonly formattedPrice?: Maybe<ConceptFormattedPrice>;
  /** Collection of product codes of the given concept */
  readonly hashCode?: Maybe<Scalars['Int']['output']>;
  /** Localized how to use text */
  readonly howToUse?: Maybe<Scalars['String']['output']>;
  /** Collection of how to use sections data (Novage+). */
  readonly howToUseSections?: Maybe<ReadonlyArray<Maybe<ConceptHowToUseSection>>>;
  /** Image url. Taken from the first (random) product. */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Localized info text */
  readonly info?: Maybe<Scalars['String']['output']>;
  /** Comma separated list of ingredients */
  readonly ingredients?: Maybe<Scalars['String']['output']>;
  /** Flag indicating whether the concept is discontinued. True - all the products from the concept are discontinued. False - at least one of the product from the concept is not discontinued */
  readonly isDiscontinued?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the concept contains more than one product */
  readonly isMultiProduct?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the concept is a outlet. True - outlet concept. False - concept is not a outlet. */
  readonly isOutlet?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the concept is a sample. True - sample concept. False - concept is not a sample */
  readonly isSample?: Maybe<Scalars['Boolean']['output']>;
  /** The get labels. */
  readonly labels?: Maybe<ReadonlyArray<Maybe<ProductLabel>>>;
  /** Localized name of main product (concept) */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Global product name */
  readonly originalName?: Maybe<Scalars['String']['output']>;
  /** The get concept permission. */
  readonly permission?: Maybe<ConceptPermission>;
  /** The get price. */
  readonly price?: Maybe<ConceptPrice>;
  /** The get primary product. */
  readonly primaryProduct?: Maybe<Product>;
  readonly productCode?: Maybe<Scalars['String']['output']>;
  readonly productCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Use Product Name instead. */
  readonly productName?: Maybe<Scalars['String']['output']>;
  /** Localized product story. */
  readonly productStory?: Maybe<Scalars['String']['output']>;
  /** The get products. */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Average Rating */
  readonly ratingAverage?: Maybe<Scalars['Float']['output']>;
  /** Count of Ratings */
  readonly ratingCount?: Maybe<Scalars['Int']['output']>;
  /** Count of Ratings with review text */
  readonly ratingsWithReviewCount?: Maybe<Scalars['Int']['output']>;
  /** Flag indicating if concept should be shown on site */
  readonly showOnSite?: Maybe<Scalars['Boolean']['output']>;
  /** The get sub brand. */
  readonly subBrand?: Maybe<Brand>;
  /** Thumbnail image url */
  readonly thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
};

export type ConceptAvailability = {
  readonly __typename?: 'ConceptAvailability';
  /** When item is out of stock, and can be backordered */
  readonly isBackorder?: Maybe<Scalars['Boolean']['output']>;
  /** Is bundle */
  readonly isBundle?: Maybe<Scalars['Boolean']['output']>;
  /** Introduction date of product is close */
  readonly isComingSoon?: Maybe<Scalars['Boolean']['output']>;
  /** Check if it is deleted */
  readonly isDeleted?: Maybe<Scalars['Boolean']['output']>;
  /** Specified by number of days (sitecore) before disco date */
  readonly isLastChance?: Maybe<Scalars['Boolean']['output']>;
  /** Current ATP is lower than 24hr sales history */
  readonly isLowStock?: Maybe<Scalars['Boolean']['output']>;
  /** Number of days (sitecore) after intro date */
  readonly isNew?: Maybe<Scalars['Boolean']['output']>;
  /** Concept is discontinued */
  readonly isNoLongerForSale?: Maybe<Scalars['Boolean']['output']>;
  /** Product cant be sold, price = 0, or is deleted */
  readonly isNotSoldOnline?: Maybe<Scalars['Boolean']['output']>;
  /** Is not standard item, catalogue offer for specific period */
  readonly isOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Out of stock */
  readonly isOutOfStock?: Maybe<Scalars['Boolean']['output']>;
  /** Is sales limited */
  readonly isSalesLimited?: Maybe<Scalars['Boolean']['output']>;
  readonly isSoldOut?: Maybe<Scalars['Boolean']['output']>;
  /** Product is Super Deal */
  readonly isSuperDeal?: Maybe<Scalars['Boolean']['output']>;
};

export type ConceptFormattedPrice = {
  readonly __typename?: 'ConceptFormattedPrice';
  /** Timestamp of when price was calculated */
  readonly dateCalculated?: Maybe<Scalars['DateTime']['output']>;
  /** Object containing concept's prices */
  readonly price?: Maybe<FormattedPrice>;
};

export type ConceptHowToUseSection = {
  readonly __typename?: 'ConceptHowToUseSection';
  /** Bullet List */
  readonly bulletList?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Icon URL */
  readonly iconUrl?: Maybe<Scalars['String']['output']>;
  /** Image URL */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ConceptPermission = {
  readonly __typename?: 'ConceptPermission';
  /** Concept can be displayed on web site or any other mobile application. Product display might not be available if the flag is set to false. */
  readonly canDisplay?: Maybe<Scalars['Boolean']['output']>;
};

export type ConceptPrice = {
  readonly __typename?: 'ConceptPrice';
  /** Currency symbol, for example: "€", "$" */
  readonly currency?: Maybe<Scalars['String']['output']>;
  /** Date calculated */
  readonly dateCalculated?: Maybe<Scalars['DateTime']['output']>;
  /** Prices */
  readonly price?: Maybe<Price>;
};

export type ConsultantSetting = {
  readonly __typename?: 'ConsultantSetting';
  /** Format of setting */
  readonly format?: Maybe<SettingsFormatType>;
  /** Content of setting */
  readonly setting?: Maybe<Scalars['String']['output']>;
  /** Timestamp */
  readonly timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export type Coordinates = {
  readonly __typename?: 'Coordinates';
  /** North–south position of a point on the Earth's surface */
  readonly latitude?: Maybe<Scalars['Float']['output']>;
  /** East–west position of a point on the Earth's surface */
  readonly longitude?: Maybe<Scalars['Float']['output']>;
};

export type CountryMetadataItem = {
  readonly __typename?: 'CountryMetadataItem';
  /** Available options */
  readonly availableOptions?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Order of property in which should be preferably displayed (e.g. field on edit form) */
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  /** Is item available */
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Is item editable */
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Item validation */
  readonly validation?: Maybe<AddressMetadataValidation>;
};

export enum CurrentOrderStatus {
  Blocked = 'Blocked',
  CancelRequested = 'CancelRequested',
  Canceled = 'Canceled',
  CreditNote = 'CreditNote',
  Paid = 'Paid',
  PaidPartially = 'PaidPartially',
  Pending = 'Pending',
  Unknown = 'Unknown',
  Unpaid = 'Unpaid',
  Waiting = 'Waiting',
  WaitingForApproval = 'WaitingForApproval'
}

export type Customer = {
  readonly __typename?: 'Customer';
  /** The get customer address metadata. */
  readonly addressMetadata?: Maybe<AddressMetadata>;
  /** The get customer addresses. */
  readonly addresses?: Maybe<ReadonlyArray<Maybe<Address>>>;
  /** Alias */
  readonly alias?: Maybe<Scalars['String']['output']>;
  /** The get customer approval. */
  readonly approval?: Maybe<Approval>;
  /** The get customer bank accounts. */
  readonly bankAccounts?: Maybe<ReadonlyArray<Maybe<BankAccount>>>;
  /** The get customer beauty store. */
  readonly beautyStore?: Maybe<PersonalBeautyStore>;
  readonly birthDate?: Maybe<Scalars['Date']['output']>;
  /** Career title */
  readonly careerTitle?: Maybe<Scalars['String']['output']>;
  /** Consultant number */
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  /** Customer ID */
  readonly customerId: Scalars['ID']['output'];
  /** Customer type */
  readonly customerType?: Maybe<CustomerType>;
  /** The get customer direct sponsor. */
  readonly directSponsor?: Maybe<Sponsor>;
  /** Display name */
  readonly displayName?: Maybe<Scalars['String']['output']>;
  /** The get customer emails. */
  readonly emails?: Maybe<ReadonlyArray<Maybe<Email>>>;
  /** The get customer ewallet accounts. */
  readonly ewalletAccounts?: Maybe<ReadonlyArray<Maybe<EWalletAccount>>>;
  /** Get financial set. */
  readonly financialSet?: Maybe<FinancialSet>;
  /** Customers first name */
  readonly firstName?: Maybe<Scalars['String']['output']>;
  /** Gender */
  readonly gender?: Maybe<Gender>;
  /** Last name */
  readonly lastName?: Maybe<Scalars['String']['output']>;
  /** Get customer manager summit qualification */
  readonly managerSummitQualification?: Maybe<ManagerSummitQualification>;
  /** Get customer benefits. */
  readonly myBenefitsSummary?: Maybe<MyBenefitsSummary>;
  /** Get customer program plus */
  readonly myProgramPlus?: Maybe<MyProgramPlus>;
  /** Passport number */
  readonly passportNumber?: Maybe<Scalars['String']['output']>;
  /** The get customer phones. */
  readonly phones?: Maybe<ReadonlyArray<Maybe<Phone>>>;
  /** Pin */
  readonly pin?: Maybe<Scalars['String']['output']>;
  /** Preferred language */
  readonly preferredLanguage?: Maybe<Scalars['String']['output']>;
  /** Product lists */
  readonly productLists?: Maybe<ReadonlyArray<Maybe<ProductList>>>;
  /** Get recruitment info. */
  readonly recruitmentInfo?: Maybe<RecruitmentInfo>;
  /** Get customer's recurring payment cards. */
  readonly recurringPaymentCards?: Maybe<ReadonlyArray<Maybe<RecurringPaymentCard>>>;
  /** The get customer settings. */
  readonly settings?: Maybe<ConsultantSetting>;
  /** Get subscription profile. */
  readonly subscriptionProfile?: Maybe<SubscriptionProfile>;
  /** The get customer tax classification. */
  readonly taxClassification?: Maybe<TaxClassification>;
  /** Tax code */
  readonly taxCode?: Maybe<Scalars['String']['output']>;
  /** Tax code 2 */
  readonly taxCode2?: Maybe<Scalars['String']['output']>;
  /** Customers title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Customers unique id */
  readonly uniqueId?: Maybe<Scalars['String']['output']>;
  /** Live video shows */
  readonly videoShows?: Maybe<ReadonlyArray<Maybe<VideoShow>>>;
  /** The get customer visitor segment. */
  readonly visitorSegment: VisitorSegment;
};


export type CustomerMyBenefitsSummaryArgs = {
  periodId: Scalars['Int']['input'];
};


export type CustomerProductListsArgs = {
  productListType: ProductListType;
};


export type CustomerRecurringPaymentCardsArgs = {
  onlyActive?: Scalars['Boolean']['input'];
};


export type CustomerSettingsArgs = {
  settingId?: InputMaybe<Scalars['String']['input']>;
  settingsClient: SettingsClientType;
};

/** Enum customer category */
export enum CustomerCategory {
  Anonymous = 'Anonymous',
  Consultant = 'Consultant',
  Employee = 'Employee',
  EndConsumer = 'EndConsumer',
  EndCustomer = 'EndCustomer',
  Invitation = 'Invitation',
  Other = 'Other'
}

export type CustomerType = {
  readonly __typename?: 'CustomerType';
  /** Customer category */
  readonly customerCategory?: Maybe<CustomerCategory>;
  /** Customer type id */
  readonly customerTypeId?: Maybe<Scalars['Int']['output']>;
  /** Descriptions */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Is customer vip */
  readonly isVipCustomer?: Maybe<Scalars['Boolean']['output']>;
};

export type CutOffPeriod = {
  readonly __typename?: 'CutOffPeriod';
  readonly endDate: Scalars['Date']['output'];
  readonly isQualified: Scalars['Boolean']['output'];
  readonly periodId: Scalars['Int']['output'];
  readonly startDate: Scalars['Date']['output'];
};

export type DeliveryAddress = {
  readonly __typename?: 'DeliveryAddress';
  readonly area?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly delivery?: Maybe<Scalars['String']['output']>;
  readonly district?: Maybe<Scalars['String']['output']>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly locality?: Maybe<Scalars['String']['output']>;
  readonly province?: Maybe<Scalars['String']['output']>;
  readonly region?: Maybe<Scalars['String']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly zipcode?: Maybe<Scalars['String']['output']>;
};

export type DeliveryChannel = {
  readonly __typename?: 'DeliveryChannel';
  /** The get delivery channel addresses. */
  readonly address?: Maybe<SharedAddress>;
  /** The get delivery channel coordinates. */
  readonly coordinates?: Maybe<Coordinates>;
  readonly deliveryChannelId: Scalars['ID']['output'];
  readonly deliveryMethod: DeliveryMethodType;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Courier logo */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Cash-on-delivery is allowed */
  readonly isCodAllowed: Scalars['Boolean']['output'];
  /** Luxury (branded) delivery channel - special usage for SPOs */
  readonly isLuxury: Scalars['Boolean']['output'];
  /** Identification of the channel in Orisales (well known identification) */
  readonly orisalesId: Scalars['Int']['output'];
  /** Phone contact to the delivery channel */
  readonly telephoneNumber?: Maybe<Scalars['String']['output']>;
  /** Working hours description (e.g. Mo-Fr: 10:00 - 16:00) */
  readonly workingHours?: Maybe<Scalars['String']['output']>;
};

export type DeliveryFee = {
  readonly __typename?: 'DeliveryFee';
  /** Formatted Max fee amount */
  readonly maxFeeText?: Maybe<Scalars['String']['output']>;
  /** Formatted Min fee amount */
  readonly minFeeText?: Maybe<Scalars['String']['output']>;
};

export enum DeliveryMethodType {
  DepartmentStore = 'DepartmentStore',
  Home = 'Home',
  Lpo = 'Lpo',
  NearestPostOffice = 'NearestPostOffice',
  NextOrder = 'NextOrder',
  ParcelLocker = 'ParcelLocker',
  PickupPoint = 'PickupPoint',
  PostOffice = 'PostOffice',
  Spo = 'Spo'
}

export type DeliveryOption = {
  readonly __typename?: 'DeliveryOption';
  /** The get delivery address details. */
  readonly deliveryAddress?: Maybe<DeliveryAddress>;
  /** Home delivery address */
  readonly deliveryAddressId?: Maybe<Scalars['Long']['output']>;
  /** The get delivery channel details. */
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  readonly deliveryChannelId?: Maybe<Scalars['Long']['output']>;
  /** Owner of the delivery address (brand partner or friend) */
  readonly deliveryCustomerId?: Maybe<Scalars['Long']['output']>;
  readonly estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery channel can be selected */
  readonly isAllowed: Scalars['Boolean']['output'];
  /** Shipping joker can replace the fee */
  readonly isShippingJoker: Scalars['Boolean']['output'];
  /** Shipping fee before joker is applied */
  readonly originalShippingFee?: Maybe<Scalars['Decimal']['output']>;
  readonly shippingFee: Scalars['Decimal']['output'];
};

export type DeliveryOptions = {
  readonly __typename?: 'DeliveryOptions';
  readonly collection?: Maybe<ReadonlyArray<Maybe<DeliveryOption>>>;
  readonly totalRecords: Scalars['Int']['output'];
};

export enum DeliverySearchFilter {
  AllAddresses = 'AllAddresses',
  DepartmentStore = 'DepartmentStore',
  History = 'History',
  Lpo = 'Lpo',
  MyAddresses = 'MyAddresses',
  MyFriends = 'MyFriends',
  MyHistoryAddresses = 'MyHistoryAddresses',
  MyHistoryFriends = 'MyHistoryFriends',
  MySpo = 'MySpo',
  PickUp = 'PickUp',
  PostOffice = 'PostOffice',
  PremiumSpo = 'PremiumSpo',
  Spo = 'Spo'
}

export type DeliverySelectionSummary = {
  readonly __typename?: 'DeliverySelectionSummary';
  /** Selected delivery address */
  readonly addressId?: Maybe<Scalars['Long']['output']>;
  /** The get selected delivery channel and addresses. */
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  /** Selected delivery channel */
  readonly deliveryChannelId: Scalars['Long']['output'];
  /** Customer's delivery note */
  readonly deliveryComment?: Maybe<Scalars['String']['output']>;
  readonly estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery channel and address is operable */
  readonly isAllowed: Scalars['Boolean']['output'];
  /** Default delivery channel (pre-selected by system) */
  readonly isDefault: Scalars['Boolean']['output'];
  /** Delivery channel from the last order */
  readonly isLastUsed: Scalars['Boolean']['output'];
  /** Shipping joker can replace the fee */
  readonly isShippingJoker: Scalars['Boolean']['output'];
  /** Shipping fee before joker is applied */
  readonly originalShippingFee?: Maybe<Scalars['Decimal']['output']>;
  /** Not available products quantity */
  readonly outOfStockQuantity: Scalars['Int']['output'];
  readonly shippingFee: Scalars['Decimal']['output'];
};

export type DigitalPromotions = {
  readonly __typename?: 'DigitalPromotions';
  /** Best offers banners */
  readonly bestOffersBanners?: Maybe<ReadonlyArray<Maybe<BestOffersBanner>>>;
  /** Best offers products */
  readonly bestOffersProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Campaign banners */
  readonly campaignBanners?: Maybe<ReadonlyArray<Maybe<CampaignBanner>>>;
  /** Campaign products */
  readonly campaignProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Category banners */
  readonly categoryBanners?: Maybe<ReadonlyArray<Maybe<CategoryBanner>>>;
  /** Category products */
  readonly categoryProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly channel: ChannelType;
  /** Hero banners */
  readonly heroBanners?: Maybe<ReadonlyArray<Maybe<HeroBanner>>>;
  /** Hero products */
  readonly heroProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly validityDate?: Maybe<Scalars['DateTime']['output']>;
  /** Whats new banners */
  readonly whatsNewBanners?: Maybe<ReadonlyArray<Maybe<WhatsNewBanner>>>;
  /** WhatsNew products */
  readonly whatsNewProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

/** Discount applied to reduce price of the order */
export type Discount = {
  readonly __typename?: 'Discount';
  /** Applied discount amount */
  readonly amount: Scalars['Decimal']['output'];
  /** Applied discount % (10.0 in case 10%) */
  readonly percent: Scalars['Decimal']['output'];
  readonly type: DiscountType;
};

export enum DiscountType {
  Direct = 'Direct',
  Employee = 'Employee',
  Extra = 'Extra',
  GiftCard = 'GiftCard',
  Immediate = 'Immediate',
  Invitation = 'Invitation',
  Merchandising = 'Merchandising',
  PromoCode = 'PromoCode',
  Staggered = 'Staggered',
  Voucher = 'Voucher',
  Welcome = 'Welcome'
}

export type Discounts = {
  readonly __typename?: 'Discounts';
  readonly collection?: Maybe<ReadonlyArray<Maybe<Discount>>>;
};

export type DocumentInfo = {
  readonly __typename?: 'DocumentInfo';
  /** Document id */
  readonly documentId?: Maybe<Scalars['Int']['output']>;
  /** Document file name */
  readonly fileName?: Maybe<Scalars['String']['output']>;
  /** Document file size. */
  readonly fileSize?: Maybe<Scalars['Int']['output']>;
};

export type EWalletAccount = {
  readonly __typename?: 'EWalletAccount';
  /** eWallet account name */
  readonly accountName?: Maybe<Scalars['String']['output']>;
  /** eWallet account ID */
  readonly eWalletAccountId: Scalars['ID']['output'];
  /** eWallet account owner */
  readonly owner?: Maybe<Scalars['String']['output']>;
  /** eWallet type */
  readonly type?: Maybe<EWalletType>;
};

/** eWallet type */
export enum EWalletType {
  Revolut = 'Revolut'
}

/** Editorial banner */
export type EditorialBanner = {
  readonly __typename?: 'EditorialBanner';
  /** Banner Id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Banner layout */
  readonly layout: EditorialBannerLayoutEnum;
  /** Banner modules */
  readonly modules?: Maybe<ReadonlyArray<Maybe<EditorialBannerModule>>>;
};

/** Editorial banner color */
export type EditorialBannerColor = {
  readonly __typename?: 'EditorialBannerColor';
  /** Color type */
  readonly type: ColorType;
};

/** Editorial banner content */
export type EditorialBannerContent = {
  readonly __typename?: 'EditorialBannerContent';
  /** Caption */
  readonly caption?: Maybe<Scalars['String']['output']>;
  /** Horizontal content alignment. */
  readonly horizontalAlignment: HorizontalContentAlignment;
  /** Make content collapsable and show ReadMore option */
  readonly showReadMoreOption: Scalars['Boolean']['output'];
  /** Banner text collection */
  readonly textCollection?: Maybe<ReadonlyArray<Maybe<EditorialBannerText>>>;
  /** Banner title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /**
   * Banner title color
   * @deprecated Use TitleStorybookColor instead
   */
  readonly titleColor: EditorialBannerTitleColorEnum;
  /** Banner title size */
  readonly titleSize: EditorialBannerTitleSizeEnum;
  /** Banner title color in storybook format. */
  readonly titleStorybookColor: StorybookColor;
  /** Content vertical alignment */
  readonly verticalAlignment: VerticalContentAlignment;
};

/** Banner background image */
export type EditorialBannerImage = {
  readonly __typename?: 'EditorialBannerImage';
  /** Image url */
  readonly url?: Maybe<Scalars['String']['output']>;
  /** Image for mobile devices */
  readonly urlMobile?: Maybe<Scalars['String']['output']>;
};

/** Editorial banner layout enumeration */
export enum EditorialBannerLayoutEnum {
  /** 50/50 banner */
  FiftyBanner = 'FiftyBanner',
  /** Banner with four media */
  FourMedia = 'FourMedia',
  /** Banner with headline */
  Headline = 'Headline',
  /** Banner with one media overlayed with text at the bottom */
  OneMediaCenterBottomText = 'OneMediaCenterBottomText',
  /** Banner with one media overlayed with text on the left */
  OneMediaOverlayLeftText = 'OneMediaOverlayLeftText',
  /** Banner with one media overlayed with text on the right */
  OneMediaOverlayRightText = 'OneMediaOverlayRightText',
  /** Banner with one media part and paragraph text */
  OneMediaParagraphText = 'OneMediaParagraphText',
  /** PLP banner with single media */
  OneMediaProductPromo = 'OneMediaProductPromo',
  /** Banner with paragraph text */
  ParagraphText = 'ParagraphText',
  /** Banner with quote */
  Quote = 'Quote',
  /** Banner with three media and right bottom text */
  ThreeMediaRightBottomText = 'ThreeMediaRightBottomText',
  /** Banner with two media parts */
  TwoMedia = 'TwoMedia',
  /** Banner with two media overlayed with text on the top */
  TwoMediaRightTopText = 'TwoMediaRightTopText',
  /** Banner with vertical video */
  VerticalVideo = 'VerticalVideo',
  /** Banner with video */
  Video = 'Video'
}

/** Banner modules */
export type EditorialBannerModule = {
  readonly __typename?: 'EditorialBannerModule';
  /**
   * Banner background color
   * @deprecated Use BackgroundStorybookColor instead
   */
  readonly backgroundColor?: Maybe<EditorialBannerColor>;
  /** Banner background image */
  readonly backgroundImage?: Maybe<EditorialBannerImage>;
  /** Banner background color in storybook format */
  readonly backgroundStorybookColor: StorybookColor;
  /** Banner background type */
  readonly backgroundType: BackgroundType;
  /** Banner background video */
  readonly backgroundVideo?: Maybe<Video>;
  /** Banner content */
  readonly content?: Maybe<EditorialBannerContent>;
  /** Banner link */
  readonly link?: Maybe<Link>;
};

/** Text wrapper */
export type EditorialBannerText = {
  readonly __typename?: 'EditorialBannerText';
  /** Text content */
  readonly text?: Maybe<Scalars['String']['output']>;
  /** Links represented in text by placeholders ("{0}", "{1}", ...). */
  readonly textLinks?: Maybe<ReadonlyArray<Maybe<Link>>>;
};

/** Editorial banner title color enumeration */
export enum EditorialBannerTitleColorEnum {
  /** The black */
  Black = 'Black',
  /** The dark grey */
  DarkGrey = 'DarkGrey',
  /** The white */
  White = 'White'
}

/** Editorial banner title size enumeration */
export enum EditorialBannerTitleSizeEnum {
  H5 = 'H5',
  H6 = 'H6'
}

export type EditorialBenefit = {
  readonly __typename?: 'EditorialBenefit';
  /** Benefit caption / title */
  readonly caption?: Maybe<Scalars['String']['output']>;
  /** Content to be displayed */
  readonly content?: Maybe<ReadonlyArray<Maybe<EditorialBannerText>>>;
  /** Icon to be displayed */
  readonly icon: EditorialBenefitIcon;
};

export enum EditorialBenefitIcon {
  BubbleHeart = 'BubbleHeart',
  Cashback = 'Cashback',
  Check = 'Check',
  Conference = 'Conference',
  DiscountTag = 'DiscountTag',
  Dollar = 'Dollar',
  Gift = 'Gift',
  Send = 'Send',
  Share = 'Share',
  Truck = 'Truck',
  Wallet = 'Wallet',
  WelcomeKit = 'WelcomeKit'
}

export type EditorialBenefits = {
  readonly __typename?: 'EditorialBenefits';
  /** Collection of benefit items */
  readonly benefits?: Maybe<ReadonlyArray<Maybe<EditorialBenefit>>>;
  /** Content type */
  readonly editorialContentType: EditorialContentType;
  /** Sitecore item id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Benefit title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type EditorialCarousel = {
  readonly __typename?: 'EditorialCarousel';
  /** Collection of editorial pages shown in carousel */
  readonly editorials?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  /** Carousel ID */
  readonly id: Scalars['ID']['output'];
  /** Products */
  readonly product?: Maybe<Product>;
};

/** Editorial channel type */
export enum EditorialChannelType {
  Mobile = 'Mobile',
  Web = 'Web'
}

export enum EditorialContentType {
  Banner = 'Banner',
  Benefits = 'Benefits',
  EditorialBannerCarousel = 'EditorialBannerCarousel',
  EditorialCarousel = 'EditorialCarousel',
  FiftyBanner = 'FiftyBanner',
  FrequentlyAskedQuestions = 'FrequentlyAskedQuestions',
  Header = 'Header',
  LinkList = 'LinkList',
  MixedPlpContent = 'MixedPlpContent',
  Olapic = 'Olapic',
  ProductBoxCarousel = 'ProductBoxCarousel',
  TestimonialCarousel = 'TestimonialCarousel'
}

export type EditorialFaqItem = {
  readonly __typename?: 'EditorialFaqItem';
  /** The content of the FAQ item. */
  readonly content: ReadonlyArray<EditorialBannerText>;
  /** The title of the FAQ item. */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type EditorialFiftyBanner = {
  readonly __typename?: 'EditorialFiftyBanner';
  /** Image details */
  readonly backgroundImage?: Maybe<EditorialBannerImage>;
  /** background color */
  readonly backgroundType: BackgroundType;
  /** Video details */
  readonly backgroundVideo?: Maybe<Video>;
  /** Content position left/right */
  readonly contentAlignment: HorizontalContentAlignment;
  /** Content type */
  readonly editorialContentType: EditorialContentType;
  /** Item id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Button link */
  readonly link?: Maybe<Link>;
  /** Collection of Steps */
  readonly steps?: Maybe<ReadonlyArray<Maybe<EditorialFiftyBannerStep>>>;
  /** Storybook color */
  readonly storybookColor: StorybookColor;
  /** Fifty banner title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type EditorialFiftyBannerStep = {
  readonly __typename?: 'EditorialFiftyBannerStep';
  /** The caption of banner step. */
  readonly caption?: Maybe<Scalars['String']['output']>;
  /** The content of the banner step. */
  readonly content?: Maybe<ReadonlyArray<Maybe<EditorialBannerText>>>;
};

export type EditorialFrequentlyAskedQuestions = {
  readonly __typename?: 'EditorialFrequentlyAskedQuestions';
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Question collection */
  readonly questions: ReadonlyArray<EditorialFaqItem>;
  /** FAQ main title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

/** Editorial header */
export type EditorialHeader = {
  readonly __typename?: 'EditorialHeader';
  /** Name of author */
  readonly authorName?: Maybe<Scalars['String']['output']>;
  /** Background color */
  readonly backgroundStorybookColor: StorybookColor;
  /** Whether the headline is longer than usual */
  readonly extraLongHeadline?: Maybe<Scalars['Boolean']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Headline text color */
  readonly headlineStorybookColor: StorybookColor;
  /** Header Id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Image url */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Indicating if sharing is enabled */
  readonly isSharingEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Links */
  readonly links?: Maybe<ReadonlyArray<Maybe<Link>>>;
  /** Name of photographer */
  readonly photographerName?: Maybe<Scalars['String']['output']>;
  /** Published date */
  readonly publishedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type EditorialLandingPage = {
  readonly __typename?: 'EditorialLandingPage';
  /** Background color */
  readonly backgroundStorybookColor?: Maybe<StorybookColor>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Editorial collection */
  readonly editorialItems?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  /** Headline text color */
  readonly headlineStorybookColor?: Maybe<StorybookColor>;
  /** Image Url */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Page Id */
  readonly landingPageId: Scalars['ID']['output'];
  /** Link to the landing page */
  readonly link?: Maybe<Link>;
  /** Theme collection */
  readonly themeItems?: Maybe<ReadonlyArray<Maybe<EditorialLandingPage>>>;
  /** Theme Tag */
  readonly themeTag?: Maybe<Scalars['String']['output']>;
  /** Thumbnail Url */
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};


export type EditorialLandingPageEditorialItemsArgs = {
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};

export type EditorialLinkList = {
  readonly __typename?: 'EditorialLinkList';
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Collection of link items */
  readonly linkItems: ReadonlyArray<EditorialLinkListItem>;
  /** Title of the link list */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type EditorialLinkListItem = {
  readonly __typename?: 'EditorialLinkListItem';
  /** Whether the link item is downloadable */
  readonly downloadable: Scalars['Boolean']['output'];
  /** Link of the link item */
  readonly link?: Maybe<Link>;
  /** Title of the link item */
  readonly title?: Maybe<Scalars['String']['output']>;
};

/** Editorial Olapic */
export type EditorialOlapic = {
  readonly __typename?: 'EditorialOlapic';
  /** Category Id */
  readonly categoryId?: Maybe<Scalars['String']['output']>;
  /** Olapic Id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** The get product. */
  readonly product?: Maybe<Product>;
  /** Olapic type */
  readonly type?: Maybe<OlapicTypeEnum>;
};

export type EditorialPage = {
  readonly __typename?: 'EditorialPage';
  readonly contentItems: ReadonlyArray<EditorialPageContent>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Parent landing page */
  readonly landingPage?: Maybe<EditorialLandingPage>;
  /** Link to the editorial page */
  readonly link: Link;
  /** Page Id */
  readonly pageId: Scalars['ID']['output'];
  /** Theme tag */
  readonly themeTag?: Maybe<Scalars['String']['output']>;
  /** Thumbnail URL */
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type EditorialPageContent = EditorialBanner | EditorialBenefits | EditorialCarousel | EditorialFiftyBanner | EditorialFrequentlyAskedQuestions | EditorialHeader | EditorialLinkList | EditorialOlapic | EditorialPage | EditorialPlpRows | EditorialProductBoxCarousel | EditorialTestimonialCarousel;

/** Editorial PLP Promo Banner */
export type EditorialPlpPromoBanner = {
  readonly __typename?: 'EditorialPlpPromoBanner';
  /** Banner content */
  readonly banner?: Maybe<EditorialBanner>;
  /** Banner size */
  readonly size: EditorialPlpPromoBannerSize;
};

/** Editorial PLP banner size */
export enum EditorialPlpPromoBannerSize {
  Size1 = 'Size1',
  Size2 = 'Size2'
}

/** Rows composing product listing page */
export type EditorialPlpRows = {
  readonly __typename?: 'EditorialPlpRows';
  readonly content: EditorialPlpRowsContent;
  readonly editorialContentType: EditorialContentType;
  /** Product listing pade id */
  readonly id: Scalars['String']['output'];
};


/** Rows composing product listing page */
export type EditorialPlpRowsContentArgs = {
  facetFiltering?: InputMaybe<ReadonlyArray<EditorialPlpRowsFacetFilterInput>>;
  orderBy?: PlpOrderBy;
  skip?: Scalars['Int']['input'];
  top: Scalars['Int']['input'];
};

export type EditorialPlpRowsContent = {
  readonly __typename?: 'EditorialPlpRowsContent';
  /** Facet definitions */
  readonly facets: ReadonlyArray<PlpFacet>;
  /** Definition of available product ordering options */
  readonly orderByOptions: ReadonlyArray<PlpOrderingDefinition>;
  /** Page content organized into rows */
  readonly rows: ReadonlyArray<PlpRow>;
  /** Total count of banners */
  readonly totalBannersCount: Scalars['Int']['output'];
  /** Total count of products */
  readonly totalProductsCount: Scalars['Int']['output'];
};

export type EditorialPlpRowsFacetFilterInput = {
  readonly keys: ReadonlyArray<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
};

/** Editorial Product Box Carousel */
export type EditorialProductBoxCarousel = {
  readonly __typename?: 'EditorialProductBoxCarousel';
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['ID']['output']>;
  /** Popular product categories */
  readonly popularProductCategories?: Maybe<ReadonlyArray<Maybe<PopularProductCategory>>>;
  /** The get products. */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

/** Single testimonial displayed on editorial */
export type EditorialTestimonial = {
  readonly __typename?: 'EditorialTestimonial';
  /** Testimonial author */
  readonly author?: Maybe<Scalars['String']['output']>;
  /** Testimonial image */
  readonly image?: Maybe<EditorialBannerImage>;
  /** Show star rating on testimonial */
  readonly showStarRating: Scalars['Boolean']['output'];
  /** Testimonial quote lines */
  readonly textCollection?: Maybe<ReadonlyArray<Maybe<EditorialBannerText>>>;
  /** Testimonial title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

/** Carousel with testimonials to be displayed on editorial */
export type EditorialTestimonialCarousel = {
  readonly __typename?: 'EditorialTestimonialCarousel';
  /** Testimonials collection */
  readonly collection: ReadonlyArray<EditorialTestimonial>;
  /** Carousel Id */
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type Email = {
  readonly __typename?: 'Email';
  /** Email address */
  readonly emailAddress?: Maybe<Scalars['String']['output']>;
  /** Is address pending */
  readonly isPending?: Maybe<Scalars['Boolean']['output']>;
};

export type ErrorDialogNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'ErrorDialogNewsletterSubsciptionConfiguration';
  /** Button */
  readonly buttonText?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ExchangedClaimItem = {
  readonly __typename?: 'ExchangedClaimItem';
  /** Production batch identification */
  readonly batchId?: Maybe<Scalars['String']['output']>;
  /** Order item to claim */
  readonly componentNumber?: Maybe<Scalars['Int']['output']>;
  /** Identity of the products should not be presented (surprise gift) */
  readonly isHidden: Scalars['Boolean']['output'];
  /** Returned product */
  readonly itemCode?: Maybe<Scalars['String']['output']>;
  /** Order item to claim */
  readonly orderItemId: Scalars['Long']['output'];
  /** The get product from the claim. */
  readonly product?: Maybe<Product>;
  /** Claimed quantity */
  readonly quantity: Scalars['Int']['output'];
  /** Claim reason */
  readonly reason?: Maybe<ClaimReasonType>;
  /** Products to be ordered instead of returned one */
  readonly replacements?: Maybe<ClaimReplacementItems>;
  /** Claim scenario */
  readonly scenarioId: Scalars['Int']['output'];
  /** Returned BP */
  readonly totalBp: Scalars['Decimal']['output'];
  /** Returned price */
  readonly totalPrice: Scalars['Decimal']['output'];
};

export type ExternalCampaign = {
  readonly __typename?: 'ExternalCampaign';
  /** Campaign */
  readonly campaignId?: Maybe<Scalars['String']['output']>;
  /** Campaign Url */
  readonly campaignUrl?: Maybe<Scalars['String']['output']>;
};

export type FeedbackBenefitBanner = {
  readonly __typename?: 'FeedbackBenefitBanner';
  /** Banner message */
  readonly message?: Maybe<Scalars['String']['output']>;
  /** Link to read more information on benefit */
  readonly readMoreLink?: Maybe<Link>;
  /** Link to register new customer */
  readonly registerLink?: Maybe<Link>;
  /** Link to sign in existing customer */
  readonly signInLink?: Maybe<Link>;
  /** Banner title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Welcome message */
  readonly welcomeMessage?: Maybe<Scalars['String']['output']>;
};

/** Feedback Benefit Banner type */
export enum FeedbackBenefitBannerType {
  Cashback = 'Cashback',
  ShareAndEarn = 'ShareAndEarn'
}

export type FinancialSet = {
  readonly __typename?: 'FinancialSet';
  /**
   * Decimal places, how to round BVs
   * 0: round to integer;
   * &gt; 0: round to n decimal places;
   * &lt; 0: round to 10, 100, 1000 etc.
   */
  readonly bvRounding: Scalars['Int']['output'];
  /** Currency code (eg. EUR, CZK) */
  readonly currencyCode?: Maybe<Scalars['String']['output']>;
  /** Name of the currency (eg. Euro) */
  readonly currencyName?: Maybe<Scalars['String']['output']>;
  /** Financial Set ID */
  readonly financialSetId: Scalars['Int']['output'];
  /**
   * Decimal places, how to round price
   * 0: round to integer;
   * &gt; 0: round to n decimal places;
   * &lt; 0: round to 10, 100, 1000 etc.
   */
  readonly priceRounding: Scalars['Int']['output'];
};

export type Footer = {
  readonly __typename?: 'Footer';
  /** Do not show language switcher pop-up */
  readonly disableLanguageSwitcherPopUp: Scalars['Boolean']['output'];
  /** Footer Language Configuration */
  readonly languageConfiguration?: Maybe<LanguageConfiguration>;
};

export type FooterNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'FooterNewsletterSubsciptionConfiguration';
  /** Enabled */
  readonly enabled?: Maybe<Scalars['Boolean']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type FormattedPrice = {
  readonly __typename?: 'FormattedPrice';
  /** Basic cashback amount */
  readonly basicCashback?: Maybe<Scalars['String']['output']>;
  /** Consultant price from basic catalogue */
  readonly basicCatalogueConsultantPrice?: Maybe<Scalars['String']['output']>;
  /** Price from basic catalogue */
  readonly basicCataloguePrice?: Maybe<Scalars['String']['output']>;
  /** Current consultant price */
  readonly currentConsultantPrice?: Maybe<Scalars['String']['output']>;
  /** Current price */
  readonly currentPrice?: Maybe<Scalars['String']['output']>;
  /** Extra cashback amount */
  readonly extraCashback?: Maybe<Scalars['String']['output']>;
  /** Previous minimum price in last 30 days */
  readonly previousMinOfferPrice?: Maybe<Scalars['String']['output']>;
  /** Price after immediate discount */
  readonly priceAfterImmediateDiscount?: Maybe<Scalars['String']['output']>;
  /** Approximate Share &amp; Earn bonus amount */
  readonly sharingBonus?: Maybe<Scalars['String']['output']>;
};

export type FreeDelivery = {
  readonly __typename?: 'FreeDelivery';
  /** Threshold price text. */
  readonly thresholdPriceText?: Maybe<Scalars['String']['output']>;
};

export type FrontPageImage = {
  readonly __typename?: 'FrontPageImage';
  /** Image's subtitle */
  readonly subTitle?: Maybe<Scalars['String']['output']>;
  /** Image's title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Image's url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type Frontpage = {
  readonly __typename?: 'Frontpage';
  /** Beauty Edit section */
  readonly beautyEdit?: Maybe<BeautyEdit>;
  /** Beauty by Sweden Video */
  readonly beautyVideo?: Maybe<BeautyVideo>;
  /** Benefit banner */
  readonly benefitBanner?: Maybe<BenefitBanner>;
  /** Bestseller products section */
  readonly bestsellerProducts?: Maybe<BestsellerProducts>;
  /** Navigation by categories */
  readonly categoryNavigation?: Maybe<ReadonlyArray<Maybe<Link>>>;
  /** Digital promotions */
  readonly digitalPromotions?: Maybe<DigitalPromotions>;
  /** Iconic products section */
  readonly iconicProducts?: Maybe<IconicProducts>;
  /** Motivation content */
  readonly motivationContent?: Maybe<MotivationContent>;
  /** Notification banner */
  readonly notificationBanner?: Maybe<NotificationBanner>;
  /** Product Standards section */
  readonly productStandards?: Maybe<ProductStandards>;
  /** Top Brands section */
  readonly topBrands?: Maybe<TopBrands>;
  /** VIP Benefits section */
  readonly vipBenefits?: Maybe<VipBenefits>;
};


export type FrontpageDigitalPromotionsArgs = {
  channel: ChannelType;
  validityDate?: InputMaybe<Scalars['Date']['input']>;
};

/** Enum gender */
export enum Gender {
  Man = 'Man',
  Other = 'Other',
  Woman = 'Woman'
}

export type GlobalBonusItemsByLocalPeriod = {
  readonly __typename?: 'GlobalBonusItemsByLocalPeriod';
  readonly globalBonusItems?: Maybe<ReadonlyArray<Maybe<MyBenefitsBonusItemV2>>>;
  readonly localPeriodCode: Scalars['Int']['output'];
};

export type HeroBanner = {
  readonly __typename?: 'HeroBanner';
  /** Background color */
  readonly backgroundColor?: Maybe<Scalars['String']['output']>;
  /** Font color -eg. red, grey */
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  /** Headline of the banner */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image url of the banner */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link type, attributes and Id */
  readonly link?: Maybe<Link>;
  /** Mobile font color -eg. red, grey */
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  /** Priority */
  readonly priority?: Maybe<Scalars['Int']['output']>;
  /** Small Image url of the banner */
  readonly smallImageUrl?: Maybe<Scalars['String']['output']>;
  /** Tagline of the banner */
  readonly tagline?: Maybe<Scalars['String']['output']>;
  /** Banner Validity date from */
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Banner validity date to */
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
  /** Video url */
  readonly videoUrl?: Maybe<Scalars['String']['output']>;
};

export enum HorizontalContentAlignment {
  Left = 'Left',
  Right = 'Right',
  Undefined = 'Undefined'
}

export type IconicProducts = {
  readonly __typename?: 'IconicProducts';
  /** Banner of the iconic products. */
  readonly banner?: Maybe<ProductsPromotionBanner>;
  /** Description of the iconic products. */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Headline of the iconic products. */
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

/** The concept resolver. */
export type Ingredient = {
  readonly __typename?: 'Ingredient';
  /** Summary of benefits */
  readonly benefitSummary?: Maybe<Scalars['String']['output']>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Did you know label */
  readonly didYouKnow?: Maybe<Scalars['String']['output']>;
  /** Url to the image */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Key benefit 1 */
  readonly keyBenefit1?: Maybe<Scalars['String']['output']>;
  /** Key benefit 2 */
  readonly keyBenefit2?: Maybe<Scalars['String']['output']>;
  /** Key benefit 3 */
  readonly keyBenefit3?: Maybe<Scalars['String']['output']>;
  /** Key benefits */
  readonly keyBenefits?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Latin name */
  readonly latinName?: Maybe<Scalars['String']['output']>;
  /** Link to ingredient detail */
  readonly link?: Maybe<Link>;
  /** Total number of products available */
  readonly numberOfProducts: Scalars['Int']['output'];
  /** Products containing the ingredient */
  readonly products: ReadonlyArray<Product>;
  /**
   * The get related articles for ingredient.
   *
   *
   * **Returns:**
   * The result.
   */
  readonly relatedArticles: ReadonlyArray<Article>;
  /** Short name */
  readonly shortName?: Maybe<Scalars['String']['output']>;
};


/** The concept resolver. */
export type IngredientProductsArgs = {
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};


/** The concept resolver. */
export type IngredientRelatedArticlesArgs = {
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};

export type IngredientLibrary = {
  readonly __typename?: 'IngredientLibrary';
  /** Ingredient library inspiration */
  readonly inspiration?: Maybe<IngredientLibraryInspiration>;
};

export type IngredientLibraryInspiration = {
  readonly __typename?: 'IngredientLibraryInspiration';
  /** Ingredient library inspiration text */
  readonly inspirationTexts?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
};

export type InviteFriendProfile = {
  readonly __typename?: 'InviteFriendProfile';
  /** Consultant number */
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  /** Consultant contacts */
  readonly contacts?: Maybe<ReadonlyArray<Maybe<SocialContact>>>;
  /** Consultant profile description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Consultant first name */
  readonly firstName?: Maybe<Scalars['String']['output']>;
  /** Consultant last name */
  readonly lastName?: Maybe<Scalars['String']['output']>;
  /** Consultant prfile photo url */
  readonly photoUrl?: Maybe<Scalars['String']['output']>;
};

export type IsPrimaryMetadataItem = {
  readonly __typename?: 'IsPrimaryMetadataItem';
  /** Order of property in which should be preferably displayed (e.g. field on edit form) */
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  /** Is item available */
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Is item editable */
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
};

export type Language = {
  readonly __typename?: 'Language';
  /** Language code */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** Language name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Language type */
  readonly type?: Maybe<LanguageType>;
};

export type LanguageConfiguration = {
  readonly __typename?: 'LanguageConfiguration';
  /** Country label */
  readonly countryLabel?: Maybe<Scalars['String']['output']>;
  /** Current Country */
  readonly currentCountry?: Maybe<Scalars['String']['output']>;
  /** Language label */
  readonly languageLabel?: Maybe<Scalars['String']['output']>;
  /** Select country label */
  readonly selectCountryLabel?: Maybe<Scalars['String']['output']>;
  /** Sekect country URL */
  readonly selectCountryUrl?: Maybe<Scalars['String']['output']>;
};

/** Enum language type */
export enum LanguageType {
  Alternative = 'Alternative',
  Default = 'Default'
}

export type Link = {
  readonly __typename?: 'Link';
  /** AppLink */
  readonly appLink?: Maybe<AppLink>;
  readonly attributes?: Maybe<ReadonlyArray<Maybe<Attribute>>>;
  readonly data?: Maybe<LinkData>;
  /** Dynamic url for sharing */
  readonly dynamicSharingUrl?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Relative url */
  readonly relativeUrl?: Maybe<Scalars['String']['output']>;
  /** Link's title */
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly type: LinkType;
};


export type LinkDynamicSharingUrlArgs = {
  storeName?: InputMaybe<Scalars['ID']['input']>;
};

export type LinkData = Brand | Catalogue | Category | EditorialPage | ExternalCampaign | NovageSkincareAdvisor | Product | ProductListingPage;

export enum LinkType {
  Brand = 'Brand',
  Campaign = 'Campaign',
  Catalogue = 'Catalogue',
  Category = 'Category',
  CategoryNavigation = 'CategoryNavigation',
  Custom = 'Custom',
  DigitalCatalogueCurrentNavigation = 'DigitalCatalogueCurrentNavigation',
  EditorialArticle = 'EditorialArticle',
  EditorialCampaign = 'EditorialCampaign',
  Frontpage = 'Frontpage',
  Ingredient = 'Ingredient',
  InvitationOffer = 'InvitationOffer',
  Invite = 'Invite',
  NewProducts = 'NewProducts',
  NovageSkincareAdvisor = 'NovageSkincareAdvisor',
  PlPage = 'PlPage',
  Product = 'Product',
  RegistrationPage = 'RegistrationPage',
  SignIn = 'SignIn'
}

export type MakeupTryOnConfiguration = {
  readonly __typename?: 'MakeupTryOnConfiguration';
  /** product Detail */
  readonly productDetail?: Maybe<ProductDetailMakeupTryOnConfiguration>;
};

export type MakeupTryOnProductRelation = {
  readonly __typename?: 'MakeupTryOnProductRelation';
  /** Related Shi Shi Product Id */
  readonly shiShiProductId?: Maybe<Scalars['String']['output']>;
};

export type ManagerSummitQualification = {
  readonly __typename?: 'ManagerSummitQualification';
  readonly cutOffPeriods?: Maybe<ReadonlyArray<Maybe<CutOffPeriod>>>;
  readonly discountRateLevelEvaluation?: Maybe<ReadonlyArray<Maybe<PeriodOrderEvaluationStatus>>>;
  readonly managerSummitStatus: ManagerSummitStatus;
  readonly managerSummitVersion: ManagerSummitVersion;
  readonly qualifiedInPeriods?: Maybe<Scalars['Int']['output']>;
  readonly qualifiedOfPeriods?: Maybe<Scalars['Int']['output']>;
  readonly remainingQualificationPeriodsCount?: Maybe<Scalars['Int']['output']>;
  readonly successfulQualificationPeriodsCount?: Maybe<Scalars['Int']['output']>;
  readonly targetDiscountRate?: Maybe<Scalars['Decimal']['output']>;
  readonly youtubeVideoId?: Maybe<Scalars['String']['output']>;
};

export enum ManagerSummitStatus {
  NotAvailable = 'NotAvailable',
  QualificationInProgress = 'QualificationInProgress',
  QualifiedBeforeCutOff = 'QualifiedBeforeCutOff',
  QualifiedIncludingCutOff = 'QualifiedIncludingCutOff'
}

export enum ManagerSummitVersion {
  Manager = 'Manager',
  NewComer = 'NewComer',
  NotAvailable = 'NotAvailable'
}

export type Manufacturer = {
  readonly __typename?: 'Manufacturer';
  /** Manufacturer address */
  readonly address?: Maybe<ManufacturerAddress>;
  /** Manufacturer Id */
  readonly manufacturerId?: Maybe<Scalars['String']['output']>;
  /** Manufacturer name */
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type ManufacturerAddress = {
  readonly __typename?: 'ManufacturerAddress';
  /** Address 1 */
  readonly address1?: Maybe<Scalars['String']['output']>;
  /** Address 2 */
  readonly address2?: Maybe<Scalars['String']['output']>;
  /** City */
  readonly city?: Maybe<Scalars['String']['output']>;
  /** Country code */
  readonly countryCode?: Maybe<Scalars['String']['output']>;
  /** Country name */
  readonly countryName?: Maybe<Scalars['String']['output']>;
  /** State */
  readonly state?: Maybe<Scalars['String']['output']>;
  /** Zip code */
  readonly zipCode?: Maybe<Scalars['String']['output']>;
};

export type MetadataOperations = {
  readonly __typename?: 'MetadataOperations';
  /** Create */
  readonly create?: Maybe<AddressMetadataOperation>;
  /** Delete */
  readonly delete?: Maybe<AddressMetadataOperation>;
  /** Read */
  readonly read?: Maybe<AddressMetadataOperation>;
  /** Update */
  readonly update?: Maybe<AddressMetadataOperation>;
};

export type MissedClaimItem = {
  readonly __typename?: 'MissedClaimItem';
  /** Order item to claim */
  readonly componentNumber?: Maybe<Scalars['Int']['output']>;
  /** Identity of the products should not be presented (surprise gift) */
  readonly isHidden: Scalars['Boolean']['output'];
  /** Ordered product */
  readonly itemCode?: Maybe<Scalars['String']['output']>;
  /** Order item to claim */
  readonly orderItemId: Scalars['Long']['output'];
  /** The get product from the claim. */
  readonly product?: Maybe<Product>;
  /** Claimed quantity */
  readonly quantity: Scalars['Int']['output'];
  /** Claim reason */
  readonly reason?: Maybe<ClaimReasonType>;
  /** Claim scenario */
  readonly scenarioId: Scalars['Int']['output'];
  /** Ordered BP */
  readonly totalBp: Scalars['Decimal']['output'];
  /** Ordered price */
  readonly totalPrice: Scalars['Decimal']['output'];
  /** Wrong products delivered */
  readonly wrongProducts?: Maybe<ClaimReplacementItems>;
};

export type MixedPlpContent = EditorialPlpPromoBanner | Product;

export type MobileApp = {
  readonly __typename?: 'MobileApp';
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Urls of additional images */
  readonly images?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Links related to the mobile app */
  readonly links?: Maybe<MobileAppLinks>;
  /** Type of the mobile app */
  readonly mobileAppType?: Maybe<MobileAppTypeData>;
  /** Subtitle */
  readonly subtitle?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Url of the main image */
  readonly titleImage?: Maybe<Scalars['String']['output']>;
};

export type MobileAppLinks = {
  readonly __typename?: 'MobileAppLinks';
  /** Link of the mobile app in Apple Store */
  readonly appleStoreLink?: Maybe<Scalars['String']['output']>;
  /** Link of the mobile app in Google Play Store */
  readonly googlePlayStoreLink?: Maybe<Scalars['String']['output']>;
};

export enum MobileAppType {
  None = 'None',
  ToSell = 'ToSell',
  ToShop = 'ToShop'
}

export type MobileAppTypeData = {
  readonly __typename?: 'MobileAppTypeData';
  /** Title of the type */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Type of the mobile app */
  readonly type?: Maybe<MobileAppType>;
};

export type MobileApps = {
  readonly __typename?: 'MobileApps';
  /** Mobile apps */
  readonly mobileAppCollection?: Maybe<ReadonlyArray<Maybe<MobileApp>>>;
  /** Header of mobile apps page */
  readonly pageHeader?: Maybe<MobileAppsPageHeader>;
};

export type MobileAppsPageHeader = {
  readonly __typename?: 'MobileAppsPageHeader';
  /** Apple Store image */
  readonly appleStoreImage?: Maybe<Scalars['String']['output']>;
  /** Google Play image */
  readonly googlePlayImage?: Maybe<Scalars['String']['output']>;
  /** Subtitle */
  readonly subtitle?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type MotivationContent = {
  readonly __typename?: 'MotivationContent';
  /** Section's headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Text displayed in the section */
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly backInStockNotificationSubscription?: Maybe<Scalars['Void']['output']>;
  readonly shareAndEarnPersonalCodeUpdate?: Maybe<MutationResult>;
};


export type MutationBackInStockNotificationSubscriptionArgs = {
  input?: InputMaybe<BackInStockNotificationSubscriptionInput>;
};


export type MutationShareAndEarnPersonalCodeUpdateArgs = {
  input?: InputMaybe<ShareAndEarnUpdatePersonalCodeInput>;
};

export type MutationResult = {
  readonly __typename?: 'MutationResult';
  /** Error message */
  readonly message?: Maybe<Scalars['String']['output']>;
  /** Indicates whether the mutation was successful or not */
  readonly success: Scalars['Boolean']['output'];
};

export type MyBenefitsBonusItem = {
  readonly __typename?: 'MyBenefitsBonusItem';
  readonly amount: Scalars['Decimal']['output'];
  readonly bonusTypeDescription?: Maybe<Scalars['String']['output']>;
  readonly paymentChannel: PaymentChannel;
  readonly paymentDescription?: Maybe<Scalars['String']['output']>;
};

export type MyBenefitsBonusItemV2 = {
  readonly __typename?: 'MyBenefitsBonusItemV2';
  readonly amount: Scalars['Decimal']['output'];
  readonly bonusTypeDescription?: Maybe<Scalars['String']['output']>;
  readonly globalPeriodCode: Scalars['Int']['output'];
  readonly paymentChannel: PaymentChannel;
  readonly paymentDescription?: Maybe<Scalars['String']['output']>;
};

export type MyBenefitsSummary = {
  readonly __typename?: 'MyBenefitsSummary';
  readonly bankTransferAmount: Scalars['Decimal']['output'];
  readonly bankTransferState: BankBonusPaymentTransferState;
  readonly bonusItems?: Maybe<ReadonlyArray<Maybe<MyBenefitsBonusItem>>>;
  readonly bonusesAndCashAwards: Scalars['Decimal']['output'];
  /** Get customer benefits. */
  readonly commissionSheet?: Maybe<CommissionSheetDocument>;
  readonly currencyIsoCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use GlobalBonusItemsByLocalPeriod instead */
  readonly globalBonusItems?: Maybe<ReadonlyArray<Maybe<MyBenefitsBonusItem>>>;
  readonly globalBonusItemsByLocalPeriod?: Maybe<ReadonlyArray<Maybe<GlobalBonusItemsByLocalPeriod>>>;
  readonly immediateDiscounts: Scalars['Decimal']['output'];
  readonly oriflameWallet: Scalars['Decimal']['output'];
  readonly totalBenefits: Scalars['Decimal']['output'];
};

export type MyProgramPlus = {
  readonly __typename?: 'MyProgramPlus';
  readonly oneTimeBonusAmount: Scalars['Decimal']['output'];
  readonly oneTimeBonusEndDate: Scalars['Date']['output'];
  readonly salesBoosterInfo?: Maybe<ReadonlyArray<Maybe<SalesBoosterQualificationInfo>>>;
};

export type Navigation = {
  readonly __typename?: 'Navigation';
  /** Collection of subitems */
  readonly children?: Maybe<ReadonlyArray<Maybe<Navigation>>>;
  /** Description of the item */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Id of the navigation item */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Image of the item */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Type of the navigation link */
  readonly linkType?: Maybe<NavigationLinkType>;
  /** Specifies nodeId in tree structure */
  readonly nodeId?: Maybe<Scalars['String']['output']>;
  /** Title of the navigation item */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Url of the item */
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type NavigationCollection = {
  readonly __typename?: 'NavigationCollection';
  /** Collection of Navigation items */
  readonly children?: Maybe<ReadonlyArray<Maybe<Navigation>>>;
  /** Navigation type */
  readonly navigationType?: Maybe<NavigationType>;
};

/** Possible types of Navigation items */
export enum NavigationLinkType {
  /** All category */
  AllCategory = 'AllCategory',
  /** Beauty Tips section */
  BeautyTips = 'BeautyTips',
  /** Bestseller products page */
  Bestsellers = 'Bestsellers',
  /** Bestseller products in category */
  BestsellersInCategory = 'BestsellersInCategory',
  /** Bottom part of the footer */
  Bottom = 'Bottom',
  /** Bottom part of the footer with copyright info */
  BottomCopyright = 'BottomCopyright',
  /** Links from the bottom part of the footer */
  BottomLinks = 'BottomLinks',
  /** Catalogue page */
  Catalogue = 'Catalogue',
  /** Catalogues page */
  Catalogues = 'Catalogues',
  /** Category */
  Category = 'Category',
  /** Catalogue for the current period */
  DigitalCatalogueCurrent = 'DigitalCatalogueCurrent',
  /** Catalogue for the next period */
  DigitalCatalogueNext = 'DigitalCatalogueNext',
  /** Focus page */
  FocusPage = 'FocusPage',
  /** Column of a footer */
  FooterColumn = 'FooterColumn',
  /** Item placed in footer's column */
  FooterColumnItem = 'FooterColumnItem',
  /** I Don't know yet link */
  IDontKnowYet = 'IDontKnowYet',
  /** Iconic products page */
  Icons = 'Icons',
  /** Iconic products in category */
  IconsInCategory = 'IconsInCategory',
  /** Inspiration section */
  Inspiration = 'Inspiration',
  /** Join rewards */
  JoinRewards = 'JoinRewards',
  /** Key ingredients page */
  KeyIngredients = 'KeyIngredients',
  /** Menu tiles */
  MenuTiles = 'MenuTiles',
  /** New products */
  New = 'New',
  /** No link type was specified for this item */
  None = 'None',
  /** Our applications */
  OurApps = 'OurApps',
  /** Outlet shop */
  OutletShop = 'OutletShop',
  /** Payment method */
  PaymentMethod = 'PaymentMethod',
  /** Available payment methods */
  PaymentMethods = 'PaymentMethods',
  /** Popular brand */
  PopularBrand = 'PopularBrand',
  /** Popular brands */
  PopularBrands = 'PopularBrands',
  /** Popular lookbook */
  PopularLookbook = 'PopularLookbook',
  Qrtr = 'Qrtr',
  /** Rewards shop page */
  RewardsShop = 'RewardsShop',
  /** Sample shop page */
  SampleShop = 'SampleShop',
  /** Segment of subcategory */
  Segment = 'Segment',
  /** Subcategory */
  SubCategory = 'SubCategory',
  /** Support center */
  SupportCenter = 'SupportCenter',
  /** Top navigation */
  TopNavigation = 'TopNavigation',
  /** What's new in the category */
  WhatsNewInCategory = 'WhatsNewInCategory'
}

export type NavigationTag = {
  readonly __typename?: 'NavigationTag';
  /** NavigationTag title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** NavigationTag url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** Possible types of navigations */
export enum NavigationType {
  /** About tiles section content */
  AboutTiles = 'AboutTiles',
  /** Footer section */
  Footer = 'Footer',
  /** Inspiration tiles content */
  InspirationTiles = 'InspirationTiles',
  /** Join section content */
  JoinTiles = 'JoinTiles',
  /** Shop section content */
  ShopSection = 'ShopSection',
  /** Top navigation bar items */
  TopNavigation = 'TopNavigation'
}

export type NewsletterSubsciptionConfiguration = {
  readonly __typename?: 'NewsletterSubsciptionConfiguration';
  /** Error Dialog */
  readonly errorDialog?: Maybe<ErrorDialogNewsletterSubsciptionConfiguration>;
  /** Footer */
  readonly footer?: Maybe<FooterNewsletterSubsciptionConfiguration>;
  /** Popup */
  readonly popup?: Maybe<PopupNewsletterSubsciptionConfiguration>;
  /** Response Messages */
  readonly responseMessages?: Maybe<ReadonlyArray<Maybe<ResponseMessageNewsletterSubsciptionConfiguration>>>;
  /** Submit Dialog */
  readonly submitDialog?: Maybe<SubmitDialogNewsletterSubsciptionConfiguration>;
  /** Subscribed Dialog */
  readonly subscribedDialog?: Maybe<SubscribedDialogNewsletterSubsciptionConfiguration>;
};

export type NotificationBanner = {
  readonly __typename?: 'NotificationBanner';
  /** Link title */
  readonly messageLinkTitle?: Maybe<Scalars['String']['output']>;
  /** Link URL */
  readonly messageLinkUrl?: Maybe<Scalars['String']['output']>;
  /** Text */
  readonly messageText?: Maybe<Scalars['String']['output']>;
  /** Theme */
  readonly messageTheme?: Maybe<NotificationBannerThemeType>;
};

export enum NotificationBannerThemeType {
  Black = 'Black',
  Grey = 'Grey'
}

export type NovageSkincareAdvisor = {
  readonly __typename?: 'NovageSkincareAdvisor';
  readonly environment?: Maybe<Scalars['String']['output']>;
  readonly partnerId?: Maybe<Scalars['String']['output']>;
};

export type OlapicConfiguration = {
  readonly __typename?: 'OlapicConfiguration';
  /** Olapic api key */
  readonly apiKey?: Maybe<Scalars['String']['output']>;
  /** The get category. */
  readonly category?: Maybe<Category>;
  /** Hide caption toggle */
  readonly hideCaption?: Maybe<Scalars['Boolean']['output']>;
  /** olapic id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Olapic instance */
  readonly instance?: Maybe<Scalars['String']['output']>;
  /** Olapic item name */
  readonly itemName?: Maybe<Scalars['String']['output']>;
  /** language code */
  readonly languageCode?: Maybe<Scalars['String']['output']>;
  /** The get product. */
  readonly product?: Maybe<Product>;
  /** Olapic script url */
  readonly scriptUrl?: Maybe<Scalars['String']['output']>;
  /** Olapic widget type */
  readonly type?: Maybe<OlapicTypeEnum>;
};

/** Olapic type enumeration */
export enum OlapicTypeEnum {
  /** Category widget */
  Category = 'Category',
  /** Front page widget */
  FrontPage = 'FrontPage',
  /** Gallery widget */
  Gallery = 'Gallery',
  /** Product detail page widget */
  ProductDetails = 'ProductDetails'
}

export type OperationPrincipals = {
  readonly __typename?: 'OperationPrincipals';
  /** Name of the customer who cancelled the order or 'Customer Service'. Null if the order is not cancelled */
  readonly cancelledBy?: Maybe<Scalars['String']['output']>;
  /** Id of the customer who cancelled the order. Null if the order is not cancelled */
  readonly cancelledById?: Maybe<Scalars['Long']['output']>;
  /** Name of the customer who placed the order or 'Customer Service' */
  readonly placedBy?: Maybe<Scalars['String']['output']>;
  /** Id of the customer who placed the order */
  readonly placedById: Scalars['Long']['output'];
};

export type Order = {
  readonly __typename?: 'Order';
  /** Provide availability of order cancel operation. */
  readonly cancellation?: Maybe<OrderCancel>;
  readonly currentOrderStatus: CurrentOrderStatus;
  /** The customer associated with the order. */
  readonly customer?: Maybe<Customer>;
  readonly debt: Scalars['Decimal']['output'];
  /** The delivery channel of the order. */
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  readonly deliveryChannelId: Scalars['Long']['output'];
  /** The discounts applied to the order. */
  readonly discounts?: Maybe<Discounts>;
  /** The documents linked with the order. */
  readonly documents?: Maybe<OrderDocuments>;
  readonly dueDateOffset?: Maybe<Scalars['DateTime']['output']>;
  readonly earnedCashback: Scalars['Decimal']['output'];
  readonly earnedPoints: Scalars['Decimal']['output'];
  readonly fee: Scalars['Decimal']['output'];
  readonly invoiceNumber?: Maybe<Scalars['String']['output']>;
  /** The order operation principals. */
  readonly operationPrincipals?: Maybe<OperationPrincipals>;
  readonly orderDateOffset: Scalars['DateTime']['output'];
  readonly orderId: Scalars['ID']['output'];
  /** The items of the order. */
  readonly orderItems?: Maybe<OrderItems>;
  readonly orderOrigin: OrderOrigin;
  readonly paymentProviderId: Scalars['Long']['output'];
  /** Returns result of evaluation if the order can be paid and number of pending payments. */
  readonly pendingPaymentsResult?: Maybe<PendingPaymentsResult>;
  readonly periodId?: Maybe<Scalars['Int']['output']>;
  /** The real time order tracking. */
  readonly realTimeOrderTracking?: Maybe<OrderTracking>;
  readonly redeemedPoints: Scalars['Decimal']['output'];
  readonly totalAmount: Scalars['Decimal']['output'];
  readonly totalBp: Scalars['Decimal']['output'];
};

export type OrderCancel = {
  readonly __typename?: 'OrderCancel';
  readonly isAllowed: Scalars['Boolean']['output'];
  readonly status: CanCancelOrderResult;
};

export type OrderDocument = {
  readonly __typename?: 'OrderDocument';
  readonly created: Scalars['DateTime']['output'];
  readonly documentId?: Maybe<Scalars['String']['output']>;
  readonly documentType: OrderDocumentType;
};

export enum OrderDocumentType {
  FiscalReceipt = 'FiscalReceipt',
  InvoicePdf = 'InvoicePdf',
  InvoiceXml = 'InvoiceXml',
  ReturnFiscalReceipt = 'ReturnFiscalReceipt'
}

export type OrderDocuments = {
  readonly __typename?: 'OrderDocuments';
  readonly collection?: Maybe<ReadonlyArray<Maybe<OrderDocument>>>;
};

export enum OrderHistoryType {
  Full = 'Full',
  Last3Periods = 'Last3Periods'
}

export type OrderItem = {
  readonly __typename?: 'OrderItem';
  readonly isHidden: Scalars['Boolean']['output'];
  readonly itemCode?: Maybe<Scalars['String']['output']>;
  readonly orderItemType: OrderItemType;
  readonly originalItemCode?: Maybe<Scalars['String']['output']>;
  readonly price: Scalars['Decimal']['output'];
  /** The get product from the order. */
  readonly product?: Maybe<Product>;
  readonly quantity: Scalars['Int']['output'];
  readonly replacementType?: Maybe<ReplacementType>;
  readonly totalBp: Scalars['Decimal']['output'];
  readonly totalDiscount: Scalars['Decimal']['output'];
  readonly totalPrice: Scalars['Decimal']['output'];
};

export enum OrderItemType {
  BackOrder = 'BackOrder',
  Bundle = 'Bundle',
  Donation = 'Donation',
  EndConsumerOrder = 'EndConsumerOrder',
  ExtraChange = 'ExtraChange',
  ExtraSold = 'ExtraSold',
  ExtraTax = 'ExtraTax',
  Fee = 'Fee',
  FreeSample = 'FreeSample',
  FreeShippingJoker = 'FreeShippingJoker',
  MerchandisingChange = 'MerchandisingChange',
  MerchandisingChangedGift = 'MerchandisingChangedGift',
  MerchandisingDiscount = 'MerchandisingDiscount',
  MerchandisingGift = 'MerchandisingGift',
  NextOrderDelivery = 'NextOrderDelivery',
  Offer = 'Offer',
  PaymentFee = 'PaymentFee',
  PersonalNutrition = 'PersonalNutrition',
  PersonalSubscription = 'PersonalSubscription',
  Product = 'Product',
  RegistrationFee = 'RegistrationFee',
  RenewalFee = 'RenewalFee',
  Replacement = 'Replacement',
  Reservation = 'Reservation',
  Section1 = 'Section1',
  Section2 = 'Section2',
  Section3 = 'Section3',
  Section4 = 'Section4',
  Service = 'Service',
  StarterKit = 'StarterKit',
  Subscription = 'Subscription',
  Target = 'Target',
  Unknown = 'Unknown',
  Voucher = 'Voucher'
}

export type OrderItems = {
  readonly __typename?: 'OrderItems';
  readonly collection?: Maybe<ReadonlyArray<Maybe<OrderItem>>>;
};

export enum OrderOrigin {
  Automatic = 'Automatic',
  Bambuser = 'Bambuser',
  Claim = 'Claim',
  Demo = 'Demo',
  Email = 'Email',
  Immediate = 'Immediate',
  NowApp = 'NowApp',
  OnBehalf = 'OnBehalf',
  Online = 'Online',
  Orisales = 'Orisales',
  Other = 'Other',
  Pbs = 'Pbs',
  Pharmacy = 'Pharmacy',
  Registration = 'Registration',
  Telephone = 'Telephone',
  Vip = 'Vip',
  WeChat = 'WeChat',
  WeChatApp = 'WeChatApp'
}

export enum OrderStatus {
  All = 'All',
  Blocked = 'Blocked',
  CancelRequested = 'CancelRequested',
  Canceled = 'Canceled',
  CreditNote = 'CreditNote',
  Paid = 'Paid',
  Pending = 'Pending',
  Unpaid = 'Unpaid',
  Waiting = 'Waiting',
  WaitingForApproval = 'WaitingForApproval'
}

export type OrderTracking = {
  readonly __typename?: 'OrderTracking';
  readonly currentStatus?: Maybe<TrackingStatus>;
  readonly isCourierApiEnabled: Scalars['Boolean']['output'];
  readonly isRealTimeOrderTrackingEnabled: Scalars['Boolean']['output'];
  readonly step1?: Maybe<OrderTrackingStep>;
  readonly step2?: Maybe<OrderTrackingStep>;
  readonly step3?: Maybe<OrderTrackingStep>;
  readonly step4?: Maybe<OrderTrackingStep>;
  readonly step5?: Maybe<OrderTrackingStep>;
  readonly url?: Maybe<Scalars['String']['output']>;
  readonly waybillNumber?: Maybe<Scalars['String']['output']>;
};

export type OrderTrackingStep = {
  readonly __typename?: 'OrderTrackingStep';
  readonly dateTime?: Maybe<Scalars['DateTime']['output']>;
  readonly status: TrackingStatus;
};

export type Orders = {
  readonly __typename?: 'Orders';
  readonly collection?: Maybe<ReadonlyArray<Maybe<Order>>>;
  readonly skip?: Maybe<Scalars['Int']['output']>;
  readonly top?: Maybe<Scalars['Int']['output']>;
  readonly totalRecords: Scalars['Int']['output'];
};

export type PageNavigation = {
  readonly __typename?: 'PageNavigation';
  /** Navigation menu item */
  readonly items?: Maybe<ReadonlyArray<Maybe<PageNavigationItem>>>;
  /** Page ID */
  readonly pageId?: Maybe<Scalars['String']['output']>;
};

export type PageNavigationItem = {
  readonly __typename?: 'PageNavigationItem';
  /** Unique ID of navigation item */
  readonly itemId?: Maybe<Scalars['String']['output']>;
  /** Localized label */
  readonly label?: Maybe<Scalars['String']['output']>;
  /** Relative URL */
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** Payment Channel for bonus type */
export enum PaymentChannel {
  /** Commission payment channel (BonusType released to bank account) */
  Commission = 'Commission',
  /** Discount payment channel (BonusType released to discount) */
  Discount = 'Discount',
  /** Sales Action Discount (BonusType not released - used for virtual income i.e. immediate profit) */
  SalesActionDiscount = 'SalesActionDiscount'
}

export type PaymentMethod = {
  readonly __typename?: 'PaymentMethod';
  /** ID of the payment method. */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** URL of the payment method's image. */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Title of the payment method. */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type PendingPaymentsResult = {
  readonly __typename?: 'PendingPaymentsResult';
  readonly canPay: Scalars['Boolean']['output'];
  readonly maxPendingPayments: Scalars['Int']['output'];
  readonly pendingPaymentsCount: Scalars['Int']['output'];
};

export type PeriodOrderEvaluationStatus = {
  readonly __typename?: 'PeriodOrderEvaluationStatus';
  readonly periodId: Scalars['Int']['output'];
  readonly periodOrderNumber: Scalars['Int']['output'];
  readonly periodStatus: PeriodStatus;
};

export enum PeriodStatus {
  ActiveCompleted = 'ActiveCompleted',
  ActiveNotCompleted = 'ActiveNotCompleted',
  Completed = 'Completed',
  NotActive = 'NotActive',
  NotCompleted = 'NotCompleted'
}

export type PermanentTopBanner = {
  readonly __typename?: 'PermanentTopBanner';
  readonly link?: Maybe<Link>;
  readonly message?: Maybe<Scalars['String']['output']>;
};

export enum PermanentTopBannerType {
  /** All pages behind Checkout */
  Checkout = 'Checkout',
  /** All pages behind my-pages */
  MyPages = 'MyPages',
  /** All other public pages */
  PublicPage = 'PublicPage',
  /** All public pages with provided referral parameter */
  PublicPageWithReferral = 'PublicPageWithReferral'
}

export type PersonalBeautyStore = {
  readonly __typename?: 'PersonalBeautyStore';
  readonly customerId?: Maybe<Scalars['Long']['output']>;
  /** Text description of PBS / applicable for PBS lite */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Name of PBS */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Product lists */
  readonly productLists?: Maybe<ReadonlyArray<Maybe<ProductList>>>;
  /** Personal beauty store owner profile */
  readonly profile?: Maybe<PersonalBeautyStoreProfile>;
  /** Url to PBS */
  readonly url?: Maybe<Scalars['String']['output']>;
  /** Live video shows */
  readonly videoShows?: Maybe<ReadonlyArray<Maybe<VideoShow>>>;
};


export type PersonalBeautyStoreProductListsArgs = {
  productListType: ProductListType;
};

export type PersonalBeautyStoreProfile = {
  readonly __typename?: 'PersonalBeautyStoreProfile';
  /** Consultant number */
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  /** Pbs owner contacts */
  readonly contacts?: Maybe<ReadonlyArray<Maybe<SocialContact>>>;
  /** Description of PBS */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** First name */
  readonly firstName?: Maybe<Scalars['String']['output']>;
  /** Last name */
  readonly lastName?: Maybe<Scalars['String']['output']>;
  /** Name PBS store */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Pbs owner photo */
  readonly photoUrl?: Maybe<Scalars['String']['output']>;
  /** Referral program data */
  readonly referralProgram?: Maybe<ReferralProgram>;
};

export type PersonalEditorialLandingPage = {
  readonly __typename?: 'PersonalEditorialLandingPage';
  /** Background color */
  readonly backgroundStorybookColor?: Maybe<StorybookColor>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Editorial collection */
  readonly editorialItems?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  /** Headline text color */
  readonly headlineStorybookColor?: Maybe<StorybookColor>;
  /** Image Url */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Page Id */
  readonly landingPageId: Scalars['ID']['output'];
  /** Link to the landing page */
  readonly link?: Maybe<Link>;
  /** Theme collection */
  readonly themeItems?: Maybe<ReadonlyArray<Maybe<PersonalEditorialLandingPage>>>;
  /** Theme Tag */
  readonly themeTag?: Maybe<Scalars['String']['output']>;
  /** Thumbnail Url */
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};


export type PersonalEditorialLandingPageEditorialItemsArgs = {
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};

export type PersonalProductListConfiguration = {
  readonly __typename?: 'PersonalProductListConfiguration';
  readonly addThis?: Maybe<AddThis>;
  /** Link to a home page */
  readonly homePageUrl?: Maybe<Scalars['String']['output']>;
  /** Is dynamic link enabled for PPL */
  readonly isDynamicLinkEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is PBS ownership is required for PPL */
  readonly isPbsRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Is PPL enabled */
  readonly isPplEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Link to Personal Beauty Store management page */
  readonly pbsPageUrl?: Maybe<Scalars['String']['output']>;
};

export type PersonalSubscription = {
  readonly __typename?: 'PersonalSubscription';
  /** Subscription cancel date */
  readonly cancelled?: Maybe<Scalars['DateTime']['output']>;
  /** Subscription type */
  readonly category: PersonalSubscriptionCategory;
  /** The customer associated with the subscription. */
  readonly customer?: Maybe<Customer>;
  /** Subscription owner */
  readonly customerId: Scalars['Long']['output'];
  /** Subscription frequency (days) */
  readonly frequency: Scalars['Int']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  /** Subscription is temporary paused */
  readonly isPaused: Scalars['Boolean']['output'];
  /** Subscribed product */
  readonly itemCode?: Maybe<Scalars['String']['output']>;
  /** Last shipment date */
  readonly lastShipment: Scalars['DateTime']['output'];
  /** Next shipment date */
  readonly nextShipment: Scalars['DateTime']['output'];
  /** Unique identification */
  readonly personalSubscriptionId: Scalars['ID']['output'];
  /** The get selected delivery channel and addresses. */
  readonly product?: Maybe<Product>;
  /** Product quantity */
  readonly quantity: Scalars['Int']['output'];
};

export enum PersonalSubscriptionCategory {
  PersonalNutrition = 'PersonalNutrition',
  Product = 'Product'
}

export type PersonalSubscriptions = {
  readonly __typename?: 'PersonalSubscriptions';
  readonly collection?: Maybe<ReadonlyArray<Maybe<PersonalSubscription>>>;
  readonly skip?: Maybe<Scalars['Int']['output']>;
  readonly top?: Maybe<Scalars['Int']['output']>;
  readonly totalRecords: Scalars['Int']['output'];
};

export type Phone = {
  readonly __typename?: 'Phone';
  /** Is telephone number primary */
  readonly isPrimary?: Maybe<Scalars['Boolean']['output']>;
  /** Is telephone number verified */
  readonly isVerified?: Maybe<Scalars['Boolean']['output']>;
  /** Telephone id */
  readonly telephoneId: Scalars['ID']['output'];
  /** Telephone number */
  readonly telephoneNumber?: Maybe<Scalars['String']['output']>;
  /** Telephone type */
  readonly telephoneType?: Maybe<TelephoneType>;
};

export type PickupPoint = {
  readonly __typename?: 'PickupPoint';
  /** The get pick-up point details. */
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  readonly deliveryChannelId: Scalars['Long']['output'];
  readonly estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery channel can be selected */
  readonly isAllowed: Scalars['Boolean']['output'];
  /** Shipping joker can replace the fee */
  readonly isShippingJoker: Scalars['Boolean']['output'];
  /** Shipping fee before joker is applied */
  readonly originalShippingFee?: Maybe<Scalars['Decimal']['output']>;
  /** Not available products quantity */
  readonly outOfStockQuantity: Scalars['Int']['output'];
  readonly shippingFee: Scalars['Decimal']['output'];
};

export type PickupPoints = {
  readonly __typename?: 'PickupPoints';
  readonly collection?: Maybe<ReadonlyArray<Maybe<PickupPoint>>>;
  readonly totalRecords: Scalars['Int']['output'];
};

export enum PickupSearchFilter {
  All = 'All',
  DepartmentStore = 'DepartmentStore',
  Lpo = 'Lpo',
  MySpo = 'MySpo',
  PickupPoint = 'PickupPoint',
  PostOffice = 'PostOffice',
  PremiumSpo = 'PremiumSpo',
  Spo = 'Spo'
}

export type PlpFacet = {
  readonly __typename?: 'PlpFacet';
  /** Facet is active for products filtering */
  readonly active: Scalars['Boolean']['output'];
  /** Facet display name */
  readonly displayName?: Maybe<Scalars['String']['output']>;
  /** Facet name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Available facet values */
  readonly values: ReadonlyArray<PlpFacetValue>;
};

export type PlpFacetInfo = {
  readonly __typename?: 'PlpFacetInfo';
  /** Color hex code (for color-family facet field) */
  readonly color?: Maybe<Scalars['String']['output']>;
};

export type PlpFacetValue = {
  readonly __typename?: 'PlpFacetValue';
  /** Facet value is active for products filtering */
  readonly active: Scalars['Boolean']['output'];
  /** Count of products meeting facet condition */
  readonly count: Scalars['Int']['output'];
  /** Value display name */
  readonly displayName?: Maybe<Scalars['String']['output']>;
  /** Facet info */
  readonly facetInfo: PlpFacetInfo;
  /** Value key */
  readonly key?: Maybe<Scalars['String']['output']>;
};

export enum PlpOrderBy {
  HighestDiscount = 'HighestDiscount',
  HighestPrice = 'HighestPrice',
  HighestRating = 'HighestRating',
  Latest = 'Latest',
  LowestPrice = 'LowestPrice',
  Recommended = 'Recommended'
}

export type PlpOrderingDefinition = {
  readonly __typename?: 'PlpOrderingDefinition';
  /** Display text */
  readonly displayText?: Maybe<Scalars['String']['output']>;
  /** Product ordering strategy */
  readonly orderBy: PlpOrderBy;
};

export type PlpRow = {
  readonly __typename?: 'PlpRow';
  readonly content: ReadonlyArray<Maybe<MixedPlpContent>>;
};

export type PlpRowsFacetFilterInput = {
  /** Gets or sets the active children keys. */
  readonly keys?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  /** Gets or sets the facet name. */
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

export type PopularProductCategory = {
  readonly __typename?: 'PopularProductCategory';
  /** Taxonomy Id */
  readonly taxonomyId: Scalars['Int']['output'];
};

export type PopupNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'PopupNewsletterSubsciptionConfiguration';
  /** DelayInSeconds */
  readonly delayInSeconds?: Maybe<Scalars['Int']['output']>;
  /** DisplayFrequencyInDays */
  readonly displayFrequencyInDays?: Maybe<Scalars['Int']['output']>;
  /** Enabled */
  readonly enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type Price = {
  readonly __typename?: 'Price';
  /** Basic cashback amount */
  readonly basicCashback?: Maybe<Scalars['Float']['output']>;
  /** Consultant price from basic catalogue */
  readonly basicCatalogueConsultantPrice?: Maybe<Scalars['Float']['output']>;
  /** Price from basic catalogue */
  readonly basicCataloguePrice?: Maybe<Scalars['Float']['output']>;
  /** Current consultant price */
  readonly currentConsultantPrice?: Maybe<Scalars['Float']['output']>;
  /** Current price */
  readonly currentPrice?: Maybe<Scalars['Float']['output']>;
  /** Extra cashback amount */
  readonly extraCashback?: Maybe<Scalars['String']['output']>;
  /** Previous minimum price in last 30 days */
  readonly previousMinOfferPrice?: Maybe<Scalars['Float']['output']>;
  /** Price after immediate discount */
  readonly priceAfterImmediateDiscount?: Maybe<Scalars['Float']['output']>;
  /** Approximate Share &amp; Earn bonus amount */
  readonly sharingBonus?: Maybe<Scalars['Float']['output']>;
};

/** The product resolver. */
export type Product = {
  readonly __typename?: 'Product';
  /** Accord Dangereux Routier (Classification of Dangerous Goods). */
  readonly adr?: Maybe<Scalars['String']['output']>;
  /** Collection of alternative images */
  readonly alternativeImageUrls?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** The get product availability. */
  readonly availability?: Maybe<ProductAvailability>;
  /** The get back in stock availability. */
  readonly backInStockAvailability?: Maybe<BackInStockAvailability>;
  readonly barcode?: Maybe<Scalars['String']['output']>;
  /** The get benefit catalogue offers. */
  readonly benefitCatalogueOffers?: Maybe<ReadonlyArray<Maybe<CatalogueOffer>>>;
  /** Priority of Best Seller product. */
  readonly bestsellerPriority?: Maybe<Scalars['Int']['output']>;
  /** The product bundle components. */
  readonly bundleComponents?: Maybe<ReadonlyArray<Maybe<BundleComponent>>>;
  /** The get catalogue offers. */
  readonly catalogueOffers?: Maybe<ReadonlyArray<Maybe<CatalogueOffer>>>;
  /** @deprecated Use 'shadeName' field instead */
  readonly color?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use 'hexColors' field instead */
  readonly colorHexCode?: Maybe<Scalars['String']['output']>;
  /** Color url */
  readonly colorImageUrl?: Maybe<Scalars['String']['output']>;
  /** The get concept. */
  readonly concept?: Maybe<Concept>;
  readonly conceptCode?: Maybe<Scalars['String']['output']>;
  readonly depth?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use 'dimensionsText' field instead */
  readonly dimension?: Maybe<Scalars['String']['output']>;
  /** Localized dimensions of product (including value and units). */
  readonly dimensionsText?: Maybe<Scalars['String']['output']>;
  /** Product discontinuation date (product no more manufactured). */
  readonly discoDate?: Maybe<Scalars['DateTime']['output']>;
  /** Euro monitor class ID. */
  readonly euroMonitorClassId?: Maybe<Scalars['String']['output']>;
  /** The get formatted price. */
  readonly formattedPrice?: Maybe<ProductFormattedPrice>;
  /** Global Commercial Intent (gcint). */
  readonly globalCommercialIntent?: Maybe<Scalars['String']['output']>;
  /** Hash code calculated form the relevant product information */
  readonly hashCode?: Maybe<Scalars['Int']['output']>;
  readonly height?: Maybe<Scalars['Int']['output']>;
  /** Collection of product color hexadecimal codes. */
  readonly hexColors?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Product Image url */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Comma separated list of product ingredients. */
  readonly inci?: Maybe<Scalars['String']['output']>;
  /** The product includeded in bundles. */
  readonly includedInBundles?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** The get ingredients. */
  readonly ingredients?: Maybe<ReadonlyArray<Maybe<Ingredient>>>;
  /** Product introduction date (before the date, the product is not available). */
  readonly introDate?: Maybe<Scalars['DateTime']['output']>;
  /** Flag indicating if product is Best Seller. */
  readonly isBestseller?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the product is discontinued */
  readonly isDiscontinued: Scalars['Boolean']['output'];
  /** Flag indicating whether the product is a outlet. True - outlet product. False - product is not a outlet */
  readonly isOutlet: Scalars['Boolean']['output'];
  /** Flag indicating if product is customized. */
  readonly isProductCustomized?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the product is a sample. True - sample product. False - product is not a sample */
  readonly isSample: Scalars['Boolean']['output'];
  /** The get labels. */
  readonly labels?: Maybe<ReadonlyArray<Maybe<ProductLabel>>>;
  readonly length?: Maybe<Scalars['Int']['output']>;
  /** Makeup Try On Product Relation */
  readonly makeupTryOn?: Maybe<MakeupTryOnProductRelation>;
  /** The product expected inbound. */
  readonly manufacturers?: Maybe<ReadonlyArray<Maybe<Manufacturer>>>;
  /** The get parent products. */
  readonly parentProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** The get PDP Url. */
  readonly pdpUrl?: Maybe<Link>;
  /** The get product permission. */
  readonly permission?: Maybe<ProductPermission>;
  /** The get price. */
  readonly price?: Maybe<ProductPrice>;
  /** Code that identifies the resulting product */
  readonly productCode: Scalars['ID']['output'];
  /** Product detail page. */
  readonly productDetailPage?: Maybe<ProductDetailPage>;
  /** The product expected inbound. */
  readonly productExpectedInbound?: Maybe<ProductExpectedInbound>;
  /** Type of the product = ['Product', 'Bundle', 'Sample', 'Fee', 'Composite'] */
  readonly productType?: Maybe<ProductType>;
  /** The get purchase catalogue offers. */
  readonly purchaseCatalogueOffers?: Maybe<ReadonlyArray<Maybe<CatalogueOffer>>>;
  /** Recommended products */
  readonly recommendations?: Maybe<ReadonlyArray<Maybe<ProductRecommendation>>>;
  /** The get relations. */
  readonly relations?: Maybe<ReadonlyArray<Maybe<ProductRelation>>>;
  readonly replacedBy?: Maybe<Scalars['String']['output']>;
  /** The get replace by product. */
  readonly replacedByProduct?: Maybe<Product>;
  readonly replacementFor?: Maybe<Scalars['String']['output']>;
  /** The get replacement for product. */
  readonly replacementForProduct?: Maybe<Product>;
  /** The get replacements. */
  readonly replacements?: Maybe<ReadonlyArray<Maybe<ProductReplacement>>>;
  /** The get product sample. */
  readonly sample?: Maybe<Product>;
  /** Shade finder image url */
  readonly shadeFinderImageUrl?: Maybe<Scalars['String']['output']>;
  /** Localized product shade/color name. */
  readonly shadeName?: Maybe<Scalars['String']['output']>;
  /** Flag indicating if product should be shown on site */
  readonly showOnSite: Scalars['Boolean']['output'];
  /** Product subscription information */
  readonly subscription?: Maybe<Subscription>;
  /** The get subscription benefit banner. */
  readonly subscriptionBenefitBanner?: Maybe<SubscriptionBenefitBanner>;
  /**
   * Collection of product tags
   * @deprecated Should have no use. If you are using the field, contact david.konecny@oriflame.com
   */
  readonly tags?: Maybe<ReadonlyArray<Maybe<Tag>>>;
  /** Thumbnail image url */
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  readonly toolingBanners?: Maybe<ReadonlyArray<Maybe<ProductToolingBanner>>>;
  /** Product video url */
  readonly videoUrl?: Maybe<Scalars['String']['output']>;
  /** Product volume value. */
  readonly volume?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use 'volumeValue' field instead */
  readonly volumeText?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use 'volumeUnitText' field instead */
  readonly volumeUnit?: Maybe<Scalars['String']['output']>;
  /** Localized unit of product volume. */
  readonly volumeUnitText?: Maybe<Scalars['String']['output']>;
  /** Localized value of product volume. */
  readonly volumeValue?: Maybe<Scalars['Float']['output']>;
  readonly weight?: Maybe<Scalars['Float']['output']>;
};


/** The product resolver. */
export type ProductBenefitCatalogueOffersArgs = {
  top?: InputMaybe<Scalars['Int']['input']>;
};


/** The product resolver. */
export type ProductCatalogueOffersArgs = {
  top?: InputMaybe<Scalars['Int']['input']>;
};


/** The product resolver. */
export type ProductPurchaseCatalogueOffersArgs = {
  top?: InputMaybe<Scalars['Int']['input']>;
};


/** The product resolver. */
export type ProductRelationsArgs = {
  relationTypes?: InputMaybe<ReadonlyArray<ProductRelationType>>;
};

export type ProductAvailability = {
  readonly __typename?: 'ProductAvailability';
  /** Expected Inbound */
  readonly expectedInbound?: Maybe<Scalars['DateTime']['output']>;
  /** When item is out of stock, and can be backordered */
  readonly isBackorder?: Maybe<Scalars['Boolean']['output']>;
  /** Is bundle */
  readonly isBundle?: Maybe<Scalars['Boolean']['output']>;
  /** Introduction date of product is close */
  readonly isComingSoon?: Maybe<Scalars['Boolean']['output']>;
  /** Specified by number of days (sitecore) before disco date */
  readonly isLastChance?: Maybe<Scalars['Boolean']['output']>;
  /** Current ATP is lower than 24hr sales history */
  readonly isLowStock?: Maybe<Scalars['Boolean']['output']>;
  /** Number of days (sitecore) after intro date */
  readonly isNew?: Maybe<Scalars['Boolean']['output']>;
  /** Product is discontinued */
  readonly isNoLongerForSale?: Maybe<Scalars['Boolean']['output']>;
  /** Product cant be sold, price = 0, or is deleted */
  readonly isNotSoldOnline?: Maybe<Scalars['Boolean']['output']>;
  /** Is not standard item, catalogue offer for specific period */
  readonly isOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Out of stock */
  readonly isOutOfStock?: Maybe<Scalars['Boolean']['output']>;
  /** Is sales limited */
  readonly isSalesLimited?: Maybe<Scalars['Boolean']['output']>;
  readonly isSoldOut?: Maybe<Scalars['Boolean']['output']>;
  /** Product is Super Deal */
  readonly isSuperDeal?: Maybe<Scalars['Boolean']['output']>;
  readonly replacements?: Maybe<ReadonlyArray<Maybe<ProductReplacement>>>;
};

export type ProductDetailMakeupTryOnConfiguration = {
  readonly __typename?: 'ProductDetailMakeupTryOnConfiguration';
  /** allows Live Camera Label */
  readonly allowsLiveCameraLabel?: Maybe<Scalars['String']['output']>;
  /** Enabled */
  readonly enabled?: Maybe<Scalars['Boolean']['output']>;
  /** Shi Shi Component Url */
  readonly shiShiComponentUrl?: Maybe<Scalars['String']['output']>;
  /** try It On Label */
  readonly tryItOnLabel?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailMetadata = {
  readonly __typename?: 'ProductDetailMetadata';
  readonly jsonLd?: Maybe<Scalars['JSON']['output']>;
};

export type ProductDetailPage = {
  readonly __typename?: 'ProductDetailPage';
  /** Breadcrumb links */
  readonly breadcrumbLinks?: Maybe<BreadcrumbLinks>;
  /** PDP configuration */
  readonly configuration?: Maybe<ProductDetailPageConfiguration>;
  /** Editorial carousel */
  readonly editorialCarousel?: Maybe<EditorialCarousel>;
  readonly guarantees: ProductDetailPageGuarantees;
  readonly metadata: ProductDetailMetadata;
  readonly modules: ReadonlyArray<EditorialPageContent>;
  /** Product */
  readonly product?: Maybe<Product>;
  readonly sections?: Maybe<ProductDetailPageSectionsContent>;
  /** Tooling banners */
  readonly toolingBanners: ReadonlyArray<ProductToolingBanner>;
};


export type ProductDetailPageModulesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductDetailPageAboutSection = {
  readonly __typename?: 'ProductDetailPageAboutSection';
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailPageAdditionalInfoSection = {
  readonly __typename?: 'ProductDetailPageAdditionalInfoSection';
  readonly expiryDate?: Maybe<ProductDetailPageExpirationDate>;
  readonly isImporterInfoEnabled: Scalars['Boolean']['output'];
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly manufacturers?: Maybe<ProductDetailPageManufacturers>;
};

export type ProductDetailPageConfiguration = {
  readonly __typename?: 'ProductDetailPageConfiguration';
  /** Is MRP statement enabled */
  readonly isMrpStatementEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is sharing enabled */
  readonly isSharingEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Max quantity */
  readonly maxQuantity?: Maybe<Scalars['Int']['output']>;
  /** Product detail page sections */
  readonly sections?: Maybe<ProductDetailPageSections>;
  /** Shipping info */
  readonly shippingInfo?: Maybe<ProductDetailShippingInfo>;
};

export type ProductDetailPageExpirationDate = {
  readonly __typename?: 'ProductDetailPageExpirationDate';
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailPageGuarantees = {
  readonly __typename?: 'ProductDetailPageGuarantees';
  /** Collection of payment methods */
  readonly deliveryFee?: Maybe<DeliveryFee>;
  /** Information about free delivery */
  readonly freeDelivery?: Maybe<FreeDelivery>;
  /** Collection of payment methods */
  readonly paymentMethods: ReadonlyArray<PaymentMethod>;
  /** Time guarantees for delivery */
  readonly timeGuarantees?: Maybe<TimeGuarantees>;
};

export type ProductDetailPageIngredient = {
  readonly __typename?: 'ProductDetailPageIngredient';
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailPageManufacturer = {
  readonly __typename?: 'ProductDetailPageManufacturer';
  readonly address1?: Maybe<Scalars['String']['output']>;
  readonly address2?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly countryCode?: Maybe<Scalars['String']['output']>;
  readonly countryName?: Maybe<Scalars['String']['output']>;
  readonly manufacturerId?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly state?: Maybe<Scalars['String']['output']>;
  readonly zipCode?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailPageManufacturers = {
  readonly __typename?: 'ProductDetailPageManufacturers';
  readonly countryOfOriginLabel?: Maybe<Scalars['String']['output']>;
  readonly informationLabel?: Maybe<Scalars['String']['output']>;
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly manufacturers?: Maybe<ReadonlyArray<Maybe<ProductDetailPageManufacturer>>>;
};

export type ProductDetailPageSections = {
  readonly __typename?: 'ProductDetailPageSections';
  /** Indicates if the about section is disabled */
  readonly aboutDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the additional information section is disabled */
  readonly additionalInformationDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the beauty edit module is disabled */
  readonly beautyEditModuleDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the how to use section is disabled */
  readonly howToUseDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the ingredients section is disabled */
  readonly ingredientsDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Inducates if the rating and reviews section is disabled */
  readonly ratingAndReviewsDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the shipping info section is disabled */
  readonly shippingInfoDisabled?: Maybe<Scalars['Boolean']['output']>;
};

export type ProductDetailPageSectionsContent = {
  readonly __typename?: 'ProductDetailPageSectionsContent';
  readonly about?: Maybe<ProductDetailPageAboutSection>;
  readonly additionalInformation?: Maybe<ProductDetailPageAdditionalInfoSection>;
  readonly ingredients?: Maybe<ReadonlyArray<Maybe<ProductDetailPageIngredient>>>;
};

export type ProductDetailShippingInfo = {
  readonly __typename?: 'ProductDetailShippingInfo';
  /** Shipping info content */
  readonly content?: Maybe<Scalars['String']['output']>;
  /** Shipping info title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ProductExpectedInbound = {
  readonly __typename?: 'ProductExpectedInbound';
  /** Expected Inbound Date */
  readonly expectedInboundDate?: Maybe<Scalars['DateTime']['output']>;
  /** Expected Inbound Label */
  readonly expectedInboundLabel?: Maybe<Scalars['String']['output']>;
};

export type ProductFormattedPrice = {
  readonly __typename?: 'ProductFormattedPrice';
  /** Business points assigned to the product */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Timestamp of when price was calculated */
  readonly dateCalculated?: Maybe<Scalars['DateTime']['output']>;
  /** Object containing concept's prices */
  readonly price?: Maybe<FormattedPrice>;
};

export type ProductLabel = {
  readonly __typename?: 'ProductLabel';
  /** Help text */
  readonly helpText?: Maybe<Scalars['String']['output']>;
  /** Key of the label */
  readonly labelKey: Scalars['ID']['output'];
  /** Product label domain type */
  readonly productLabelDomain?: Maybe<ProductLabelDomainType>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

/** Product Label Domain Type */
export enum ProductLabelDomainType {
  /** Only Offer, New, SuperDeal */
  Deal = 'Deal',
  Ppa = 'PPA',
  /** Domain Type for AddToBasketVisible ProductLabelType */
  Shopping = 'Shopping'
}

export type ProductList = {
  readonly __typename?: 'ProductList';
  /** Date of creation */
  readonly created?: Maybe<Scalars['DateTime']['output']>;
  /** Collection of product items */
  readonly items?: Maybe<ReadonlyArray<Maybe<ProductListItem>>>;
  /** Name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Id */
  readonly productListId: Scalars['ID']['output'];
  /** Type of product list */
  readonly productListType?: Maybe<ProductListType>;
};

export type ProductListItem = {
  readonly __typename?: 'ProductListItem';
  /** Product code */
  readonly itemCode?: Maybe<Scalars['String']['output']>;
};

export enum ProductListType {
  Favorite = 'Favorite',
  Personal = 'Personal',
  Private = 'Private',
  Wishlist = 'Wishlist'
}

export type ProductListingBanner = {
  readonly __typename?: 'ProductListingBanner';
  readonly contentVerticalAlignment?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly imageHoverUrl?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly isUpperCase: Scalars['Boolean']['output'];
  readonly link?: Maybe<Link>;
  readonly size: Scalars['Int']['output'];
  readonly textColour?: Maybe<Scalars['String']['output']>;
};

/** Enum ProductListingBannerVideoType */
export enum ProductListingBannerVideoType {
  Mp4 = 'Mp4',
  None = 'None',
  Youku = 'Youku',
  Youtube = 'Youtube'
}

export type ProductListingPage = {
  readonly __typename?: 'ProductListingPage';
  readonly contentCollection?: Maybe<ReadonlyArray<Maybe<ProductListingPageContent>>>;
  /** Page's Description */
  readonly desription?: Maybe<Scalars['String']['output']>;
  /** Main image's url */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Page's Name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Page's ID */
  readonly pageId: Scalars['ID']['output'];
  /** Related editorial pages */
  readonly relatedEditorialPages?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  /** Page's Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};


export type ProductListingPageRelatedEditorialPagesArgs = {
  channelType: EditorialChannelType;
};

export type ProductListingPageContent = Product | ProductListingPromoBanner;

export type ProductListingProduct = {
  readonly __typename?: 'ProductListingProduct';
  readonly pricePosX: Scalars['Int']['output'];
  readonly pricePosY: Scalars['Int']['output'];
  /** The get product. */
  readonly product?: Maybe<Product>;
};

export type ProductListingPromoBanner = {
  readonly __typename?: 'ProductListingPromoBanner';
  readonly productListingBanner?: Maybe<ProductListingBanner>;
  readonly productListingProduct?: Maybe<ProductListingProduct>;
  readonly productListingVideo?: Maybe<ProductListingVideo>;
};

export type ProductListingVideo = {
  readonly __typename?: 'ProductListingVideo';
  readonly autoplayOnScroll: Scalars['Boolean']['output'];
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly playInLoop: Scalars['Boolean']['output'];
  readonly type: ProductListingBannerVideoType;
};

export type ProductPermission = {
  readonly __typename?: 'ProductPermission';
  /** Product can be put into the basket owned by specified category of the customer */
  readonly canBuy?: Maybe<Scalars['Boolean']['output']>;
  /** Product can be displayed on web site or any other mobile application. Product display might not be available if the flag is set to false. */
  readonly canDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** Logged-in customer of specified category can put a product to current/active basket */
  readonly canSell?: Maybe<Scalars['Boolean']['output']>;
  /** Code that identifies the resulting product */
  readonly productCode?: Maybe<Scalars['String']['output']>;
};

export type ProductPrice = {
  readonly __typename?: 'ProductPrice';
  /** Business points */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Currency symbol, for example: "€", "$" */
  readonly currency?: Maybe<Scalars['String']['output']>;
  /** Date calculated */
  readonly dateCalculated?: Maybe<Scalars['DateTime']['output']>;
  /** Prices */
  readonly price?: Maybe<Price>;
};

/** The product recommendation resolver. */
export type ProductRecommendation = {
  readonly __typename?: 'ProductRecommendation';
  /** Recommended product */
  readonly recommendedProduct?: Maybe<Product>;
  /** @deprecated Use ProductCode from RecommendedProduct instead. */
  readonly recommendedProductCode?: Maybe<Scalars['ID']['output']>;
  /** Recommended product similarity to the original product */
  readonly similarity?: Maybe<Scalars['Float']['output']>;
};

export type ProductRelation = {
  readonly __typename?: 'ProductRelation';
  /** Related products */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly relationType?: Maybe<ProductRelationType>;
};

export enum ProductRelationType {
  Favourite = 'Favourite',
  Popular = 'Popular',
  RecentlyViewed = 'RecentlyViewed',
  Related = 'Related',
  Samples = 'Samples'
}

export type ProductReplacement = {
  readonly __typename?: 'ProductReplacement';
  /** Replacement product */
  readonly product?: Maybe<Product>;
  /** Replacement quantity */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  readonly replacementType?: Maybe<ProductReplacementType>;
};

export enum ProductReplacementType {
  /** Alternative replacement. */
  Alternative = 'Alternative',
  /** Automatic replacement without interaction with user. */
  Automatic = 'Automatic',
  /** Manual replacement during shopping. */
  Manual = 'Manual',
  /** Taste replacement of subscribed product. */
  Subscription = 'Subscription',
  /** Automatic replacement of subscribed wellness product without interaction with user. */
  WellnessAutomatic = 'WellnessAutomatic',
  /** Manual replacement of subscribed wellness product during shopping. */
  WellnessManual = 'WellnessManual'
}

export type ProductSearchPage = {
  readonly __typename?: 'ProductSearchPage';
  /**
   * Query products based on the specified parameters.
   *
   *
   * **Returns:**
   * The products matching the search criteria.
   */
  readonly products: ProductSearchPageProducts;
};


export type ProductSearchPageProductsArgs = {
  facetFiltering?: InputMaybe<ReadonlyArray<PlpRowsFacetFilterInput>>;
  orderBy?: PlpOrderBy;
  query: Scalars['String']['input'];
  skipRows?: Scalars['Int']['input'];
  topRows?: Scalars['Int']['input'];
};

export type ProductSearchPageProducts = {
  readonly __typename?: 'ProductSearchPageProducts';
  readonly facets: ReadonlyArray<PlpFacet>;
  readonly orderByOptions: ReadonlyArray<PlpOrderingDefinition>;
  readonly rows: ReadonlyArray<PlpRow>;
  readonly totalProductsCount: Scalars['Int']['output'];
};

export type ProductSearchPreview = {
  readonly __typename?: 'ProductSearchPreview';
  /** Search results */
  readonly searchResults: ProductSearchResults;
};


export type ProductSearchPreviewSearchResultsArgs = {
  query: Scalars['String']['input'];
  top?: Scalars['Int']['input'];
};

/** Represents the results of a product search. */
export type ProductSearchResults = {
  readonly __typename?: 'ProductSearchResults';
  /** List of products for the given search results. */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Suggestions for subsequent search queries */
  readonly suggestions: ReadonlyArray<Scalars['String']['output']>;
  /** Total count of products. */
  readonly totalProductsCount: Scalars['Int']['output'];
};

export type ProductStandards = {
  readonly __typename?: 'ProductStandards';
  /** Section's headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Product standards images */
  readonly images?: Maybe<ReadonlyArray<Maybe<FrontPageImage>>>;
  /** Section's main text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ProductToolingBanner = {
  readonly __typename?: 'ProductToolingBanner';
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image URL */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link */
  readonly link?: Maybe<Link>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

export enum ProductType {
  Bundle = 'Bundle',
  Composite = 'Composite',
  Fee = 'Fee',
  Product = 'Product',
  Sample = 'Sample'
}

export type ProductsPromotionBanner = {
  readonly __typename?: 'ProductsPromotionBanner';
  /** Font color of the iconic products banner. */
  readonly fontColor: StorybookColor;
  /** Headline of the iconic products banner. */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** URL of the image for the iconic products banner. */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link associated with the iconic products banner. */
  readonly link?: Maybe<Link>;
};

/** The query. */
export type Query = {
  readonly __typename?: 'Query';
  readonly application?: Maybe<Application>;
  /** The get personal beauty store. */
  readonly beautyStore?: Maybe<PersonalBeautyStore>;
  /** The get categories. */
  readonly categories?: Maybe<ReadonlyArray<Maybe<Category>>>;
  /** The get category. */
  readonly category?: Maybe<Category>;
  readonly claiming?: Maybe<Claiming>;
  /** Provides concepts. */
  readonly concept?: Maybe<Concept>;
  /** Provides concepts. */
  readonly concepts?: Maybe<ReadonlyArray<Maybe<Concept>>>;
  /** The get customer. */
  readonly customer?: Maybe<Customer>;
  /** Gets the ingredients. */
  readonly ingredients?: Maybe<ReadonlyArray<Maybe<Ingredient>>>;
  /** The get invite friend profile. */
  readonly inviteFriendProfile?: Maybe<InviteFriendProfile>;
  readonly languages?: Maybe<ReadonlyArray<Maybe<Language>>>;
  readonly mobileApps?: Maybe<MobileApps>;
  readonly newsletterSubsciptionConfiguration?: Maybe<NewsletterSubsciptionConfiguration>;
  /** The get order. */
  readonly order?: Maybe<Order>;
  /** Get order history. */
  readonly orders?: Maybe<Orders>;
  /** The get product. */
  readonly product?: Maybe<Product>;
  /** The get products. */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly shopping?: Maybe<Shopping>;
  readonly socialMediaLinks?: Maybe<ReadonlyArray<Maybe<SocialMediaLinks>>>;
  readonly subscribing?: Maybe<Subscribing>;
  readonly vipCustomerBanner?: Maybe<VipCustomerBanner>;
};


/** The query. */
export type QueryBeautyStoreArgs = {
  name?: InputMaybe<Scalars['ID']['input']>;
};


/** The query. */
export type QueryCategoriesArgs = {
  taxonomyIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


/** The query. */
export type QueryCategoryArgs = {
  taxonomyId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query. */
export type QueryConceptArgs = {
  conceptCode?: InputMaybe<Scalars['ID']['input']>;
};


/** The query. */
export type QueryConceptsArgs = {
  conceptCodes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  top?: InputMaybe<Scalars['Int']['input']>;
};


/** The query. */
export type QueryIngredientsArgs = {
  ingredientNames?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Int']['input']>;
};


/** The query. */
export type QueryInviteFriendProfileArgs = {
  consultantNumber: Scalars['Int']['input'];
};


/** The query. */
export type QueryOrderArgs = {
  orderId: Scalars['ID']['input'];
};


/** The query. */
export type QueryOrdersArgs = {
  customerId?: InputMaybe<Scalars['Long']['input']>;
  excludeWeChatOrders?: Scalars['Boolean']['input'];
  historyType?: OrderHistoryType;
  searchPhase?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  status?: OrderStatus;
  top?: InputMaybe<Scalars['Int']['input']>;
};


/** The query. */
export type QueryProductArgs = {
  productCode?: InputMaybe<Scalars['ID']['input']>;
};


/** The query. */
export type QueryProductsArgs = {
  productCodes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};

export type RatingsAndReviewsConfiguration = {
  readonly __typename?: 'RatingsAndReviewsConfiguration';
  readonly legalNoticeLink?: Maybe<Link>;
  readonly legalNoticeText?: Maybe<Scalars['String']['output']>;
  readonly testFreaksConfiguration?: Maybe<TestFreaksConfiguration>;
};

export type RecruitmentInfo = {
  readonly __typename?: 'RecruitmentInfo';
  readonly recruitDate?: Maybe<Scalars['DateTime']['output']>;
  readonly signUpDate?: Maybe<Scalars['DateTime']['output']>;
};

export type RecurringPaymentCard = {
  readonly __typename?: 'RecurringPaymentCard';
  /** Last 4 digits */
  readonly cardNumberLast4?: Maybe<Scalars['String']['output']>;
  /** Type of card (VISA, MC, ...) */
  readonly cardType?: Maybe<Scalars['String']['output']>;
  /** Name of cardholder */
  readonly cardholderName?: Maybe<Scalars['String']['output']>;
  /** Moment of creation of a recurring contract */
  readonly created: Scalars['DateTime']['output'];
  /** Month of expiration */
  readonly expiryMonth?: Maybe<Scalars['Int']['output']>;
  /** Year of expiration */
  readonly expiryYear?: Maybe<Scalars['Int']['output']>;
  /** Absolute url of card logo image */
  readonly logoUrl?: Maybe<Scalars['String']['output']>;
  /** Card Id */
  readonly recurringPaymentCardId?: Maybe<Scalars['String']['output']>;
};

export type ReferralProgram = {
  readonly __typename?: 'ReferralProgram';
  /** Allowed contacts */
  readonly allowedContacts?: Maybe<ReadonlyArray<SocialContactType>>;
  /** Referral link */
  readonly benefitsLink?: Maybe<Link>;
  /** Greetings message */
  readonly greetingMessage?: Maybe<Scalars['String']['output']>;
  /** Message */
  readonly message?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export enum ReplacementType {
  Alternative = 'Alternative',
  Automatic = 'Automatic',
  Manual = 'Manual'
}

export type ResponseMessageNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'ResponseMessageNewsletterSubsciptionConfiguration';
  /** text */
  readonly text?: Maybe<Scalars['String']['output']>;
  /** Type */
  readonly type?: Maybe<ResponseMessageNewsletterSubsciptionsType>;
};

/** ResponseMessageNewsletterSubsciptionsType */
export enum ResponseMessageNewsletterSubsciptionsType {
  /** Cleaned */
  Cleaned = 'Cleaned',
  /** New */
  New = 'New',
  /** Pending */
  Pending = 'Pending',
  /** Subscribed */
  Subscribed = 'Subscribed',
  /** Unsubscribed */
  Unsubscribed = 'Unsubscribed'
}

export type ReturnedClaimItem = {
  readonly __typename?: 'ReturnedClaimItem';
  /** Production batch identification */
  readonly batchId?: Maybe<Scalars['String']['output']>;
  /** Order item to claim */
  readonly componentNumber?: Maybe<Scalars['Int']['output']>;
  /** Identity of the products should not be presented (surprise gift) */
  readonly isHidden: Scalars['Boolean']['output'];
  /** Returned product */
  readonly itemCode?: Maybe<Scalars['String']['output']>;
  /** Order item to claim */
  readonly orderItemId: Scalars['Long']['output'];
  /** The get product from the claim. */
  readonly product?: Maybe<Product>;
  /** Claimed quantity */
  readonly quantity: Scalars['Int']['output'];
  /** Claim reason */
  readonly reason?: Maybe<ClaimReasonType>;
  /** Claim scenario */
  readonly scenarioId: Scalars['Int']['output'];
  /** Returned BP */
  readonly totalBp: Scalars['Decimal']['output'];
  /** Returned price */
  readonly totalPrice: Scalars['Decimal']['output'];
};

export type SalesBoosterQualificationInfo = {
  readonly __typename?: 'SalesBoosterQualificationInfo';
  readonly actualBp: Scalars['Float']['output'];
  readonly bonusAmount: Scalars['Float']['output'];
  readonly bonusAmountDifference?: Maybe<Scalars['Float']['output']>;
  readonly bpConditionsFulfilled: Scalars['Boolean']['output'];
  readonly dashboardImage?: Maybe<Scalars['String']['output']>;
  readonly firstLevelBvRateReward: Scalars['Float']['output'];
  readonly firstLevelMemberSubtypeBvRateReward: Scalars['Float']['output'];
  readonly firstLineMemberBp: Scalars['Float']['output'];
  readonly firstLineMemberBpPercentage: Scalars['Float']['output'];
  readonly level?: Maybe<Scalars['Int']['output']>;
  readonly maxAmountCapPersonal: Scalars['Float']['output'];
  readonly maxAmountCapTotal: Scalars['Float']['output'];
  readonly maxBpFromFirstLineMembers: Scalars['Float']['output'];
  readonly pageTopImage?: Maybe<Scalars['String']['output']>;
  readonly personalBp: Scalars['Float']['output'];
  readonly personalBpPercentage: Scalars['Float']['output'];
  readonly personalBvRateReward: Scalars['Float']['output'];
  readonly personalBvRateRewardDifference?: Maybe<Scalars['Float']['output']>;
  readonly showPersonalBpAlert: Scalars['Boolean']['output'];
  readonly targetBp: Scalars['Float']['output'];
  readonly termsAndConditionsLink?: Maybe<Scalars['String']['output']>;
  readonly version?: Maybe<Scalars['Int']['output']>;
  readonly youtubeVideoId?: Maybe<Scalars['String']['output']>;
};

export enum SettingsClientType {
  OnlineReportingGrid = 'OnlineReportingGrid',
  WellnessClub = 'WellnessClub'
}

export enum SettingsFormatType {
  Json = 'Json',
  String = 'String',
  Xml = 'Xml'
}

export type ShareAndEarn = {
  readonly __typename?: 'ShareAndEarn';
  /** Frequently Asked Questions section */
  readonly faqSection?: Maybe<ShareAndEarnFaqSection>;
  /** How it works section */
  readonly howItWorksSection?: Maybe<ShareAndEarnHowItWorksSection>;
  /** Personal code edit page */
  readonly personalCodeEditPage?: Maybe<ShareAndEarnPersonalCodeEditPage>;
  /** Personal links content */
  readonly personalLinksContent?: Maybe<ShareAndEarnPersonalLinks>;
  /** Share and earn section */
  readonly shareAndEarnSection?: Maybe<ShareAndEarnShareSection>;
  /** Sharing conditions */
  readonly sharingConditions?: Maybe<ShareAndEarnConditions>;
  /** Top section */
  readonly topSection?: Maybe<ShareAndEarnTopSection>;
};

export type ShareAndEarnConditionItem = {
  readonly __typename?: 'ShareAndEarnConditionItem';
  /** Condition text */
  readonly lines?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Condition title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnConditions = {
  readonly __typename?: 'ShareAndEarnConditions';
  /** Condition collection */
  readonly collection?: Maybe<ReadonlyArray<Maybe<ShareAndEarnConditionItem>>>;
  /** Conditions headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnFaqItem = {
  readonly __typename?: 'ShareAndEarnFaqItem';
  /** Content */
  readonly content?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnFaqSection = {
  readonly __typename?: 'ShareAndEarnFaqSection';
  /** Collection of FAQ items */
  readonly collection?: Maybe<ReadonlyArray<Maybe<ShareAndEarnFaqItem>>>;
  /** Headline of the section */
  readonly headline?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnHowItWorksSection = {
  readonly __typename?: 'ShareAndEarnHowItWorksSection';
  /** Conditions link title */
  readonly conditionsLinkTitle?: Maybe<Scalars['String']['output']>;
  /** Earn description */
  readonly earnDescription?: Maybe<Scalars['String']['output']>;
  /** Earn title */
  readonly earnTitle?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Invite description */
  readonly inviteDescription?: Maybe<Scalars['String']['output']>;
  /** Invite title */
  readonly inviteTitle?: Maybe<Scalars['String']['output']>;
  /** Share description */
  readonly shareDescription?: Maybe<Scalars['String']['output']>;
  /** Share title */
  readonly shareTitle?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnPersonalCodeEditPage = {
  readonly __typename?: 'ShareAndEarnPersonalCodeEditPage';
  /** Button applied label */
  readonly buttonAppliedLabel?: Maybe<Scalars['String']['output']>;
  /** Button cancel label */
  readonly buttonCancelLabel?: Maybe<Scalars['String']['output']>;
  /** Button change label */
  readonly buttonChangeLabel?: Maybe<Scalars['String']['output']>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Invalid code message */
  readonly invalidCodeMessage?: Maybe<Scalars['String']['output']>;
  /** Your code label */
  readonly yourCodeLabel?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnPersonalLinks = {
  readonly __typename?: 'ShareAndEarnPersonalLinks';
  /** Copy label */
  readonly copyLabel?: Maybe<Scalars['String']['output']>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Instructions */
  readonly instructions?: Maybe<Scalars['String']['output']>;
  /** Links */
  readonly links?: Maybe<ReadonlyArray<Maybe<SharingLink>>>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnShareSection = {
  readonly __typename?: 'ShareAndEarnShareSection';
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Sharing card */
  readonly sharingCard?: Maybe<SharingCard>;
};

export type ShareAndEarnTopSection = {
  readonly __typename?: 'ShareAndEarnTopSection';
  /** Top section description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Top section headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnUpdatePersonalCodeInput = {
  /** The customer ID of the customer who wants to update the personal code. */
  readonly customerId?: InputMaybe<Scalars['String']['input']>;
  /** The new personal code */
  readonly newPersonalCode?: InputMaybe<Scalars['String']['input']>;
};

export type SharedAddress = {
  readonly __typename?: 'SharedAddress';
  readonly addressId: Scalars['ID']['output'];
  readonly area?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly district?: Maybe<Scalars['String']['output']>;
  readonly locality?: Maybe<Scalars['String']['output']>;
  readonly province?: Maybe<Scalars['String']['output']>;
  readonly region?: Maybe<Scalars['String']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly zipcode?: Maybe<Scalars['String']['output']>;
};

export type SharingCard = {
  readonly __typename?: 'SharingCard';
  /** From label */
  readonly fromLabel?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
  /** Use code label */
  readonly useCodeLabel?: Maybe<Scalars['String']['output']>;
};

export type SharingLink = {
  readonly __typename?: 'SharingLink';
  /** Link definition */
  readonly link?: Maybe<Link>;
  /** Link tooltip */
  readonly tooltip?: Maybe<Scalars['String']['output']>;
};

export type SharingLinks = {
  readonly __typename?: 'SharingLinks';
  /** Link to the current digital catalogue */
  readonly currentCatalogue: Link;
  readonly otherCatalogue: Link;
};


export type SharingLinksCurrentCatalogueArgs = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type SharingLinksOtherCatalogueArgs = {
  catalogueId: Scalars['String']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** Shopping query root */
export type Shopping = {
  readonly __typename?: 'Shopping';
  /** The get delivery channel. */
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  /** The get shopping session. */
  readonly shoppingSession?: Maybe<ShoppingSession>;
};


/** Shopping query root */
export type ShoppingDeliveryChannelArgs = {
  deliveryChannelId: Scalars['Long']['input'];
};


/** Shopping query root */
export type ShoppingShoppingSessionArgs = {
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

export type ShoppingSession = {
  readonly __typename?: 'ShoppingSession';
  /** For who the order is going to be placed */
  readonly customerId: Scalars['Long']['output'];
  /** The get selected delivery channel and addresses. */
  readonly delivery?: Maybe<DeliverySelectionSummary>;
  /** Find available pick-up points based on search criteria. */
  readonly deliveryChannels?: Maybe<PickupPoints>;
  /** Find available delivery options. */
  readonly deliveryOptions?: Maybe<DeliveryOptions>;
  /** Shopping by not authorized person */
  readonly isAnonymous: Scalars['Boolean']['output'];
  readonly isShoppingBlocked: Scalars['Boolean']['output'];
  /** VIP shopping (guarantee order on behalf of consultant) */
  readonly isVip: Scalars['Boolean']['output'];
  /** Channel used for placing order */
  readonly origin: OrderOrigin;
  /** The shopping session id */
  readonly sessionId: Scalars['ID']['output'];
};


export type ShoppingSessionDeliveryChannelsArgs = {
  deliveryMethod?: InputMaybe<DeliveryMethodType>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  radius?: InputMaybe<Scalars['Float']['input']>;
  searchFilter?: InputMaybe<PickupSearchFilter>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};


export type ShoppingSessionDeliveryOptionsArgs = {
  filter?: DeliverySearchFilter;
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};

/** Live video show type */
export enum ShowType {
  /** Show is recorded */
  Ended = 'Ended',
  /** Show is running now */
  Live = 'Live',
  /** Show not started yet */
  Scheduled = 'Scheduled'
}

export type SizedImage = {
  readonly __typename?: 'SizedImage';
  /** Url of the image */
  readonly url?: Maybe<Scalars['String']['output']>;
  /** Width of the image */
  readonly width?: Maybe<Scalars['Int']['output']>;
};

export type SocialContact = {
  readonly __typename?: 'SocialContact';
  /** Type of contact, eg. phone, WhatsUp, Facebook ... */
  readonly type?: Maybe<SocialContactType>;
  /** Social contact value */
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** Types of social contact */
export enum SocialContactType {
  /** Email */
  Email = 'Email',
  /** Facebook */
  Facebook = 'Facebook',
  /** Phone */
  PhoneNumber = 'PhoneNumber',
  /** VKontakte */
  VKontakte = 'VKontakte',
  /** WeChat */
  WeChat = 'WeChat',
  /** WhatsUp */
  WhatsUpNumber = 'WhatsUpNumber'
}

export type SocialMediaLinks = {
  readonly __typename?: 'SocialMediaLinks';
  /** Social Media Link Title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Social Media type */
  readonly type?: Maybe<SocialMediaType>;
  /** Social Media Link url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** Types of social media */
export enum SocialMediaType {
  /** Facebook */
  Facebook = 'Facebook',
  /** Instagram */
  Instagram = 'Instagram',
  /** Line */
  Line = 'Line',
  /** Odnoklassniki */
  Odnoklassniki = 'Odnoklassniki',
  /** Pinterest */
  Pinterest = 'Pinterest',
  Qq = 'Qq',
  /** Telegram */
  Telegram = 'Telegram',
  /** Twitter */
  Twitter = 'Twitter',
  /** VKontakte */
  VKontakte = 'VKontakte',
  /** WeChat */
  WeChat = 'WeChat',
  /** Weibo */
  Weibo = 'Weibo',
  /** YouTube */
  YouTube = 'YouTube',
  /** Youku */
  Youku = 'Youku'
}

export type Sponsor = {
  readonly __typename?: 'Sponsor';
  /** Consultant number of sponsor */
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  /** Customer ID of sponsor */
  readonly customerId?: Maybe<Scalars['Int']['output']>;
  /** The get customer emails. */
  readonly emails?: Maybe<ReadonlyArray<Maybe<Email>>>;
  /** Full name (formatted) of sponsor */
  readonly fullName?: Maybe<Scalars['String']['output']>;
  /** The get customer phones. */
  readonly phones?: Maybe<ReadonlyArray<Maybe<Phone>>>;
  /** The get profile image. */
  readonly profileImage?: Maybe<DocumentInfo>;
  /** Type of sponsor (relation to customer). */
  readonly sponsorType?: Maybe<SponsorType>;
};

/** Sponsor type */
export enum SponsorType {
  DirectSponsor = 'DirectSponsor'
}

/** Colors enum referring to FE storybook */
export enum StorybookColor {
  /** Shades/palette.blue[100] */
  Blue100 = 'Blue100',
  /** Shades/palette.brown[50] */
  Brown50 = 'Brown50',
  /** Shades/palette.brown[300] */
  Brown300 = 'Brown300',
  /** Shades/palette.brown[400] */
  Brown400 = 'Brown400',
  /** Extra colors/palette.common.white */
  CommonWhite = 'CommonWhite',
  /** Palette - Main colors/palette.default.contrastText */
  DefaultContrastText = 'DefaultContrastText',
  /** Palette - Main colors/palette.default.main */
  DefaultMain = 'DefaultMain',
  /** Shades/palette.green[50] */
  Green50 = 'Green50',
  /** Shades/palette.green[200] */
  Green200 = 'Green200',
  /** Shades/palette.green[400] */
  Green400 = 'Green400',
  /** Shades/palette.grey[50] */
  Grey50 = 'Grey50',
  /** Shades/palette.grey[100] */
  Grey100 = 'Grey100',
  /** Shades/palette.grey[700] */
  Grey700 = 'Grey700',
  /** Shades/palette.grey[900] */
  Grey900 = 'Grey900',
  /** InfoDark */
  InfoDark = 'InfoDark',
  /** Shades/palette.pink[50] */
  Pink50 = 'Pink50',
  /** Shades/palette.pink[300] */
  Pink300 = 'Pink300',
  /** Palette - Main colors/palette.default.primarycontrastText */
  PrimaryContrastText = 'PrimaryContrastText',
  /** PrimaryLight */
  PrimaryLight = 'PrimaryLight',
  /** Palette - Main colors/palette.default.primarymain */
  PrimaryMain = 'PrimaryMain',
  /** Shades/palette.purple[50] */
  Purple50 = 'Purple50',
  /** Shades/palette.purple[200] */
  Purple200 = 'Purple200',
  /** Shades/palette.purple[400] */
  Purple400 = 'Purple400',
  /** Shades/palette.red[50] */
  Red50 = 'Red50',
  /** Palette - Main colors/palette.default.secondaryContrastText */
  SecondaryContrastText = 'SecondaryContrastText',
  /** Palette - Main colors/palette.secondary.main */
  SecondaryMain = 'SecondaryMain',
  /** Typography/palette.text.primary */
  TextPrimary = 'TextPrimary',
  /** Typography/palette.text.secondary */
  TextSecondary = 'TextSecondary',
  /** Undefined value, its an error when this occurs */
  Undefined = 'Undefined',
  /** Shades/palette.yellow[50] */
  Yellow50 = 'Yellow50',
  /** Shades/palette.yellow[400] */
  Yellow400 = 'Yellow400'
}

export type SubmitDialogNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'SubmitDialogNewsletterSubsciptionConfiguration';
  /** EmailLabel */
  readonly emailLabel?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** InvalidEmailLabel */
  readonly invalidEmailLabel?: Maybe<Scalars['String']['output']>;
  /** PrivacyPolicyText */
  readonly privacyPolicyText?: Maybe<Scalars['String']['output']>;
  /** PrivacyPolicyUrl */
  readonly privacyPolicyUrl?: Maybe<Scalars['String']['output']>;
  /** PrivacyPolicyUrlText */
  readonly privacyPolicyUrlText?: Maybe<Scalars['String']['output']>;
  /** SubscribeButtonText */
  readonly subscribeButtonText?: Maybe<Scalars['String']['output']>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type SubscribedDialogNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'SubscribedDialogNewsletterSubsciptionConfiguration';
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

/** Subscription query root */
export type Subscribing = {
  readonly __typename?: 'Subscribing';
  /** The get personal subscriptions of customer. */
  readonly personalSubscriptions?: Maybe<PersonalSubscriptions>;
};


/** Subscription query root */
export type SubscribingPersonalSubscriptionsArgs = {
  customerId: Scalars['Long']['input'];
  isActive?: Scalars['Boolean']['input'];
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};

export type Subscription = {
  readonly __typename?: 'Subscription';
  /** Allowed subscription frequency possibilities */
  readonly allowedFrequencies?: Maybe<ReadonlyArray<Scalars['Int']['output']>>;
  /** Indicator if subscription is available */
  readonly available: Scalars['Boolean']['output'];
  /** Default frequency of product subscription */
  readonly frequency: Scalars['Int']['output'];
};

export type SubscriptionBenefitBanner = {
  readonly __typename?: 'SubscriptionBenefitBanner';
  /** Banner message */
  readonly message?: Maybe<Scalars['String']['output']>;
  /** Link to read more information on subscriptions */
  readonly readMoreLink?: Maybe<Link>;
  /** Link to register new customer */
  readonly registerLink?: Maybe<Link>;
  /** Link to sign in existing customer */
  readonly signInLink?: Maybe<Link>;
  /** Banner title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionProfile = {
  readonly __typename?: 'SubscriptionProfile';
  /** Delivery channel assigned to the subscription. */
  readonly address?: Maybe<DeliveryAddress>;
  /** Home delivery address */
  readonly addressId?: Maybe<Scalars['Long']['output']>;
  /** Subscription owner */
  readonly customerId: Scalars['Long']['output'];
  /** Delivery channel assigned to the subscription. */
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  /** Delivery channel */
  readonly deliveryChannelId: Scalars['Long']['output'];
  /** Recurring payment provider */
  readonly paymentProviderId: Scalars['Long']['output'];
};

export type Tag = {
  readonly __typename?: 'Tag';
  /** Tag group */
  readonly groupName?: Maybe<Scalars['String']['output']>;
  /** Taxonomy tag group name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Collection of tags */
  readonly tags?: Maybe<ReadonlyArray<Maybe<TagValue>>>;
  /** Tag group translation */
  readonly translation?: Maybe<Scalars['String']['output']>;
};

export type TagValue = {
  readonly __typename?: 'TagValue';
  /** Value of a tag */
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type TaxClassification = {
  readonly __typename?: 'TaxClassification';
  /** Entrepreneur tax related documents including historical */
  readonly entrepreneurDocuments?: Maybe<ReadonlyArray<Maybe<TaxClassificationDocument>>>;
  /** Indicates whether customer is entrepreneur */
  readonly isEntrepreneur?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether customer is physical person */
  readonly isPhysicalPerson?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether customer is vat payer */
  readonly isVatPayer?: Maybe<Scalars['Boolean']['output']>;
  /** Physical person tax related documents including historical */
  readonly physicalPersonDocuments?: Maybe<ReadonlyArray<Maybe<TaxClassificationDocument>>>;
  /** Vat payer related documents including historical */
  readonly vatPayerDocuments?: Maybe<ReadonlyArray<Maybe<TaxClassificationDocument>>>;
};

export type TaxClassificationDocument = {
  readonly __typename?: 'TaxClassificationDocument';
  /** Company name */
  readonly companyName?: Maybe<Scalars['String']['output']>;
  /** Document number */
  readonly documentNumber?: Maybe<Scalars['String']['output']>;
  /** Whether document is valid and used (e.g. not deleted by operator) */
  readonly isActive?: Maybe<Scalars['Boolean']['output']>;
  /** Issue Date */
  readonly issueDate?: Maybe<Scalars['DateTime']['output']>;
  /** ID of tax classification */
  readonly taxClassificationId: Scalars['ID']['output'];
  /** Validity of document - start */
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Validity of document - end */
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
};

/** Enum telephone type */
export enum TelephoneType {
  Business = 'Business',
  Fax = 'Fax',
  Home = 'Home',
  Mobile = 'Mobile'
}

export type TestFreaksConfiguration = {
  readonly __typename?: 'TestFreaksConfiguration';
  readonly scriptUrl?: Maybe<Scalars['String']['output']>;
};

export type TimeGuarantees = {
  readonly __typename?: 'TimeGuarantees';
  /** Delivery duration text */
  readonly deliveryDurationText?: Maybe<Scalars['String']['output']>;
  /** More info link */
  readonly moreInfoLink?: Maybe<Link>;
};

export type TopBrands = {
  readonly __typename?: 'TopBrands';
  /** Brands displayed in the section */
  readonly brands?: Maybe<ReadonlyArray<Maybe<Brand>>>;
  /** Section's title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export enum TrackingStatus {
  Cancel = 'Cancel',
  CourierHandover = 'CourierHandover',
  CourierTakeover = 'CourierTakeover',
  Create = 'Create',
  Deliver = 'Deliver',
  EstimatedDelivery = 'EstimatedDelivery',
  HandoverFailed = 'HandoverFailed',
  OutForDelivery = 'OutForDelivery',
  Pick = 'Pick',
  PickedUpBy = 'PickedUpBy',
  Print = 'Print',
  ReadyForPickup = 'ReadyForPickup',
  ReturnedToSender = 'ReturnedToSender',
  Ship = 'Ship',
  TakenByCourier = 'TakenByCourier',
  ToBeReturnedToSender = 'ToBeReturnedToSender',
  Unknown = 'Unknown'
}

/** Content vertical alignment */
export enum VerticalContentAlignment {
  Bottom = 'Bottom',
  Middle = 'Middle',
  Top = 'Top',
  Undefined = 'Undefined'
}

export type Video = {
  readonly __typename?: 'Video';
  /** Flag indicating if video should be automatcially played on scroll */
  readonly autoplayOnScroll?: Maybe<Scalars['Boolean']['output']>;
  /** Video's id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Flag indicating if video should be played in a loop */
  readonly playInLoop?: Maybe<Scalars['Boolean']['output']>;
  /** Type of the video */
  readonly type?: Maybe<VideoType>;
};

export type VideoShow = {
  readonly __typename?: 'VideoShow';
  /** Duration of show in sec. */
  readonly durationSeconds?: Maybe<Scalars['Int']['output']>;
  /** Time when show was ended */
  readonly endedAtUtc?: Maybe<Scalars['DateTime']['output']>;
  /** Identifying show which should be displayed in banner */
  readonly isCurrent?: Maybe<Scalars['Boolean']['output']>;
  /** Preview image of show */
  readonly previewImageUrl?: Maybe<Scalars['String']['output']>;
  /** Scheduled start of show */
  readonly scheduledStartAtUtc?: Maybe<Scalars['DateTime']['output']>;
  /** Id of show */
  readonly showId: Scalars['ID']['output'];
  /** Real time of show start */
  readonly startedAtUtc?: Maybe<Scalars['DateTime']['output']>;
  /** Show title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Type of show */
  readonly typeOfShow?: Maybe<ShowType>;
};

export enum VideoType {
  /** Video in MP4 file format */
  Mp4 = 'Mp4',
  /** Video hosted on Youku */
  Youku = 'Youku',
  /** Video hosted on YouTube */
  Youtube = 'Youtube'
}

export type VipBenefits = {
  readonly __typename?: 'VipBenefits';
  /** Collection of images displayed in VIP Benefits section on the Frontpage */
  readonly images?: Maybe<ReadonlyArray<Maybe<FrontPageImage>>>;
  /** Link of VIP Benefits section */
  readonly link?: Maybe<Link>;
};

export type VipCustomerBanner = {
  readonly __typename?: 'VipCustomerBanner';
  /** Vip Customer Banner link title */
  readonly linkTitle?: Maybe<Scalars['String']['output']>;
  /** Vip Customer Banner link url */
  readonly linkUrl?: Maybe<Scalars['String']['output']>;
};

/** Possible types of visitor segment */
export enum VisitorSegment {
  /** Anonymous */
  Anonymous = 'Anonymous',
  /** Consultant */
  Consultant = 'Consultant',
  /** Director and above */
  DirectorAndAbove = 'DirectorAndAbove',
  /** End customer */
  EndCustomer = 'EndCustomer',
  /** Manager */
  Manager = 'Manager',
  /** Member */
  Member = 'Member',
  /** New consultant */
  NewConsultant = 'NewConsultant',
  /** Not defined */
  NotDefined = 'NotDefined',
  /** Spo */
  Spo = 'Spo'
}

export type WellnessClub = {
  readonly __typename?: 'WellnessClub';
  /** Wellness club banners */
  readonly banners?: Maybe<WellnessClubBanners>;
  /** Wellness Club configuration */
  readonly configuration?: Maybe<WellnessClubConfiguration>;
  /** Wellness Club localization */
  readonly localization?: Maybe<WellnessClubLocalization>;
};

export type WellnessClubBanners = {
  readonly __typename?: 'WellnessClubBanners';
  /** Daily wellnessbeing banner */
  readonly dailyWellbeingBannerUrl?: Maybe<Scalars['String']['output']>;
  /** Wellness club main banner */
  readonly mainBannerUrl?: Maybe<Scalars['String']['output']>;
  /** Sport track banner */
  readonly sportBannerUrl?: Maybe<Scalars['String']['output']>;
  /** Weight management banner */
  readonly weightManagementBannerUrl?: Maybe<Scalars['String']['output']>;
};

export type WellnessClubConfiguration = {
  readonly __typename?: 'WellnessClubConfiguration';
  /** Get certification page url */
  readonly certifiedUrl?: Maybe<Scalars['String']['output']>;
  /** Is club members management enabled */
  readonly isClubMembersManagementEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is daily wellnessbeing track enabled */
  readonly isDailyWellbeingTrackEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is wellness club enabled */
  readonly isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is sports track enabled */
  readonly isSportsTrackEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is weight management track enabled */
  readonly isWeightManagementTrackEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type WellnessClubLocalization = {
  readonly __typename?: 'WellnessClubLocalization';
  readonly contents?: Maybe<Scalars['JSON']['output']>;
  readonly cultures?: Maybe<Scalars['JSON']['output']>;
  /** Default language */
  readonly defaultLanguage?: Maybe<Scalars['String']['output']>;
  readonly translations?: Maybe<Scalars['JSON']['output']>;
};

export type WhatsNewBanner = {
  readonly __typename?: 'WhatsNewBanner';
  /** Background color */
  readonly backgroundColor?: Maybe<Scalars['String']['output']>;
  /** Font color -eg. red, grey */
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  /** Headline of the banner */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image url of the banner */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link type, attributes and Id */
  readonly link?: Maybe<Link>;
  /** Mobile font color -eg. red, grey */
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  /** Banner Validity date from */
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Banner validity date to */
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
};
