import type { FC } from 'react';

import { BannerWithProductsAndButton } from '../../components';
import { BANNER_ROOT_PLACEHOLDER_LEFT } from '../../constants';
import { useFrontpage } from '../../contexts';
import { useTranslations } from '../../hooks';
import { IconicProductsPlaceholder } from '../../images/iconicProductsPlaceholder';
import { ICONIC_PRODUCTS_TO_DISPLAY_DESKTOP, ICONIC_PRODUCTS_TO_DISPLAY_MOBILE } from './constants';

export const IconicProductsSection: FC = () => {
  const { translations } = useTranslations();
  const {
    iconicProducts: { banner, description, headline, productBoxes },
  } = useFrontpage();

  return (
    <BannerWithProductsAndButton
      header={{
        title: headline ?? translations.iconicProducts,
        description,
      }}
      button={{
        title: translations.viewAll,
        href: '/icons',
        alignment: 'right',
      }}
      bannerPlaceholder={{
        image: <IconicProductsPlaceholder testId={BANNER_ROOT_PLACEHOLDER_LEFT} />,
      }}
      banner={{
        banner,
        alignment: 'left',
        bannerOrientation: 'portrait',
      }}
      products={{
        maxProductsToDisplay: ICONIC_PRODUCTS_TO_DISPLAY_DESKTOP,
        maxProductsToDisplayMobile: ICONIC_PRODUCTS_TO_DISPLAY_MOBILE,
        productBoxes,
      }}
    />
  );
};
